@import '../../../styles/variables.scss';
@import "../../../../components/_shared/_styles/component-base";
// @import "../../components/_shared/_styles/component-base";

.itemContainer {
  display: flex;
  outline: none;
  cursor: pointer;
  background: none;
  flex-direction: row;
  align-items: flex-start;
}
.itemText {
  font-size: 16px;
  color: $text-dark;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.144px;
  font-family: 'Open Sans', sans-serif;
  @media screen and (max-width: $break--mbl-lg) {
      font-size: 16px;
      line-height: 22px;
  } 
}
.uncheckedDiv {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 1px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #cfcccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checked {
  background: #0064DF;
  border: 1px solid #0064DF;
}

.rounded {
  border-radius: 25px;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}
