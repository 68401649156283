@import "../../components/_shared/_styles/component-base";

:global .search-filter {
    @include rounded(4px);
    width: 100%;
    max-width: 480px;
    margin-top: 30px;
    display: flex;
    align-items: center;

    overflow: hidden;
    // .ais-SearchBox {
    //     flex: 1;
    //     width: 100%;
    // }


    .field {
        &:nth-child(1) {
            flex: 1;
        }
        &:nth-child(2) {
            width: 218px;
            display: flex;
        }

        .subfield {
            &:nth-child(1) {
                flex: 1;
            }
            &:nth-child(2) {
                width: 58px;
            }
        }
    }
    &.site-search {
		.field {
			&:nth-child(2) {
				width: 58px;
			}
		}
	}

    input[type=text] {
        width: 100%;
        border: 0;
        border-right: 1px solid $forms-search-input-border;
        height: 58px;
        margin: 0;
        padding-left: 10px;
        padding-right: 10px;
    }
}


:global .site-search-results {
    background-color: $search-results-bg;
}

#hits {
    background: none;
}

.site-search {
    width: 100%;
    max-width: 480px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    overflow: hidden;
    #searchbox {
        height: auto;
    }
    @include media-breakpoint-down(lg) {
        max-width: unset;
        margin-top: 0;
    }
}

:global .ais-SearchBox-input {
    @include font-size(16px);
}

ol.ais-Hits-list {
    list-style-type: none;
    li {
        padding: 30px 0 25px 0;
        border-bottom: 1px solid $search-results-hits-border;
        background: none;
        &:first-child {
            padding-top: 0;
        }
        p {
            margin-bottom: 15px;
            word-wrap: break-word;
            @include font-size(16px);
            line-height: 1.5em;
            color: $search-results-hits-text;
            &:last-of-type {
                margin-bottom: 0;
            }
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        span{
            @include font-size(16px);
        }
        .title {
            @include font-size(22px);
            font-weight: 700;
            color: $search-results-hits-title;
            margin-bottom: 0px;
        }
    }

    .ais-Highlight-highlighted,
    .ais-Snippet-highlighted {
        background: none !important;
        color: $search-results-hits-term;
        font-style: normal;
        font-weight: 700;
    }

    a .ais-Highlight-highlighted,
    a .ais-Snippet-highlighted {
        background: none !important;
        color: $search-results-hits-term;
        font-style: normal;
        font-weight: 700;
    }
    @include media-breakpoint-down(lg) {
        margin-bottom: 40px;
        max-width: unset;
    }
}

//Search Filters
#brand-list {
    position: relative;
    margin-top: 5px;
    &:after {
        content: '';
        width: 1px;
        height: 100%;
        background: $search-results-filters-column-border;
        position: absolute;
        right: -12%;
        top: 0;
    }

    @include media-breakpoint-down(lg) {
        display: none;
    }
}

ul.ais-Menu-list {
    border-top: 1px solid $search-results-filters-list-border;
    margin-right: 40px;
    li {
        border-bottom: 1px solid $search-results-filters-list-border;
        a {
            @include font-size(18px);
            color: $search-results-filters-list-link;
            text-transform: capitalize;
            display: block;
            padding: 15px 20px;
        }

        span.ais-Menu-count {
            background: none;
            border: none;
            @include font-size(16px);
            color: $search-results-filters-list-link;
            margin-left: 0;
        }

        &.ais-Menu-item--selected {
            font-weight: 700;
            span {
                font-weight: 700;
            }
        }
    }
}

.ais-Hits-item:first-of-type,
.ais-InfiniteHits-item:first-of-type {
    border-radius: 0 !important;
}

.ais-Hits-item,
.ais-InfiniteHits-item {
    box-shadow: none !important;
}
