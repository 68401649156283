@import "../../_shared/_styles/component-base";

.c-promo-secondary {
    position: relative;

    h2 {
        margin-bottom: 30px;
        @include font-size(36px);
        line-height: 1.2em;
        font-weight: 700;
    }

    p {
        @include font-size(18px);
    }
    //Light Text
    &.light {
        position: relative;

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: $promo-secondary-light-no-gradient;
            background: $promo-secondary-light-gradient;
        }

        :global .eyebrow {
            color: $promo-secondary-light-eyebrow;
        }

        :global h2 {
            color: $promo-secondary-light-title;
        }

        :global p {
            color: $promo-secondary-light-text;
        }

        :global &.offset {
            &:before {
                background: $promo-secondary-light-no-gradient;
                background: $promo-secondary-light-gradient-offset;
            }
        }
    }
    //Dark Text
    &.dark {
        position: relative;

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: $promo-secondary-dark-no-gradient;
            background: $promo-secondary-dark-gradient;
        }

        :global .eyebrow {
            color: $promo-secondary-dark-eyebrow;
        }

        :global h2 {
            color: $promo-secondary-dark-title;
        }

        :global p {
            color: $promo-secondary-dark-text;
        }

        :global &.offset {
            &:before {
                background: $promo-secondary-dark-no-gradient;
                background: $promo-secondary-dark-gradient-offset;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        padding-top: 40px;
        padding-bottom: 60px;
        height: 100%;
        min-height: unset;

        h2 {
            @include font-size(28px);
        }
    }
}
