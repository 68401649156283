@import "../../_shared/_styles/component-base";

.c-promo-two-thirds {
  height: auto;
  padding: {
    top: 0;
    bottom: 0;
  }

  .promo-content {
    position: relative;
    padding: {
      left: 60px;
      right: 0;
      top: 100px;
      bottom: 100px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100vw;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
    }

    > div {
      position: relative;
      z-index: 1;
    }
  }

  h3 {
    @include font-size(30px);
    font-weight: 700;
    margin-bottom: 30px;
  }

  p {
    @include font-size(18px);
    line-height: 1.6em;
  }

  :global .link-list {
    margin-top: 30px;
    ul {
      li {
        margin-left: 20px;
        padding-right: 20px;
        position: relative;
        &:after {
          content: '|';
          position: absolute;
          right: 0;
          color: $promo-two-thirds-light-link;
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          padding-right: 0;
          &:after {
            content: '';
          }
        }

        :global a.smartlink {
          @include font-size(16px);
          font-weight: 600;
          color: $promo-two-thirds-light-link;
        }
      }
    }
  }

  //Backgrounds
  &.light {
    h3 {
        color: $promo-two-thirds-light-title
    }

    p {
        color: $promo-two-thirds-light-text;
    }
    .link-list {
        ul {
            li {
                &:after {
                    color: $promo-two-thirds-light-link;
                }
                a.smartlink {
                    color: $promo-two-thirds-light-link;
                }
            }
        }
    }
    .promo-content {
      &:before {
        background: $promo-two-thirds-light-no-gradient;
        background: -webkit-linear-gradient(left, $promo-two-thirds-light-gradient-left 0%, $promo-two-thirds-light-gradient-right 50%);
        background: linear-gradient(to right, $promo-two-thirds-light-gradient-left 0%, $promo-two-thirds-light-gradient-right 50%);
      }
    }
  }

  &.dark {
    h3 {
        color: $promo-two-thirds-dark-title
    }

    p {
        color: $promo-two-thirds-dark-text;
    }
    .link-list {
        ul {
            li {
                &:after {
                    color: $promo-two-thirds-dark-link;
                }
                a.smartlink {
                    color: $promo-two-thirds-dark-link;
                }
            }
        }
    }
    .promo-content {
        &:before {
            background: $promo-two-thirds-dark-no-gradient;
            background: -webkit-linear-gradient(right, $promo-two-thirds-dark-gradient-left 75%, $promo-two-thirds-dark-gradient-right 100%);
            background: linear-gradient(to left, $promo-two-thirds-dark-gradient-left 75%, $promo-two-thirds-dark-gradient-right 100%);
        }
    }
  }

  /* Reverse */
  &.reverse {
    &.light {
        .promo-content {
            &:before {
                background: $promo-two-thirds-light-no-gradient;
                background: -webkit-linear-gradient(right, $promo-two-thirds-light-gradient-left 0%, $promo-two-thirds-light-gradient-right 50%);
                background: linear-gradient(to left, $promo-two-thirds-light-gradient-left 0%, $promo-two-thirds-light-gradient-right 50%);
            }
        }
    }

    &.dark {
        .promo-content {
            &:before {
                background: $promo-two-thirds-dark-no-gradient;
                background: -webkit-linear-gradient(left, $promo-two-thirds-dark-gradient-left 75%, $promo-two-thirds-dark-gradient-right 100%);
                background: linear-gradient(to right, $promo-two-thirds-dark-gradient-left 75%, $promo-two-thirds-dark-gradient-right 100%);
            }
        }
    }

    .promo-content {
      padding: {
        right: 60px;
        left: 0;
      }

      &:before {
        left: unset;
        right: 0;
      }
    }
  }

  @include media-breakpoint-up(md) {
    :global .row {
      justify-content: flex-end;
    }

    &.reverse {
      :global .row {
        justify-content: flex-start;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    min-height: auto;;
    &:before {
      content: '';
      position: absolute;
      width: 100vw;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
    }

    &.light {

        &:before {
            @include opacity(.90);
            background: $promo-two-thirds-light-no-gradient;
            background: -webkit-linear-gradient(top, $promo-two-thirds-light-gradient-left 0%, $promo-two-thirds-light-gradient-right 100%);
            background: linear-gradient(to bottom, $promo-two-thirds-light-gradient-left 0%, $promo-two-thirds-light-gradient-right 100%);
        }
    }

    &.dark {
        &:before {
            background: $promo-two-thirds-dark-no-gradient;
            background: -webkit-linear-gradient(bottom, $promo-two-thirds-dark-gradient-left 0%, $promo-two-thirds-dark-gradient-right 100%);
            background: linear-gradient(to top, $promo-two-thirds-dark-gradient-left 0%, $promo-two-thirds-dark-gradient-right 100%);
        }
    }

    .promo-content {
      padding: {
        right: 0;
        left: 0;
      }

      &:before {
        display: none;
      }
    }

    &.reverse {
      .c07-promo-content {
        padding: {
          right: 0;
          left: 0;
        }

        &:before {
          display: none;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .promo-content {
      position: relative;
      padding: {
        left: 0;
        right: 0;
        top: 60px;
        bottom: 60px;
      }
    }

    h3 {
      @include font-size(26px);
      margin-bottom: 15px;
    }

    p {
      @include font-size(16px);
      line-height: 1.6em;
    }

    :global .link-list {
      margin-top: 20px;
      ul {
        flex-direction: column;
        li {
          margin-left: 0;
          padding-right: 0;
          margin-bottom: 10px;
          position: relative;
          &:after {
            display: none
          }
          a {
            @include font-size(16px);
          }
        }
      }
    }
  }
}
