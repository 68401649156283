@import "../../components/_shared/_styles/component-base";

.locator {
    position: relative;
    background: $locator-no-gradient;
    background: $locator-gradient;
    height: unset;

    @include media-breakpoint-up(lg) {
        padding-top: 150px;
    }

    @include font-size(20px);

    &:before {
        content: '';
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: $locator-watermark;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        background-size: 40%;
        @include opacity(0.6);
        pointer-events: none;
    }
    .bolder{
        font-weight: 700;
    }

    h1 {
        @include font-size(26px);
        font-weight: bold;
        color: $locator-h1;
        margin-bottom: 20px;
        padding-top: 16px;
    }

    h2 {
        color: $gray-dark;
        font-family: unset;
        font-size: 30px;
        margin-bottom: 10px;
        font-weight: 500;
    }

    h3 {
        @include font-size(16px);
        font-weight: 700;
        color: $locator-h3;
    }

    a.back {
        display: block;
        font-weight: 700;
        @include font-size(18px);
        padding-left: 25px;
        position: relative;
        color: $locator-back-link;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 6px;
            @include rotate(90deg);
            background-image: $locator-back-icon;
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 15px;
            width: 20px;
            height: 20px;
        }
    }
}

// From locations project
/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
for details on configuring this project to bundle and minify static web assets. */

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

/* Sticky footer styles
  -------------------------------------------------- */
html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.border-top {
    border-top: 1px solid #e5e5e5;
}

.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

button.accept-policy {
    font-size: 1rem;
    line-height: inherit;
}

/* Sticky footer styles
  -------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    white-space: nowrap;
    line-height: 60px;
    /* Vertically center the text there */
}

.iframe {
    height: 700px;
    overflow-y: auto !important;
    background: #f3f3f3;
    outline: 1px dotted;
}

.locations {
    position: relative;
    overflow: hidden;
    outline: 1px dotted;
}

.locations:before,
.locations:after {
    content: " ";
    display: table;
}

.locations:after {
    clear: both;
}

.locations .locations-search {
    position: relative;
    z-index: 9;
    background: #199cb2;
    padding: 10px;
    color: #fff;
    border-bottom: 5px solid rgba(0, 0, 0, 0.2);

    .btn {
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
        font-size: 14px;
        line-height: 1.42857143;
    }

    .btn>input[type="radio"],
    [data-toggle="buttons"]>.btn>input[type="checkbox"] {
        position: absolute;
        z-index: -1;
        opacity: 0;
        filter: alpha(opacity=0);
    }

    .form-control {
        display: block;
        width: 100%;
        height: 34px;
        // padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }
}

.locations .locations-search .title {
    display: inline-block;
    vertical-align: middle;
    font-size: 24px;
    font-weight: 700;
    margin: 0 10px 0 0;
}

.locations .locations-search .form-group {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    position: relative;
    margin: 5px 10px 0 25px;
}


.btn.btn-center:focus {
    background: #fff;
}

.btn-center {
    background: #fff;
    color: #003f80;
    -webkit-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.3);
    text-shadow: none;
}

.btn.btn-center:hover:active,
.btn-center:hover:active {
    -webkit-box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.3);
}

.btn-ss {
    background: #fff !important;
    padding: 3px 10px !important;
    margin-top: 2px !important;
    color: #003f80 !important;
    -webkit-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.3);
    text-shadow: none;
    height: 35px;
}

.btn.btn-ss:hover:active,
.btn-ss:hover:active {
    -webkit-box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.3);
}

.mapbtns {
    height: 49px;
    margin-top: -5px;
}

.mapbtnrow {
    height: 30px;
}

.locations .locations-search .form-group label {
    font-weight: 400;
    margin: 0;
}

.locations .locations-search .form-group span {
    margin: -4px 10px 0 -10px;
}

.locations .locations-search .form-group input {
    color: #333333;
}

.locations .locations-search .form-group input.text {
    background: #fff;
    border: none;
    outline: none;
    padding: 6px 6px;
    width: 130px;
    height: 29px;
    font-size: 12px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-top: -8px;
}

.locations .locations-search .form-group input.number {
    background: #fff;
    border: none;
    outline: none;
    padding: 6px 6px;
    width: 60px;
    height: 35px;
    font-size: 12px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.locations .locations-search .form-group.text label {
    color: #333333;
    position: absolute;
    top: 6px;
    left: 6px;
    text-shadow: none;
}

.locations .locations-search .form-group.checkbox {
    margin: 10px 10px 0 0;
}

.locations .locations-search .form-group.checkbox label {
    padding: 0 0 0 18px;
}

.locations .locations-search .form-group.checkbox input.checkbox {
    position: absolute;
    top: -1px;
    left: 0;
    margin: 0;
}

.locations .locations-results {
    padding: 0 0 0 260px;
    position: relative;
    z-index: 8;
}

.locations .locations-results .results-header {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 5px;
    width: 100%;
    height: 100px;
    background: #f2f2f2;
    border-bottom: 1px solid #d9d9d9;
    -webkit-box-shadow: 5px 0 0 0 rgba(0, 0, 0, 0.2);
    box-shadow: 5px 0 0 0 rgba(0, 0, 0, 0.2);
}

.locations .locations-results .results-header .title {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 19px;
    font-weight: 700;
    color: #d95127;
    padding: 10px;
}

.results-header .no-results {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #d95127;
    padding: 10px;
}

.locations .locations-results .results-list {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 260px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 500px;
    background: #f2f2f2;
    -webkit-box-shadow: 5px 0 0 0 rgba(0, 0, 0, 0.2);
    box-shadow: 5px 0 0 0 rgba(0, 0, 0, 0.2);
}

.locations .locations-results .results-list ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 12px;
    margin-top: 95px;
}

.locations .locations-results .results-list ul li {
    cursor: pointer;
    padding: 10px 10px 10px 58px;
    position: relative;
    border-top: 1px solid #d9d9d9;
}

.locations .locations-results .results-list ul li .content {
    margin-left: -5px;
}

.locations .locations-results .results-list ul li .number {
    position: absolute;
    top: 7px;
    left: 16px;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #d95127;
}

.locations .locations-results .results-list ul li .branch-name {
    left: 0px;
    font-weight: 700;
    color: #199cb2;
}

.under-maint {
    left: 0px;
    font-size: 10px;
    font-weight: 500;
    color: red;
}

.locations .locations-results .results-list ul li .address {
    font-weight: 600;
}

.locations .locations-results .results-list ul li.active {
    background: #d9d9d9;
}

.locations .locations-results .results-map {
    height: 500px;
    width: 100%;
    position: relative;
    z-index: 8;
}

.locations .locations-results .results-map #map-canvas {
    height: 100%;
}

@media (max-width: 959px) {
    .btn.btn-secondary {
        margin-top: 6px;
        margin-top: 6px !important;
        font-size: .875em;
        line-height: 2.5em;
    }
}

@media (max-width: 767px) {
    .locations .locations-results {
        padding: 0;
    }

    .locations .locations-results .results-list {
        position: relative;
        top: auto;
        left: auto;
        width: auto;
        height: 200px;
    }

    .locations .locations-results .results-map {
        height: 400px;
        margin-bottom: 15px;
    }

    .inptsearch {
        width: 70%;
    }

    .btn.btn-secondary {
        width: 90%;
    }
}

@media (max-width: 734px) {
    .btn.btn-secondary {
        margin-top: 6px !important;
        font-size: .875em;
        line-height: 1.9em;
        height: 30px !important;
        width: 90%;
    }

    .inptsearch {
        height: 30px;
        width: auto;
    }

    #locations-search-submit {
        height: 30px !important;
        margin-top: -5px;
    }
}

@media (max-width: 469px) {
    .btn.btn-secondary {
        margin-top: 6px;
        margin-top: 6px !important;
        font-size: .875em;
        line-height: 2.0em !important;
        height: 30px !important;
        width: 100%;
    }
}

@media (max-width: 340px) {
    .inptsearch {
        height: 30px;
        width: 95%;
    }
}

.locations .location-info h3 {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 36px;
    font-weight: 400;
    color: #003f80;
    margin: 0 0 5px 0;
}

.locations .location-info .location-image img {
    width: 100%;
    margin: 20px 0 0 0;
}

.locations .location-info .location-data .location-data-item {
    font-size: 14px;
    font-weight: 400;
}

.locations .location-info .location-data .location-data-item>div {
    margin: 0 0 2px 0;
}

.locations .location-info .location-data .location-data-item>div.contact-number-item {
    margin: 0 0 10px 0;
}

.location-info {
    border: 1px solid #D9D9D9;
    padding: 3%;
    margin: 2% 0;
    border-radius: 10px;
}

.get-directions-html {
    font-size: 12px;
}

.get-directions-html .content {
    margin: 5px 0 0 0;
    padding: 15px;
    position: relative;
    border: 5px solid rgba(0, 0, 0, 0.2);
    h4{
        font-size: 24px;
        border-bottom: 1px solid #148295;
        padding-bottom: 5px;
        margin-bottom: 5px;
    }
}

.get-directions-html .content .top {
    border-bottom: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    border-top: 8px solid #4aad02;
    height: 5px;
    background: #f3f3f3 url(../../_assets/background-gray.jpg) center top;
}

.get-directions-html .content .google-overview {
    border-bottom: 5px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.get-directions-html .content .google-overview:before,
.get-directions-html .content .google-overview:after {
    content: " ";
    display: table;
}

.get-directions-html .content .google-overview:after {
    clear: both;
}

.get-directions-html .content .google-overview .google-address {
    float: left;
}

.get-directions-html .content .google-overview .google-address .google-via {
    font-weight: 600;
    color: #4aad02;
}

.get-directions-html .content .google-overview .google-distance {
    float: right;
    text-align: right;
}

.get-directions-html .content .google-overview .google-distance .google-duration {
    font-weight: 600;
    color: #4aad02;
}

.get-directions-html .content .google-overflow {
    height: 200px;
    overflow: auto;
}


.get-directions-html .content .google-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.get-directions-html .content .google-item:before,
.get-directions-html .content .google-item:after {
    content: " ";
    display: table;
}

.get-directions-html .content .google-item:after {
    clear: both;
}

.get-directions-html .content .google-item .google-distance {
    float: left;
}

.get-directions-html .content .google-item .google-duration {
    float: right;
}

/*# sourceMappingURL=Locations.ascx.css.map */
/*Checkbox map headers */
label.loc-hdrs {
    text-shadow: 0 0 #000 !important;
    color: #fff !important;
}

label.loc-sub {
    font-size: 12px;
    padding: 0 0 0 8px;
}

.loc {
    border: 0px solid #000;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
}

.location-data2 {
    padding-top: 1%;
}

.branch-icon {
    background: url(../../_assets/branch-marker.png) left top;
    width: 20px;
    height: 28px;
    float: left;
}

.vystar-atm-icon {
    background: url(../../_assets/atm-vcu-marker.png) left top;
    width: 20px;
    height: 28px;
    float: left;
}

.surcharge-free-atm-icon {
    background: url(../../_assets/atm-surcharge-marker.png);
    position: inherit;
    vertical-align: middle;
    width: 20px;
    height: 28px;
    float: left;
}

.current-pos-icon {
    background: url(../../_assets/here.png);
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 15px;
}

.filter-container {
    background-color: #e1f2f5;
}

.lbl-filter {
    line-height: 1.9;
    margin-left: 2% !important;
}

.current-pos {
    /* margin-top: -2px !important; */
    padding: 3px 10px;
    font-weight: 700;
    background: #F2F2F2;
    margin-top: 2px;
    color: #000;
    border: none;
    box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.3);
    border-radius: 6px !important;
    margin-right: 15px;
    height: 35px;
    text-shadow: none;
}

.btn-secondary-disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn.btn-secondary {
    padding: 3px 10px;
    font-weight: 700;
    background: #F2F2F2;
    margin-top: 2px;
    color: #000;
    border: none;
    box-shadow: 2px 4px 2px 2px rgba(0, 0, 0, 0.3);
    border-radius: 6px !important;
    margin-right: 30px;
    height: 35px;
    text-shadow: none;
}


// .btn:focus,
// .btn.active:focus {
//     outline: thin dotted;
//     outline: 5px auto -webkit-focus-ring-color;
//     outline-offset: -2px;
//     text-decoration: none;
//     background: #c5c5c5;
//     -moz-box-shadow: inset 0px 50px 85px rgba(0, 0, 0, .5);
//     box-shadow: inset 0px 50px 85px rgba(0, 0, 0, .5);
//     border: 1px solid #999;
// }
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
	color: black;
	background-color: var(--bs-btn-active-bg);
	border-color: var(--bs-btn-active-border-color);
}

.btn:hover,
.btn:focus {
    // color: #333333;
    text-decoration: none;
}
button:focus {
    box-shadow: none;
}

.btn:active,
.btn.active {
    outline: 0;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    text-decoration: none;
    background: #c5c5c5;
    -moz-box-shadow: inset 0px 50px 85px rgba(0, 0, 0, .5);
    border: .5px solid #0c0c0c;
    box-shadow: 0 2px 2px 2px #444;
}

.btn.filter {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
}

#locations-search-submit {
    height: 34px;
    margin-top: -5px;
}


.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('../../_assets/ajax-loader.gif') 50% 50% no-repeat rgb(249, 249, 249);
    opacity: .8;
}

#deposit-only-atms-container {
    margin-left: 15px;
}

.inptsearch {
    margin-top: 2px !important;
    padding: 0 0 3px 3px;
}

.btsearch {
    height: 34px;
}

.suratm {
    margin-top: 4px;
}

.current-pos-container {
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 2px;
    padding: 3px;
    cursor: pointer;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .3);
    height: 30px;
    width: 38px;
    position: relative;
}

.no-padding {
    padding: 0 !important;
    margin: 0 !important;
}

a.get-directions-popup {
    color: #148295 !important;
    text-decoration: none;
}