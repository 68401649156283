@import "../../../components/_shared/_styles/component-base";

:global #alerts {
  background: $site-alert-bg;
  position: relative;
  z-index: 60;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: $site-alert-gradient-fallback;
    background: linear-gradient(180deg, rgba($site-alert-gradient-top,1) 0%, rgba($site-alert-gradient-bottom,0.1) 100%);
  }

  @include media-breakpoint-down(md) {
    &:before {
        background: $site-alert-gradient-fallback;
        background: linear-gradient(180deg, rgba($site-alert-gradient-top,0.5) 0%, rgba($site-alert-gradient-bottom,0.1) 100%);
    }
}

  :global .alert {
    border: 0;
    background: none;
    margin: 0;
    padding: 0;
    width: 100%;

    display: none;
    height: 0;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: height 350ms ease-in-out, opacity 750ms ease-in-out;
    -moz-transition: height 350ms ease-in-out, opacity 750ms ease-in-out;
    transition: height 350ms ease-in-out, opacity 750ms ease-in-out;

    &.is-visible {
      display: block;
      height: auto;
      opacity: 1;
    }
    @include media-breakpoint-down(md) {
        .alert-content {
            padding-left: 0;
        }
        .icon {
            display: none;
        }
    }
  }

  :global .container {
    padding-right: 0;
  }

  :global .alert-content {
    position: relative;
    padding: {
      top: 15px;
      bottom: 15px;
      left: 40px;
      right: 60px;
    }    
    color: $site-alert-text;
    @include font-size(13px);
    border-bottom: 1px solid $site-alert-content-border;
  }

  :global .icon {
    position: absolute;
    left: 0;
    bottom: 20px;
    top: 20px;
  }

  :global .title {
    font-weight: 700;
    color: $site-alert-title;
    @include font-size(13px);
  }

  :global p {
    margin-bottom: 0;
  }

  :global .link-list {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      li {
        position: relative;
        margin-right: 10px;
        padding-right: 10px;
        color: $site-alert-title;
        &:after {
          content: '|';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &:last-child {
          margin-right: 0;
          padding-right: 0;

          &:after {
            display: none;
          }
        }

        a {
          font-weight: 700;
        }
      }
    }
  }

  :global button.close-alert {
    position: absolute;
    top: 10px;
    bottom: 10px;
    right: 20px;
    background: none;
    border: 0;
  }

  :global .alert-danger {
    .title {
        color: $site-alert-danger;
    }
  }
}
