@import "../../components/_shared/_styles/component-base";

.content-block {
    padding-top: 190px;
    background: $content-bg-no-gradient;
    background: $content-bg-gradient;

    @include media-breakpoint-down(lg) {
        padding-top: 100px;
    }

    //Layouts Global Settings
    .meta {
        color: $content-meta-info;
        @include font-size(18px);
        span {
            display: block;
        }
    }

    //Share Button
    .share {
        text-align: right;
    }

    .btn-share {
        @include font-size(16px);
        padding-left: 25px;
        padding-right: 25px;
    }

    .social {
        margin-top: 10px;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;

            li {
                padding-left: 5px;
                margin-bottom: 0;
                div {
                    cursor: pointer;
                }
                img {
                    width: 30px;
                }
            }
        }
    }

    .title {
        margin-bottom: 40px;
        .share {
            text-align: left;
            margin-top: 40px;
        }
        .social ul {
            justify-content: flex-start;
        }

    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
        color: $content-headers;
    }

    h5, .h5, h6, .h6 {
        color: $content-headers-secondary;
    }

    h1, .h1 {
        @include font-size(36px);
        font-weight: 400;
    }

    h2, .h2 {
        @include font-size(30px);
        margin-bottom: 15px;
    }

    h3, .h3 {
        @include font-size(24px);
        margin-bottom: 10px;
    }

    h4, .h4 {
        @include font-size(20px);
        margin-bottom: 10px;
    }

    h5, .h5 {
        @include font-size(18px);
        margin-bottom: 10px;
    }

    h6, .h6 {
        @include font-size(16px);
        font-weight: 700;
        margin-bottom: 10px;
    }

    p {
        @include font-size(16px);
        color: $content-text;
    }

    ul, ol {
        //padding-left: 20px;
        @include font-size(16px);
        margin-bottom: 30px;
        color: $content-text;
        li {
            line-height: 1.4em;
            margin-bottom: 8px;
        }
    }

    a.smartlink_default {
        content: $content-link;
    }

    a.smartlink_button {
        margin-right: 15px;
        margin-bottom: 15px;
    }

    .article-content {
        padding-bottom: 20px;
    }

    .inline-video {
        width: 100%;
        margin-bottom: 40px;

        .caption {
            @include font-size(14px);
            margin-top: 8px;
        }

    }

    figure {
        figcaption {
            @include font-size(14px);
            margin-top: 8px;
        }
    }

    //Table
    table {
        table-layout: fixed;
        width: 100%;
        min-width: 760px;
        thead {
            border: 1px solid $content-table-header-borders;
            tr {
                background: $content-table-header-bg;
                th {
                    border-left: 1px solid $content-table-header-borders;
                    padding: 20px;
                    text-align: center;
                    color: $content-table-header-title;
                    @include font-size(15px);
                    p{
                        text-align: center;
                        margin-bottom: 0;
                        color: $content-table-header-title;
                        @include font-size(15px);
                        width: 100%;
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid $content-table-body-borders;
                background: $content-table-body-bg;
                th, td {
                    padding: 15px 10px;
                    border-left: 1px solid $content-table-body-borders;
                    @include font-size(14px);
                    p{
                        margin-bottom: 0;
                        width: 100%;
                        @include font-size(14px);
                    }
                    &:first-child {
                        border-left: 0;
                    }
                }
            }
        }
    }

    .scrollable {
        overflow-x: auto;
        padding-bottom: 10px;
        margin-bottom: 40px;
    }

    .columns {
        display: flex;

        .column {
            padding-left: 15px;
            padding-right: 15px;
            &.col-two {
                width: 50%;
            }
        }

        @include media-breakpoint-down(lg) {
            .column {
                &.col-two {
                    width: 100%;
                }
            }
        }
    }

    //Blog - Tags
    .tags,
    .sidebar {
        margin-top: 40px;
        ul.link-list {
            margin: 0;
            padding: 0;
            list-style: none;
            li a {
                font-weight: 600;
            }
        }
    }

    .sidebar h2 {
        @include font-size(22px);
        font-weight: 600;
    }

    /* Single Event */
    .single-events {

        .single-event {
            border-top: 1px solid $content-events-border;
            display: flex;
            padding-top: 40px;
            padding-bottom: 40px;
            .event-details {
                flex: 1;
                padding-right: 100px;
                .avatar {
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
            .avatar {
                width: 180px;
            }
        }

        @include media-breakpoint-down(lg) {
            .single-event {
                .event-details {
                    padding-right: 0;
                }
            }
        }
    }

    //Content Layouts
    &-article {
        .title-event {
            h1 {
                margin-bottom: 20px;
            }
        }
    }

    &-blog {
        .title-blog {
            h1 {
                margin-bottom: 20px;
            }
        }
    }

    &.simplified {
        h1 {
            margin-bottom: 40px;
        }
        img {
            margin-bottom: 30px;
        }
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    //Page Layouts
    &.blog {
        .share {
            text-align: left;
        }
    }

    &.generic {
        .share {
            text-align: left;
        }
    }
}
