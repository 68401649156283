@import "variables_theme_base";

/*************************************
    Begin Theme
*************************************/

@font-face {
    font-family: "MuseoSlab";
    src: url('./App/_styles/fonts/MuseoSlab100/font.woff2') format('woff2'), // fonts/MuseoSlab100/font.woff2
         url('./App/_styles/fonts/MuseoSlab100/font.woff') format('woff');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: "MuseoSlab";
    src: url('./App/_styles/fonts/MuseoSlab300/font.woff2') format('woff2'),
         url('./App/_styles/fonts/MuseoSlab300/font.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: "MuseoSlab";
    src: url('./App/_styles/fonts/MuseoSlab500/font.woff2') format('woff2'),
         url('./App/_styles/fonts/MuseoSlab500/font.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: "MuseoSlab";
    src: url('./App/_styles/fonts/MuseoSlab700/font.woff2') format('woff2'),
         url('./App/_styles/fonts/MuseoSlab700/font.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: "MuseoSlab";
    src: url('./App/_styles/fonts/MuseoSlab900/font.woff2') format('woff2'),
         url('./App/_styles/fonts/MuseoSlab900/font.woff') format('woff');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: "MuseoSlab";
    src: url('./App/_styles/fonts/MuseoSlab1000/font.woff2') format('woff2'),
         url('./App/_styles/fonts/MuseoSlab1000/font.woff') format('woff');
    font-weight: 1000;
    font-style: normal;
  }

/*-------------------------------------
     Default Colors
---------------------------------------*/
/* Primary Colors */
$primary: #006EF5;
$primary-light: #81BAF9;
$primary-lighter: #D6E7F1;
$primary-medium: #002C6C;
$primary-medium-2: #193C78;
$primary-dark: #0A233F;
$primary-darker: #061C40;

/* Secondary Colors */
$secondary-lighter: #BCFFFB;

/* Tertiary Colors */
$tertiary: #3C5072;
$tertiary-medium: #81BAF9;
$tertiary-light: #F4F9FF;

$tertiary-accent-01: #008139;
$tertiary-accent-02: #DD2C34;

/* Grays */
$gray: #4E4E4E;
$gray-dark: #333333;
$gray-darker: #080808;
$gray-medium: #D6D6D6;
$gray-light: #EFECEC;
$gray-lighter: #F7F7F7;

/* Hovers & Active States */
$primary-hover: #0D2C6C;
$primary-active: #0D2C6C;

/* Accents */
$danger: #DD2C34;
$success: #008139;

/* Prime Colors */
$dark: #212529;
$light: #f8f9fa;
$white: #FFFFFF;
$black: #000000;

/* Text Colors */
$text-primary: $gray-darker;
$text-light: $light;
$text-dark: $dark;


/*-------------------------------------
     FontFaces
---------------------------------------*/
$font-family-sans-serif: 'PublicSans', sans-serif !important;
$font-family-manrope: 'Manrope', sans-serif !important;
$font-family-monospace: 'MuseoSlab', sans-serif !important;


/*-------------------------------------
    Backgrounds - Default
---------------------------------------*/
$bg-01: $gray-lighter;
$bg-02: $tertiary-light;
$bg-03: $primary-darker;

/*-------------------------------------
    Buttons and Links
---------------------------------------*/
//Button Default
$text-color: $primary;
$text-color-hover: $primary-hover;

$btn-font-family: $font-family-manrope;

//Button Primary
$btn-primary-bg: $primary;
$btn-primary-text: $white;
$btn-primary-border: $primary;
$btn-primary-bg-hover: $primary-hover;
$btn-primary-text-hover: $white;
$btn-primary-border-hover: $primary-hover;

//Button Secondary
$btn-secondary-bg: $white;
$btn-secondary-text: $primary;
$btn-secondary-border: $primary;
$btn-secondary-bg-hover: $white;
$btn-secondary-text-hover: $primary-hover;
$btn-secondary-border-hover: $primary-hover;

//Button Transparent
$btn-transparent-text: $primary;
$btn-transparent-bg: rgba($white, 0.5);
$btn-transparent-border: rgba($white, 0);
$btn-transparent-text-hover: $primary-hover;
$btn-transparent-bg-hover: rgba($white, 0.5);
$btn-transparent-border-hover: $primary-hover;

//Button Menu
$btn-menu-bg: rgba($white, 0.7);
$btn-menu-bg-hover: $primary-hover;
$btn-menu-text-hover: $white;

/*-------------------------------------
    Site Alerts
---------------------------------------*/
$site-alert-bg: $white;
$site-alert-gradient-fallback: $white;
$site-alert-gradient-top: $white;
$site-alert-gradient-bottom: $gray;
$site-alert-title: $primary-medium;
$site-alert-text: $gray;
$site-alert-content-border: $gray-medium;
$site-alert-danger: $danger;

/*-------------------------------------
    Toolbar
---------------------------------------*/
$toolbar-bg: $primary-medium;

$toolbar-site-select-text: $white;
$toolbar-site-select-text-active: $primary;
$toolbar-site-select-text-active-light-header: $white;

$toolbar-link-text: $white;
$toolbar-link-text-hover: $white;
$toolbar-link-divider: $white;
$toolbar-link-animation: $white;

$toolbar-login-bg: $primary;
$toolbar-login-bg-active: $primary-light;
$toolbar-login-text: $white;
$toolbar-login-text-active: $white;
$toolbar-login-icon: url('../../../_assets/icon-lock.svg');
$toolbar-login-icon-active: url('../../../_assets/x-close-white.svg');

$toolbar-search-icon: url('../../../_assets/icon-search-white.svg');
$toolbar-search-icon-active: url('../../../_assets/x-close-white.svg');

$toolbar-search-panel-bg: $white;
$toolbar-search-panel-bg-active: $white;
$toolbar-search-panel-label: $gray;
$toolbar-search-panel-label-active: $primary-medium;
$toolbar-search-panel-label-bg: $white;

$toolbar-search-input-border: $gray-medium;
$toolbar-search-input-text: $black;

$toolbar-search-icon-color: $primary;

/*-------------------------------------
    Cookie Banner
---------------------------------------*/
$cookie-banner-bg: $primary-darker;
$cookie-banner-text: $white;
$cookie-banner-link: $primary;

/*-------------------------------------
    Header
---------------------------------------*/
$header-bg: rgba(255, 255, 255, 0);
$header-bg-locked: rgba(255, 255, 255, 1);

$header-bg-light: rgba(255, 255, 255, 0);
$header-bg-light-locked: $primary-medium;

$header-mobile-bg: $white;
$header-mobile-light-bg: $primary-medium;

$header-mobile-btn: $primary;
$header-mobile-btn-text: $white;

/*-------------------------------------
    Footer
---------------------------------------*/

$footer-upper-bg: $white;
$footer-upper-text: $primary-medium;
$footer-upper-divider: $gray-medium;
$footer-upper-title: $primary-medium;
$footer-upper-link: $primary;

$footer-bottom-bg: $primary-medium;
$footer-bottom-link: $primary-lighter;
$footer-bottom-divider-link: $white;
$footer-bottom-text: $white;

/*-------------------------------------
    Eyebrow - Global/Default
---------------------------------------*/
$eyebrow-text: $white;

/*-------------------------------------
    Intro - Global/Default
---------------------------------------*/
$intro-title: $primary-medium;
$intro-text: $dark;
$intro-title-light: $white;
$intro-text-light: $white;

/*-------------------------------------
    Main Menu (Desktop)
---------------------------------------*/
$main-menu-link: $primary;

$main-menu-submenu-bg: $white;
$main-menu-submenu-title: $primary-medium;
$main-menu-submenu-link: $primary;
$main-menu-submenu-link-hover: $primary-medium;
$main-menu-submenu-link-active: $primary-medium;

$main-menu-submenu-btn-primary-bg: $primary;
$main-menu-submenu-btn-primary-text: $white;
$main-menu-submenu-btn-primary-bg-hover: $primary-hover;
$main-menu-submenu-btn-primary-text-hover: $white;

$main-menu-promo-bg: $white;
$main-menu-promo-border: $gray-medium;
$main-menu-promo-text: $primary-medium;

$main-menu-promo-btn-bg: rgba(255, 255, 255, 0.5);
$main-menu-promo-btn-border: rgba(255, 255, 255, 0);
$main-menu-promo-btn-text: $primary;

$main-menu-promo-btn-bg-hover: rgba(255, 255, 255, 0.5);
$main-menu-promo-btn-border-hover: $primary-hover;
$main-menu-promo-btn-text-hover: $primary-hover;

$main-menu-link-light: $primary-lighter;
$main-menu-link-hover: $white;
$main-menu-link-active: $white;
$main-menu-btn-light: rgba(255, 255, 255, 0.2);

//Menu Button - Default
$main-menu-btn: rgba(255, 255, 255, 0.3);
$main-menu-btn-text: $primary;
$main-menu-btn-hover: $primary-hover;
$main-menu-btn-hover-text: $white;
$main-menu-btn-font-family: $font-family-manrope;

//Sticky Menu Button - Default
$main-menu-btn-sticky: $primary;
$main-menu-btn-text-sticky: $white;
$main-menu-btn-hover-sticky: $primary-hover;
$main-menu-btn-hover-text-sticky: $white;

//Dark Theme - Menu Button
$main-menu-btn-dark: rgba(255, 255, 255, 0.3);
$main-menu-btn-text-dark: $white;
$main-menu-btn-hover: $primary;
$main-menu-btn-hover-text: $white;

//Dark Theme - Sticky Menu Button
$main-menu-btn-dark-sticky: $primary;
$main-menu-btn-text-dark-sticky: $white;
$main-menu-btn-hover-dark-sticky: rgba(255, 255, 255, 0.7);
$main-menu-btn-hover-dark-text-sticky: $primary-hover;

/*-------------------------------------
    Main Menu (Mobile)
---------------------------------------*/
$mobile-menu-icon-bg: $primary-medium; //Default
$mobile-menu-icon-light-bg: $white;

//Mobile Menu Container
$mobile-menu-bg: $white;
$mobile-menu-border: $gray-medium;

//Mobile Menu Tabs
$mobile-menu-tab-bg: transparent;
$mobile-menu-tab-border: transparent;
$mobile-menu-tab-text: #717171;
$mobile-menu-tab-active-bg: $white;
$mobile-menu-tab-active-border: $primary-medium;
$mobile-menu-tab-active-text: $primary-medium;

$mobile-menu-prome-text: $primary-medium;

$mobile-menu-toolbar-link: $primary;

$mobile-search-label-bg: $white;
$mobile-search-label: $gray;
$mobile-search-border: $gray-medium;
$mobile-search-icon: url('../_images/magnifying-glass-blue.svg');
$mobile-search-input-text: $black;

$mobile-search-btn-icon: url('../../../../_assets/icon-search-white.svg');
$mobile-search-btn-text: $white;

//Slinky Menu
$mobile-slinky-bg: $white;
$mobile-slinky-border: $gray-medium;
$mobile-slinky-link: $primary;
$mobile-slinky-link-icon: url('../_images/mobile-menu-chevron.svg');

$mobile-slinky-header-border: $gray-medium;
$mobile-slinky-header-bg: $gray-lighter;
$mobile-slinky-back-title: $primary;
$mobile-slinky-back-icon: url('../_images/mobile-menu-chevron.svg');
$mobile-slinky-title: $primary;

$mobile-slinky-text: $gray;

$mobile-promo-text: $primary-medium;

/*-------------------------------------
    Hero and Hero.Small
---------------------------------------*/
//Hero Light Version
$hero-light-title: $primary-medium;
$hero-light-text: $gray-darker;
$hero-light-watermark: url('_images/hero-watermark-light.svg'); //TODO: PS to update to new watermark
$hero-light-no-gradient: rgba(255, 255, 255, 1);
$hero-light-gradient: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 60%);
$hero-light-text-bg-mobile: rgba(246,246,246,1);
$hero-light-gradient-mobile: linear-gradient(180deg, rgba(255,255,255,0) 50%, rgba(246, 246, 246,1) 90%), linear-gradient(-90deg, rgba(255,255,255,0) 0%, rgba(246, 246, 246,0.2) 100%);

//Hero Dark Version
$hero-dark-title: $white;
$hero-dark-text: $white;
$hero-dark-watermark: url('_images/hero-watermark-dark.svg');
$hero-dark-no-gradient: rgba(0,25,61, 1);
$hero-dark-gradient: linear-gradient(180deg, rgba(0,25,61,1) 10%, rgba(255,255,255,0) 50%),linear-gradient(90deg, rgba(0,25,61,1) 30%, rgba(255,255,255,0) 60%),linear-gradient(0deg, rgba(0,45,108,0.7) 0%, rgba(255,255,255,0) 20%);
$hero-dark-text-bg-mobile: rgba(0,25,61,1);
$hero-dark-gradient-mobile: linear-gradient(180deg, rgba(0,25,61,0.8) 0%, rgba(255,255,255,0) 80%), linear-gradient(90deg, rgba(0,25,61,0.5) 0%, rgba(255,255,255,0) 80%), linear-gradient(0deg, rgba(0,25,61,1) 10%, rgba(255,255,255,0) 60%);

//Hero Small (Light)
$hero-small-light-text-bg-mobile: rgba(246, 246, 246, 1);
$hero-small-light-gradient-mobile: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(216,216,216,0.8) 80%);

//Hero Small (Dark)
$hero-small-dark-text-bg-mobile: rgba(0,25,61,1);
$hero-small-dark-gradient-mobile: linear-gradient(180deg, rgba(0,25,61,1) 0%, rgba(255,255,255,0) 80%);

//Hero Gradient
$hero-gradient-title: $white;
$hero-gradient-text: $white;
$hero-gradient-link: $secondary-lighter;
$hero-gradient-default: rgba(40,92,165, 1);
$hero-gradient: linear-gradient(180deg, rgba(40,92,165,1) 0%, rgba(0,45,108,1) 100%);
$hero-gradient-watermark: url('_images/hero-watermark-light-clean.svg');

//404 Page Hero
$hero-404-bg: $primary-darker;
$hero-404-watermark: url('_images/hero-watermark-light-clean.svg');
$hero-404-title: $white;
$hero-404-text: $white;
$hero-404-link: $secondary-lighter;

//Hero Sub-Component : Accordions
$hero-accordion-bg: rgba(255, 255, 255, 0.1);
$hero-accordion-title: $white;
$hero-accordion-main-title: $white;
$hero-accordion-text: $white;
$hero-accordion-border:  none;
$hero-accordion-borders: 1px solid rgba(255, 255, 255, 0.3);
$hero-accordion-borders-active: 1px solid transparent;
$hero-accordion-link: $primary-lighter;
$hero-accordion-icon: url('_images/plus-lightblue.svg') no-repeat center center;
$hero-accordion-icon-active: url('_images/minus-lightblue.svg') no-repeat center center;

//Hero Sub-Component : Boxed
$hero-box-bg: rgba(255, 255, 255, 0.1);
$hero-box-title: $white;
$hero-box-text: $white;
$hero-box-list-borders: 1px solid rgba($white, 0.3);
$hero-box-link: $primary-lighter;

//Hero Sub-Component :  Rates Table
$hero-rates-bg: rgba($white, 0.1);
$hero-rates-title: $white;
$hero-rates-table-title: $white;
$hero-rates-table-header-border: $white;
$hero-rates-table-border: $primary-medium;
$hero-rates-table-text: $white;
$hero-rates-table-link: $primary-lighter;

//Hero Filter
$hero-filter-no-gradient: rgb(255,255,255);
$hero-filter-gradient: linear-gradient(180deg, rgba(255,255,255,0.85) 0%, rgba(246,246,246,1) 100%);
$hero-filter-watermark: url('../../_assets/hero-watermark-dark-clean.svg');

$hero-filter-box-border: $primary-light;
$hero-filter-box-title: $primary;
$hero-filter-box-link: $primary;

//Selectbox Styling - NOT Material UI
$hero-select-border: $gray-medium;
$hero-select-icon: url('/assets/images/ui/single-chevron-down-blue.svg');
$hero-select-color: $primary-medium;


/*-------------------------------------
    Promos
---------------------------------------*/
//Primary - Light
$promo-primary-light-title: $white;
$promo-primary-light-text: $white;
$promo-primary-light-eyebrow: $white;
$promo-primary-light-no-gradient: rgb(0,93,255);
$promo-primary-light-gradient-top: rgba(0,93,255,0.3);
$promo-primary-light-gradient-bottom: rgba(0,247,255,0.3);

//Primary - Dark
$promo-primary-dark-title: $black;
$promo-primary-dark-text: $black;
$promo-primary-dark-eyebrow: $black;
$promo-primary-dark-no-gradient: rgb(255,255,255);
$promo-primary-dark-gradient-top: rgba(255,255,255,0.85);
$promo-primary-dark-gradient-bottom: rgba(104,212,255,0.5);

//Secondary - Light
$promo-secondary-light-title: $dark;
$promo-secondary-light-text: $dark;
$promo-secondary-light-eyebrow: $dark;
$promo-secondary-light-no-gradient: rgb(255,255,255);
$promo-secondary-light-gradient: linear-gradient(90deg, rgba(255,255,255,0.85) 0%, rgba(255,255,255,0.18) 100%);
$promo-secondary-light-gradient-offset: linear-gradient(270deg, rgba(255,255,255,0.85) 0%, rgba(255,255,255,0.18) 100%);
$promo-secondary-light-gradient-top: rgba(255,255,255,0.85);/* Is removed by PQs story, may have to manually remove later */
$promo-secondary-light-gradient-bottom: rgba(255,255,255,0.18);/* Is removed by PQs story, may have to manually remove later */

//Secondary - Dark
$promo-secondary-dark-title: $white;
$promo-secondary-dark-text: $white;
$promo-secondary-dark-eyebrow: $white;
$promo-secondary-dark-no-gradient: rgb(25,60,120);
$promo-secondary-dark-gradient: linear-gradient(90deg, rgba(25,60,120,0.5) 0%, rgba(0,113,253,0.5) 100%);
$promo-secondary-dark-gradient-offset: linear-gradient(270deg, rgba(255,255,255,0.85) 0%, rgba(255,255,255,0.18) 100%);
$promo-secondary-dark-gradient-top: rgba(25,60,120,0.5);/* Is removed by PQs story, may have to manually remove later */
$promo-secondary-dark-gradient-bottom: rgba(0,113,253,0.5);/* Is removed by PQs story, may have to manually remove later */

//Two-Thirds - Light
$promo-two-thirds-light-title: $white;
$promo-two-thirds-light-text: $white;
$promo-two-thirds-light-link: $primary-lighter;
$promo-two-thirds-light-no-gradient: rgb(1,30,80);
$promo-two-thirds-light-gradient-left: rgba(1,30,80,0.64);
$promo-two-thirds-light-gradient-right: rgba(0,25,69,0.95);

$promo-two-thirds-dark-title: $white;
$promo-two-thirds-dark-text: $white;
$promo-two-thirds-dark-link: $primary-lighter;
$promo-two-thirds-dark-no-gradient: rgb(0,0,0);
$promo-two-thirds-dark-gradient-left: rgba(0,0,0,0.88);
$promo-two-thirds-dark-gradient-right: rgba(0,0,0,0.29735644257703087);

//Short - Light
$promo-short-light-title: $white;
$promo-short-light-text: $white;
$promo-short-light-eyebrow: $white;
$promo-short-light-link: $white;
$promo-short-light-no-gradient: rgb(40,92,165);
$promo-short-light-gradient: rgba(40,92,165,0) 0%, rgba(40,92,165,0.30994404597776615) 3%, rgba(62,97,147,0.57) 5%, rgba(35,87,159,0.71) 9%, rgba(31,82,153,0.91) 14%, rgba(27,77,147,0.99) 20%, rgba(28,78,147,1) 25%, rgba(0,45,108,1) 100%;

//Short - Dark
$promo-short-dark-title: $primary;
$promo-short-dark-text: $black;
$promo-short-dark-eyebrow: $black;
$promo-short-dark-link: $primary;
$promo-short-dark-no-gradient: rgb(255,255,255);
$promo-short-dark-gradient: rgba(255,255,255,0) 0%, rgba(240,240,240,1) 20%, rgba(243,243,243,1) 80%, rgba(237,237,237,1) 100%;

/*-------------------------------------
    Category Cards
---------------------------------------*/
$category-intro-title: $primary-medium;
$category-intro-text: $dark;;
$category-intro-link: $primary;

//Category Layout
$category-layout-bg-color: transparent;

//Category Cards
$category-bg: $white;
$category-title: $primary-medium;
$category-text: $gray-darker;
$category-link: $primary;
$category-link-hover: $primary-hover;

//Category Cards Rates Table
$category-table-text: $black;
$category-table-border: $gray-medium;

//Category.Filled
$category-filled-intro-title: $white;
$category-filled-intro-text: $white;
$category-filled-bg: $white;
$catefory-filled-title: $primary;
$catefory-filled-text: $black;

//card.rate
$category-rate-bg: $white;
$category-rate-title: $primary;
$category-rate-table-title: $black;
$category-rate-table-text: $black;
$category-rate-table-text-small: $black;
$category-rate-table-link: $primary;
$category-rate-table-border: $gray-medium;

//Rates.collapsable
$category-collapsable-container-bg: rgb(231,228,228);
$category-collapsable-container-gradient: rgba(231,228,228,1) 0%, rgba(255,255,255,1) 100%;
$category-collapsable-intro-title: $black;
$category-collapsable-intro-link: $primary;

$category-collapsable-card-bg: $white;
$category-collapsable-card-link: $primary;
$category-collapsable-card-table-text: $black;
$category-collapsable-card-text: $black;
$catogory-collapsable-card-border: $primary-light; //Mobile

//Rates.small
$category-small-container-bg: rgb(231,228,228);
$category-small-container-gradient: rgba(231,228,228,1) 0%, rgba(255,255,255,1) 100%;
$category-small-intro-title: $black;
$category-small-intro-link: $primary;

$category-small-card-bg: $white;
$category-small-card-link: $primary;
$category-small-card-table-text: $black;
$category-small-card-text: $black; //Mobile
$catogory-small-card-border: $primary-light; //Mobile

/*-------------------------------------
    Rates.Embed
---------------------------------------*/
$rates-embed-stage: $white;
$rates-embed-container-bg: $white;
$rates-embed-intro-title: $primary-medium;
$rates-embed-intro-text: $black;

/*-------------------------------------
    Rates.Embed Rates Table
---------------------------------------*/
$rates-table-table-bg: $white;
$rates-table-table-header: $primary-medium;
$rates-table-table-border: $gray-medium;
$rates-table-table-text: $dark;

$rates-table-filter-label: $primary-medium;

$rates-table-disclosure-text: $black;
$rates-table-disclosure-block: none;

/*-------------------------------------
    Rates.Filter
---------------------------------------*/
$rates-filter-bg: $white;
$rates-filter-label: $primary-medium;
$rates-filter-input-border: $gray-medium;
$rates-filter-input-text: $dark;

/*-------------------------------------
    Disclosures
---------------------------------------*/
$disclosures-bg: $gray-lighter;
$disclosures-text: $gray-dark;

/*-------------------------------------
    Carousel.Testimonial
---------------------------------------*/
$carousel-testimonial-intro-title: $primary-medium;
$carousel-testimonial-intro-desc: $gray-darker;
$carousel-testimonial-quote: $black;
$carousel-testimonial-cite: $black;
$carousel-testimonial-inline-link: $primary;
$carousel-testimonial-quote-opening: url('./quotes-gray-before.svg');
$carousel-testimonial-quote-closing: url('./quotes-gray-after.svg');

/*-------------------------------------
    Carousel.Large
---------------------------------------*/
$carousel-large-bg: $primary-darker;
$carousel-large-title: $white;

$carousel-large-nav-link: $primary-lighter;
$carousel-large-nav-link-hover: $white;
$carousel-large-nav-link-active: $white;
$carousel-large-card-gradient-default: $white;
$carousel-large-panel-gradient-bottom: rgba(#193c78, 0);
$carousel-large-panel-gradient-top: rgba(#193c78, 1);

$carousel-large-panel-eyebrow: $white;
$carousel-large-panel-title: $primary-lighter;
$carousel-large-panel-text: $white;
$carousel-large-panel-link: $primary-lighter;

/*-------------------------------------
    Feature
---------------------------------------*/
$feature-bg: $white;
$feature-panel-divider: $primary-light;
$feature-title-highlight: $black;
$feature-rates-highlight: $primary-medium;
$feature-rates-divider: $primary-light;

$feature-content-title: $black;
$feature-content-text: $dark;
$feature-content-link: $primary;

$feature-rates-table: transparent;
$feature-rates-table-headers: $black;
$feature-rates-table-body-headers: $black;
$feature-rates-table-body-text: $black;

/*-------------------------------------
    TwoThirds.Filter
---------------------------------------*/
$twothirds-intro-title: $primary-medium;
$twothirds-intro-text: $dark;
$twothirds-left-text: $primary-medium;
$twothirds-left-divider: $tertiary;

$twothirds-link: $primary;
$twothirds-link-border: $primary-light;
$twothirds-link-icon: url('../../_assets/chevron-right-blue.svg') no-repeat 60px center;

$twothirds-content-link: $primary;

/*-------------------------------------
    Accordions - Default
---------------------------------------*/
$accordion-bg: transparent;
$accordion-border: $primary-light;
$accordion-title: $primary-medium;
$accordion-text: $primary-medium;

$accordion-icon: url('../../_assets/plus-blue.svg') no-repeat center center;
$accordion-icon-active: url('../../_assets/minus-blue.svg') no-repeat center center;

/*-------------------------------------
    Bio Cards
---------------------------------------*/
$bio-card-bg: $white;
$bio-card-name: $primary;
$bio-card-title: $primary-medium;
$bio-card-text: $black;

/*-------------------------------------
    Blog | News | Event Cards
---------------------------------------*/
$social-page-gradient-default: rgba($white, 1);
$social-page-gradient-top: rgba(255,255,255,0.85);
$social-page-gradient-bottom: rgba(246,246,246,1);

$card-news-border: $primary;
$card-news-bg: $white;
$card-news-image-placeholder: url('../../_assets/social-content-default.png');
$card-news-date: $primary-medium;
$card-news-title: $primary;
$card-news-text: $text-dark;

$card-blog-border: $primary;
$card-blog-bg: $white;
$card-blog-image-placeholder: url('../../_assets/social-content-default.png');
$card-blog-date: $primary-medium;
$card-blog-title: $primary;
$card-blog-text: $text-dark;

$card-event-border: $primary;
$card-event-bg: $white;
$card-event-image-placeholder: url('../../_assets/social-content-default.png');
$card-event-date: $primary-medium;
$card-event-title: $primary;
$card-event-text: $text-dark;
$card-event-icon: url('../../_assets/icon-calendar.svg');

//Social Filters Bar
$social-filters-bg: $white;
$social-filters-border: $primary-medium;
$social-filters-link: $primary;
$social-filters-link-hover: $primary-medium;
$social-filters-link-active: $primary-medium;
$social-filters-link-animation: transparent;
$social-filters-link-animation-hover: $primary-medium;
$social-filters-link-animation-active: $primary-medium;

/*-------------------------------------
    Tabs
---------------------------------------*/
$tabs-tab-text: $primary;
$tabs-tab-text-hover: $primary-medium;
$tabs-tab-text-active: $primary-medium;
$tabs-tab-text-active-border: $primary-medium;
$tabs-tab-divider-border: $primary-medium;

/*-------------------------------------
    Tabs.Tall
---------------------------------------*/
$talltabs-intro-title: $primary-medium;
$talltabs-intro-text: $dark;

$talltabs-tab-active-bg: $primary-darker;
$talltabs-tab-title: $primary;
$talltabs-tab-title-active: $white;
$talltabs-tab-text: $black;
$talltabs-tab-text-active: $white;

$talltabs-tabcontent-panel-bg: $primary-darker;
$talltabs-tabcontent-panel-bg-image: url('../../_assets/ripple-transparent.webp');
$talltabs-tabcontent-intro-title: $white;
$talltabs-tabcontent-intro-title: $white;
$talltabs-tabcontent-intro-text: $white;

$talltabs-tabcontent-headline: $white;
$talltabs-tabcontent-link: $secondary-lighter;
$talltabs-tabcontent-text: $white;

$talltabs-tabcontent-hr: $tertiary;
$talltabs-rates-table-header-th: $white;
$talltabs-rates-table-border: $gray-medium;
$talltabs-rates-table-body-th: $white;
$talltabs-rates-table-body-text: $white;
$talltabs-rates-table-link: $primary-lighter;

$talltabs-link-list-hr: $tertiary;
$talltabs-link-list-link: $secondary-lighter;

//Mobile Versions
$talltabs-accordion-btn-text: $primary;
$talltabs-accordion-btn-text-active: $primary-medium;
$talltabs-accordion-btn-bg: $white;
$talltabs-accordion-btn-icon: url('../../_assets/plus-blue.svg');
$talltabs-accordion-btn-icon-active: url('../../_assets/minus-blue.svg');
$talltabs-accordion-btn-border: $primary-light;
$talltabs-accordion-desc-bg: $white;
$talltabs-accordion-desc-text: $black;

/*-------------------------------------
    Content Pages
---------------------------------------*/
$content-bg-no-gradient: rgba(255, 255, 255, 1);
$content-bg-gradient: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(243,241,241,1) 100%);

$content-meta-info: $primary-medium;

$content-headers: $primary-medium;
$content-headers-secondary: $black;
$content-text: $dark;
$content-link: $primary;

$content-table-header-bg: $white;
$content-table-header-borders: $gray-medium;
$content-table-header-title: $primary-medium;
$content-table-body-borders: $gray-medium;
$content-table-body-bg: transparent;

$content-events-border: $primary-medium;

/*-------------------------------------
    Anchors
---------------------------------------*/
$anchors-bg: $primary;
$anchors-link: $white;
$anchors-link-animation: $white;

/*-------------------------------------
    CTA Bar
---------------------------------------*/
$ctabar-bg: $primary-medium;
$ctabar-title: $white;
$ctabar-link: $primary-lighter;

$cta-primary-bg: $primary;
$cta-primary-text: $white;
$cta-primary-border: $primary;
$cta-primary-bg-hover: rgba(255, 255, 255, 0.7);
$cta-primary-text-hover: $primary-hover;
$cta-primary-border-hover: rgba(255, 255, 255, 0);
$cta-secondary-bg: $white;
$cta-secondary-text: $primary;
$cta-secondary-border: $primary;
$cta-secondary-bg-hover: $white;
$cta-secondary-text-hover: $primary-hover;
$cta-secondary-border-hover: $primary-hover;
/*-------------------------------------
    Image List
---------------------------------------*/
$imagelist-background: $white;
$imagelist-intro-title: $primary-medium;
$imagelist-intro-text: $dark;
$imagelist-text: $black;

/*-------------------------------------
    Links Listing
---------------------------------------*/
$links-listing-intro-title: $white;
$links-listing-intro-text: $white;
$links-listing-link: $primary-lighter;

/*-------------------------------------
    FAQs
---------------------------------------*/
$faqs-bg: $white;
$faqs-intro-title: $primary;
$faqs-intro-text: $black;
$faqs-link: $primary;
$faqs-faq-title: $primary-medium;
$faqs-faq-text: $dark;
$faqs-expand-link: $primary;
$faqs-section-bg: $bg-02;

/*-------------------------------------
    Stepwise
---------------------------------------*/
$stepwise-intro-title: $white;
$stepwise-intro-text: $white;

$stepwise-step-bg: $white;
$stepwise-step: $primary-medium;
$stepwise-step-title: $white;
$stepwise-step-text: $white;
$stepwise-step-link: $primary-lighter;

/*-------------------------------------
    Modals / Lightbox
---------------------------------------*/
$modal-overlay-bg: rgba($primary-darker, 0.7);

$modal-bg: $white;

$modal-title: $black;
$modal-title-border: $tertiary;
$modal-text: $dark;
$modal-link-list: $primary;
$modal-link: $primary;

$modal-bio-name: $primary-medium;
$modal-bio-title: $primary-medium;

$modal-apply-watermark: url('../../../_assets/hero-watermark-dark.png');
$modal-apply-input-border: $primary;
$modal-apply-small-text: $primary-medium;
$modal-apply-title: $black;
$modal-apply-divider: $gray-medium;
$modal-apply-note-text: $primary-medium;

$modal-flow-back-icon: url('../../../_assets/single-chevron-down-blue.svg');
$modal-flow-border: $gray-medium;
$modal-close-btn: url('./App/_assets/x-close-blue.svg');

/*-------------------------------------
    Table.Compare
---------------------------------------*/
$table-compare-no-bg: $white;
$table-compare-intro-title: $white;
$table-compare-intro-text: $white;

$table-compare-table-color: $white;
$table-compare-table-link: $secondary-lighter;

$table-compare-table-header-bg: $white;
$table-compare-table-header-text: $primary;
$table-compare-table-header-borders: $tertiary;

$table-compare-table-body-borders: $tertiary;

$table-compare-table-expand-row: $white;
$table-compare-table-expand-text: $primary;
$table-compare-table-expand-icon: url('../../../App/_assets/single-chevron-down-blue.svg');

$table-compare-table-footer-link: $secondary-lighter;
$table-compare-table-footer-borders: $tertiary;

//Mobile View (Quick Links)
$table-compare-quicklinks-border: $primary-lighter;
$table-compare-quicklinks-footer-title: $primary;
$table-compare-quicklinks-footer-link: $primary;
$table-compare-quicklinks-footer-text: $black;

$table-compare-mobile-table-text: $white;
$table-compare-mobile-table-link: $secondary-lighter;
$table-compare-mobile-table-header-bg: $white;
$table-compare-mobile-table-header-text: $primary;
$table-compare-mobile-table-header-border: $white;

$table-compare-mobile-table-body-borders: $tertiary;
$table-compare-mobile-table-footer-expander: $primary-lighter;
$table-compare-mobile-table-footer-expander-icon: url('../../../App/_assets/single-chevron-down-lightblue.svg');
$table-compare-mobile-table-footer-expander-borders: $tertiary;

/*-------------------------------------
    Content Pages
---------------------------------------*/
$content-bg-no-gradient: rgba(255, 255, 255, 1);
$content-bg-gradient: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(243,241,241,1) 100%);

$content-meta-info: $primary-medium;

$content-headers: $primary-medium;
$content-headers-secondary: $black;
$content-text: $dark;
$content-link: $primary;

$content-table-header-bg: $white;
$content-table-header-borders: $gray-medium;
$content-table-header-title: $primary-medium;
$content-table-body-borders: $gray-medium;
$content-table-body-bg: transparent;

$content-events-border: $primary-medium;

/*-------------------------------------
    Forms
---------------------------------------*/
$forms-input-border: $gray-medium;

//Placeholder text
$placeholder-text: $black;

//MUI Forms
$mui-select-icon: url("../../../_assets/select-down-chevron.svg");

//Custom Selectbox - NOT Material UI
$forms-select-bg: $white;
$forms-select-text: $dark;
$forms-select-border: $gray-medium;
$forms-select-icon: url('/assets/images/ui/select-down-chevron.svg');

//Custom Checkboxes - NOT Material UI
$forms-checkbox-label: $primary;
$forms-checkbox-checkmark-unchecked: url("/assets/images/ui/checkmark.svg") left 0px no-repeat;
$forms-checkbox-checkmark-checked: url("/assets/images/ui/checkmark.svg") left -25px no-repeat;

$forms-search-input-border: $primary;
$forms-search-submit-bg: $primary;
$forms-search-icon: url('/assets/images/ui/select-down-chevron-lightblue.svg');

/*-------------------------------------
    Locator and Locations
---------------------------------------*/
$locator-no-gradient: rgb(255,255,255);
$locator-gradient: linear-gradient(180deg, rgba(255,255,255,0.85) 0%, rgba(246,246,246,1) 100%);
$locator-watermark: url('../../_assets/hero-watermark-dark-clean.svg');

$locator-h1: $primary-medium;
$locator-h2: $primary-medium;
$locator-h3: $black;

$locator-back-link: $primary;
$locator-back-icon: url('../../_assets/single-chevron-down-blue.svg');

$locator-wrapper-bg: $white;

$locator-search-input-border: $gray-medium;
$locator-search-btn-bg: $primary;
$locator-search-filters-text: $primary;

$location-types-border: $gray-medium;
$location-types-border-hover: $primary-medium;
$location-types-checkmark: $primary-medium;

$location-list-border: $gray-medium;
$location-list-branch-icon: url('/assets/images/icons/icon-branch.svg');
$location-list-atm-icon: url('/assets/images/icons/icon-atm.svg');
$location-list-text: $black;
$location-list-link: $primary;

$location-filters-bg: $white;
$location-filters-hr: $gray-medium;
$location-filters-label: $black;
$location-filters-checkbox: $gray-medium;
$location-filters-checkbox-hover: $primary-medium;
$location-filters-checkbox-focus: $primary-medium;
$location-filters-label-disable: $gray-light;
$location-filters-checkbox-disable: $gray-light;
$location-filters-checkmark: $primary-medium;

$location-branch-text: $black;
$location-branch-borders: $gray-medium;
$location-branch-em: $primary-medium;

/*-------------------------------------
    Search Results
---------------------------------------*/
$search-results-bg: $gray-light;

$search-results-hits-border: $primary-light;
$search-results-hits-title: $primary;
$search-results-hits-link-hover: $primary-medium;
$search-results-hits-text: $black;
$search-results-hits-term: $primary-medium;

$search-results-filters-column-border: $tertiary;
$search-results-filters-list-border: $primary-light;
$search-results-filters-list-link: $primary;

$search-results-pagination-border: $primary;
$search-results-pagination-link: $primary;
$search-results-pagination-hover: $primary;
$search-results-pagination-link-hover: $white;

$search-results-pagination-disabled-border: $gray-medium;
$search-results-pagination-disabled-text: $primary;

$search-results-pagination-selected-bg: $primary-dark;
$search-results-pagination-selected-border: $primary-dark;
$search-results-pagination-selected-link: $white;

/*-------------------------------------
    Loading Screen
---------------------------------------*/
//$loading-bg: rgba(25, 60, 120, 0.7);
//$loading-message-bg: $primary;
$loading-message-text: $primary-medium;

/*-------------------------------------
    Error Page (Something Went Wrong)
---------------------------------------*/
$error-bg: $primary-darker;
$error-watermark: url("../_assets/ripple-transparent.webp");
$error-title: $white;
$error-text: $white;

/*************************************
    End Theme
*************************************/
