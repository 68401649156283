@import "../_shared/_styles/component-base";

:global .ctabar {
  background: $ctabar-bg;
  padding: {
    top: 40px;
    bottom: 40px;
  }

  :global .ctabar-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    > div {
      margin: 0 10px;
    }
  }

  :global h4 {
    color: $ctabar-title;
    @include font-size(30px);
    font-weight: 300;
  }

  :global a.btn {
    @include font-size(16px);
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  :global .link-list {
    ul {
        align-items: center;
        justify-content: center;
        flex-direction: row;
      li {
        padding-left: 10px;
        padding-right: 10px;
        a.smartlink_default {
            @include font-size(16px);
            font-weight: 600;
            display: flex;
            align-items: center;
            img {
                margin-right: 10px;
            }
            &:not(.smartlink_button) {
                color: $ctabar-link;
            }
        }
      }
    }
  }


  @include media-breakpoint-down(lg) {
    :global .ctabar-content {
      flex-direction: column;
      > div {
        margin: 0 10px 10px 10px;
      }
    }

    :global .link-list {
      ul {
        flex-direction: column;
        li {
          margin: {
            top: 10px;
            bottom: 10px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

}
