@import "../_shared/_styles/component-base";

:global .faqs {
  background-color: $faqs-section-bg;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .faqs-container {
    background: $faqs-bg;
    border-radius: 0.35rem;
    padding: 60px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
    -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
  }

  .intro {
    h2 {
        color: $faqs-intro-title;
    }
    p {
        color: $faqs-intro-text;
    }
}

  .faq-item {
    margin-bottom: 35px;
    .faq-content {
      position: relative;
      padding-bottom: 25px;
      color: $faqs-faq-text;
    }
    .open-popup-link {
      display: block;
      width: 100%;
      cursor: pointer;
      @include font-size(14px);
      color: $faqs-link;
      background-color: $faqs-bg;
      outline: none;

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    h3 {
      @include font-size(20px);
      font-weight: 700;
      color: $faqs-faq-title;
      margin-bottom: 20px;
    }
    p {
        @include font-size(16px);
        color: $faqs-faq-text;
    }

    a.smartlink_default {
      font-weight: 600;
    }
  }

  a.load-btn {
    display: block;
    font-weight: 700;
    color: $faqs-expand-link;
  }

  @include media-breakpoint-down(md) {
    .faqs-container {
      padding: 40px 15px;
    }
  }
}
