//@import '../themeVariables.scss';
@import "_styles/site";

//visibility will be overridden by code
.posh-widget-container {
  display: none;
}

//visibility will be overridden by code
.LPMcontainer, #posh-widget-button {
  visibility: hidden !important;
}
