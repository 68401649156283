@import "../../_shared/_styles/component-base";

.root {
  flex: 1;
  padding-left: 50px;

  p {
    @include font-size(36px);
    line-height: 1.2em;
    color: $carousel-testimonial-quote;
    position: relative;
    margin-bottom: 25px;

  }
  .quoted{
    &:before {
      content: $carousel-testimonial-quote-opening;
      position: absolute;
      top: -3px;
      left: -45px;
    }
    &:after {
      content: $carousel-testimonial-quote-closing;
      display: inline;
      padding-left: 10px;
      position: relative;
      top: 5px;
    }
  }
  cite {
    font-style: normal;
    color: $carousel-testimonial-cite;
    @include font-size(16px);
    font-weight: 500;
  }
  @include media-breakpoint-down(lg) {
    padding-bottom: 20px;

    p {
      @include font-size(25px);
      line-height: 1.4em;
    }

    cite span {
      display: block;
    }
  }
  @include media-breakpoint-down(md) {
    p {
      @include font-size(20px);
      line-height: 1.4em;
    }

    cite span {
      display: block;
    }
  }
}
