@import "../_shared/_styles/component-base";

.anchors {
  background: $anchors-bg;
  position: relative;
  padding: {
    top: 25px;
    bottom: 0;
  }

  :global &.isSticky {
    position: fixed;
    top: 64px;
    width: 100%;
    z-index: 999; //has to be lower than header so we can still use the megamenu
  }

  :global .link-list {
        width: 100%;
    ul {
        padding: 0;
        justify-content: center;
        flex-wrap: nowrap;
      li {
        padding: {
          left: 20px;
          right: 20px;
        }
        a.smartlink_default {
          @include font-size(24px);
          color: $anchors-link;
          display: block;
          position: relative;
          padding-bottom: 10px;
          white-space: nowrap;
          &:hover {
            text-decoration: none;
            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
  
              width: 100%;
              height: 4px;
              background: $anchors-link-animation;
              @include rounded(80px);
              @include animate(0.2s);            
            }
          }
          &.active {
            font-weight: 700;
            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
  
              width: 100%;
              height: 4px;
              background: $anchors-link-animation;
              @include rounded(80px);
              @include animate(0.2s);                
            }
            &:focus {            
              outline: 0;
            }
          }
        }
      }
    }
  }

  :global .alignCenter .link-list ul,
  :global .link-list.alignCenter ul {
    justify-content: center;
  }
  :global .alignLeft .link-list ul,
  :global .link-list.alignLeft ul {
    justify-content: flex-start;
  }
}


