@import "../../_shared/_styles/component-base";

.c-promo-short {
  position: relative;
  padding: {
    top: 100px;
    bottom: 100px;
  }
  :global .container {
    position: relative;
    z-index: 3;
  }

  .c-promo-short-inline-image {
    width: 50vw;

    position: absolute;
    top: 0;
    bottom: 0;

    z-index: 1;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .c-promo-short-gradient {
    width: 65vw;
    position: absolute;
    top: 0;
    bottom: 0;

    z-index: 2;
  }

  h2 {
    margin-bottom: 30px;
    @include font-size(36px);
    line-height: 1.2em;
    font-weight: 700;
  }

  p {
    @include font-size(18px);
  }

  //Light Text
  &.light {
    .c-promo-short-inline-image {
      right: 0;
    }
    .c-promo-short-gradient {
      background: $promo-short-light-no-gradient;
      background: linear-gradient(270deg, $promo-short-light-gradient);
      left: 0;
    }

    :global .eyebrow {
        color: $promo-short-light-eyebrow;
    }

    :global h2 {
      color: $promo-short-light-title;
    }

    :global p {
        color: $promo-short-light-text;
    }

    :global a:not(.smartlink_button) {
        color: $promo-short-light-link;
    }

    &.offset {
      .c-promo-short-inline-image {
        right: unset;
        left: 0;
      }
      .c-promo-short-gradient {
        background: $promo-short-light-no-gradient;
        background: linear-gradient(90deg, $promo-short-light-gradient);
        left: unset;
        right: 0
      }
    }
  }

  //Dark Text
  &.dark {
    .c-promo-short-inline-image {
      right: 0;
    }
    
    .c-promo-short-gradient {
      background: $promo-short-dark-no-gradient;
      background: linear-gradient(270deg, $promo-short-dark-gradient);
      left: 0;
    }

    :global .eyebrow {
      color: $promo-short-dark-eyebrow;
    }

    h2 {
        color: $promo-short-dark-title;
    }

    :global p {
        color: $promo-short-dark-text;
    }

    :global a:not(.smartlink_button) {
        color: $promo-short-dark-link;
    }

    &.offset {
      .c-promo-short-inline-image {
        right: unset;
        left: 0;
      }
      .c-promo-short-gradient {
        background: $promo-short-dark-no-gradient;
        background: linear-gradient(90deg, $promo-short-dark-gradient);
        left: unset;
        right: 0
      }
    }
  }

  @include media-breakpoint-down(lg) {
    padding-top: 320px;
    overflow: hidden;
    padding-bottom: 60px;

    h2 {
      @include font-size(28px);
    }

    .c-promo-short-inline-image {
      width: 100%;
      height: 375px;
    }

    .c-promo-short-gradient {
      width: 100%;
      height: 100%;
    }

    &.light {
      .c-promo-short-gradient {

        background: $promo-short-light-no-gradient;
        background: linear-gradient(180deg, $promo-short-light-gradient) !important;

        left: 0;
        right: 0;
        top: unset;
        bottom: -30%;
      }
    }

    &.dark {
      .c-promo-short-gradient {
        background: $promo-short-dark-no-gradient;
        background: linear-gradient(180deg, $promo-short-dark-gradient) !important;

        left: 0;
        right: 0;
        top: unset;
        bottom: -30%;
      }
    }
  }
}
