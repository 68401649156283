@import '../../../styles/variables.scss';

//base
.button {
  gap: 10px;
  border: none;
  display: flex;
  outline: none;
  cursor: pointer;
  width: max-content;
  border-radius: 3px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.24px;
  font-family: $font-family;
}

//rounded
.rounded {
  border-radius: 25px;
  font-weight: 400;
}

//block
.block {
  width: 100%;
}

//size
.large {
  padding: 11px;
  font-size: 20px;
  line-height: 27px;
}

.medium {
  padding: 9px;
  font-size: 18px;
  line-height: 25px;
}

.small {
  padding: 12px;
  font-size: 14px;
  line-height: 16px;
}

//primary
.primary {
  background: $primary-bright;
  color: $white;
}
.primary:hover,
.primary:active,
.primary:focus,
.secondary:hover,
.secondary:active,
.secondary:focus  {
  background: $button-hover;
  color: $white;
}

.secondary:active:enabled , .primary:active:enabled{
 border: 3px solid $button-hover-border;
}
.primary:disabled {
  opacity: 0.2;
  cursor: default;
  background: $primary-bright;
}

//secondary
.secondary {
  border: 1px solid $primary-bright;
  background: $white;
  color: $primary-bright;
}
.secondary:disabled {
  opacity: 0.2;
  cursor: default;
  border: 1px solid $primary-bright;
  color: $primary-bright;
  background: white;
}

//toggle
.toggle {
  background: $primary-bright;
  color: #202020;
}
.toggle:hover,
.toggle:active,
.toggle:focus {
  background: $primary-medium;
  color: $white;
}
.toggle:disabled {
  opacity: 0.2;
  cursor: default;
  background: $primary-bright;
}

//tertiary
.text {
  background: $white;
  color: $primary-bright;
  font-weight: 600;
}
.text:hover,
.text:active,
.text:focus {
  background: $white;
  color: $button-hover;
}
.text:disabled {
  opacity: 0.2;
  cursor: default;
  color: $primary-bright;
}

//test
.testClass {
  background: red;
}
