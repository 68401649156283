@import "../../../../components/_shared/_styles/component-base";
//Slinky Menu
:global .slinky-menu {
  overflow: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  //padding-bottom: 40px;

  & > ul {
    left: 0;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
  }

  ul li {
    border-bottom: 1px solid $mobile-slinky-border;
    padding-right: 10px;
  }

  li,
  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
  }

  ul {
    width: 100%;
    padding-bottom: 80px;
  }

  li.cta {
    border-bottom: 0;
  }

  li > a {
    display: block;
    border: none;
    text-align: left;
    color: $mobile-slinky-link;
    text-decoration: none !important;
    padding: 15px 0;
    &.hasSubMenu{
      background-image: $mobile-slinky-link-icon;
      background-position: right center;
      background-repeat: no-repeat;
    }
    font-weight: 600;
    &:hover {
      text-decoration: none;
    }
  }

  li ul {
    display: none;
    left: 100%;
    position: absolute;
    top: 0;
  }

  .header {
    position: relative;
    background: $mobile-slinky-header-bg;
    border-top: 1px solid $mobile-slinky-header-border;
  }

  h2 ~ a.back {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    padding: {
      left: 30px;
      right: 30px;
    }
  }

  h2 {
    @include font-size(16px);
    font-weight: 400;
    margin: 0;
    text-align: left;
    color: $mobile-slinky-back-title;
    padding: 15px 20px 15px 30px;
  }

  li {
    line-height: 1
  }

  a.back {
    font-size: 0;
    background: none !important;
  }

  a.back:before {
    content: '';
    width: 8px;
    height: 10px;
    background-image: $mobile-slinky-back-icon;
    background-size: 6px auto;
    background-repeat: no-repeat;
    @include rotate(180deg);
    margin-top: 4px;
    margin-left: -18px;
    float: left;
  }

  li.mobile-search {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .mobile-menu-ctas {
    display: block;
    padding-top: 20px;
  }

  li.promo-wrapper {
    border-bottom: 0;
    padding-right: 0;
  }

  .promo {
    padding: 30px;
    margin-top: 30px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    p {
      color: $mobile-promo-text;
      font-weight: 700;
      @include font-size(18px);
      line-height: 1.3em;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    a.btn-menu {
      color: $mobile-slinky-text;
      @include font-size(12px);
      padding: {
        left: 1.25rem;
        right: 1.25rem;
      }
      background: rgba(255, 255, 255, 0.5);
    }
  }
}
