@import "../_shared/_styles/component-base";

:global .bio-landing {

    padding-top: 30px;
    //Tabs overwrites for Bio Landing
    .tabs [role=tabpanel] {
        margin-top: 10px;
    }

    [role=tabpanel] [class*='col-'] {
        margin-bottom: 50px;
    }

    .bio {
        background: $bio-card-bg;
        padding: 30px;
        box-shadow: 0 0 10px rgba(0,0,0, 0.15);
        -webkit-box-shadow: 0 0 10px rgba(0,0,0, 0.15);
        -moz-box-shadow: 0 0 10px rgba(0,0,0, 0.15);
        height: 100%;
        display: flex;
        flex-direction: column;
        /*
        a:hover {
            color: $link-blue-2;
            text-decoration: underline;
        }
        a{
            cursor: pointer;
        }
        */
    }

    .card-body {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        text-align: center;
        flex: 1 1 auto;
        padding: 1.25rem;
        a {
            text-decoration: none;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
        .bio-name {
            @include font-size(24px);
            line-height: 1.3em;
            color: $bio-card-name;
            font-weight: 400;
            margin-bottom: 10px;
        }
        .bio-title {
            @include font-size(20px);
            color: $bio-card-title;
            font-weight: 400;
            margin-bottom: 30px;
        }
        p {
            color: $bio-card-text;
            @include font-size(16px);
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            &:last-of-type {
                margin-bottom: 0;
            }
        }

        @media screen and (max-width: 580px) {
            .bio-name {
                @include font-size(20px);
            }
        }
    }
}
// @import '../helpers/_variables';
// @import "../helpers/mixins";
// @import "~bootstrap/scss/mixins/breakpoints";

:global .tabs {
    //Tabs List
    [role=tablist] {
        display: flex;
        justify-content: space-around;
        position: relative;
        padding-bottom: 10px;
        overflow-x: auto;
        button {
            border: 0;
            background: none;
            @include font-size(24px);
            color: $tabs-tab-text;
            padding-bottom: 20px;
            cursor: pointer;
            border-bottom: 4px solid transparent;
            position: relative;
            z-index: 2;
            white-space: nowrap;
            @include animate(0.2s);
            &[aria-selected=true] {
                font-weight: 700;
                color: $tabs-tab-text-active;
                border-bottom: 5px solid $tabs-tab-text-active-border;
            }
            &:hover {
                color: $tabs-tab-text-hover;
            }
        }
        @include media-breakpoint-down(lg) {
            justify-content: flex-start;
            button {
                @include font-size(20px);
                margin: {
                    left: 15px;
                    right: 15px;
                }
            }
        }
    }
    .divider {
        border-top: 1px solid $tabs-tab-divider-border;
        position: relative;
        top: -13px;
        height: 1px;
    }
    //Panels
    [role=tabpanel] {
        padding: 40px 0;
    }
}