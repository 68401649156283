@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@200;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

$primary-medium: #193c78;
$primary-bright: #006ef5;
$primary-dark: #061c40;
$primary-light: #cff3ff;
$primary-linkWork: #013e7d;

$secondary-dark: #d6d6d6;
$secondary-medium: #efecec;
$secondary-light: #f7f7f7;

$black: #000000;
$white: #ffffff;
$green: #008139;
$alert-red: #d62727;

$light-blue: #f4f9ff;
$gray-blue: #3c5072;
$baby-blue: #81baf9;
$footer-link: #d6e7f1;
$very-light-gray: #f9f9f9;
$mid-gray: #767676;
$dark-gray: #202020;

$font-family: 'Public Sans', sans-serif;

$card-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
$section-shadow: 0px 3px 30px rgba(0, 0, 0, 0.148082);
$card-bio-shadow: 2px 2px 4px rgba(0, 0, 0, 0.108282);
$rates-shadow: 0px 3px 22px rgba(0, 0, 0, 0.112653);

$input-grey: #767676;
$input-black: #202020;

$breadcrumbs-blue: #002c6c;
$select-blue: #67a0f9;

$button-hover: #0064DF;
$button-hover-border: #94C4FF;

$modal-orange: #cf5433;
$modal-blue: #003e7e;
$modal-divider: #3c5072;

$error-alert: #fdeded;
$warning-alert: #fff4e5;
$info-alert: #e5f6fd;
$success-alert: #edf7ed;

$warning-text: #957013;

// Breakpoints
$break--mbl: 320px;
$break--mbl-lg: 576px;
$break--tblt: 768px;
$break--tblt--lg: 950px;
$break--dsktp: 1024px;
$break--dsktp--lg: 1440px;

// Breakpoints min-width
$breakMin--mbl: 576px;
$breakMin--tblt: 768px;
$breakMin--tblt--lg: 992px;
$breakMin--dsktp: 1200px;



