@import "../_shared/_styles/component-base";

.social-content {
    position: relative;
    background: $social-page-gradient-default;
    background: linear-gradient(180deg, $social-page-gradient-top 0%, $social-page-gradient-bottom 100%);
    padding-top: 0;

    .link-list {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            li:not(:last-child){
                margin-bottom: 10px;
            }
        }
    }

    [class*='col-'] {
        margin-bottom: 30px;
    }

    .container {
        position: relative;
        margin-bottom: -20px;
    }

    .social-filters {
        padding-bottom: 20px;
        background: $social-filters-bg;
        -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.08);
        -moz-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.08);
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.08);
        padding-top: 25px;
        margin-bottom: 20px;
        overflow-x: hidden;
        position: relative;

        &:after {
            content: '';

            position: absolute;
            top: 65px;
            left: 0;
            right: 0;
            z-index: 1;

            width: auto;
            height: 1px;

            background: $social-filters-border;
        }

        [class*='col-'] {
            margin-bottom: 0px;
        }

        .scrollable-horizontal {
            overflow-y: hidden;
            overflow-x: scroll;
            //padding-bottom: 20px;
            padding-bottom: 10px;
            &::-webkit-scrollbar {
                -webkit-appearance: none;
              }
            &.scroller {
                overflow-x: hidden;
                > .link-list ul {
                    justify-content: center !important;
                }
            }

            @include media-breakpoint-down(lg) {
                &.scroller {
                    > .link-list ul {
                        justify-content: flex-start !important;
                    }
                }
            }
            @include media-breakpoint-down(md) {
                &.scroller {
                    overflow-x: scroll;
                }
            }
        }

        .link-list {
            ul {
                padding: 0 0 10px 0;
                flex-wrap: nowrap;
                justify-content: center;
                li {
                    padding: {
                        left: 50px;
                        right: 50px;
                    }
                    a.smartlink_default {
                        @include font-size(20px);
                        position: relative;
                        padding-bottom: 11px;
                        white-space: nowrap;
                        text-decoration: none;
                        color: $social-filters-link;
                        &:after {
                            content: '';
                            width: 100%;
                            height: 6px;
                            @include rounded(20px);
                            background-color: $social-filters-link-animation;
                            background-color: transparent;
                            position: absolute;
                            bottom: -6px;
                            left: 0;
                            right: 0;
                            z-index: 2;
                        }
                    }
                    &:hover {
                        a.smartlink_default {
                            color: $social-filters-link-hover;
                            &:after {
                                background-color: $social-filters-link-animation-hover;
                            }
                        }
                    }
                    &.active {
                        a.smartlink_default {
                            color: $social-filters-link-active;
                            font-weight: 700;
                            &:after {
                                background-color: $social-filters-link-animation-active;
                            }
                        }
                    }
                }
            }
        }

        /*
        @include media-breakpoint-up(lg) {
            border-bottom: none;
        }
        @include media-breakpoint-up(md) {
            ul {
                justify-content: center;
                border-bottom: 1px solid $social-filters-border;
            }
            overflow-x: hidden;
        }
        */
    }

    .load-more {
        margin-top: 20px;
    }

    .card {
        padding: 30px;
        height: 100%;
        @include rounded(0);

        &.news {
            border: 1px solid $card-news-border;
            background: $card-news-bg;
            .card-image {
                background-image: $card-news-image-placeholder;
            }
            .card-body {
                .date {
                    color: $card-news-date;
                }
                h3.card-title {
                    color: $card-news-title;
                }
                p {
                    color: $card-news-text;
                }
            }
        }

        &.blog {
            border: 1px solid $card-blog-border;
            background: $card-blog-bg;
            .card-image {
                background-image: $card-blog-image-placeholder;
            }
            .card-body {
                .date {
                    color: $card-blog-date;
                }
                h3.card-title {
                    color: $card-blog-title;
                }
                p {
                    color: $card-blog-text;
                }
            }
        }

        &.event {
            border: 1px solid $card-event-border;
            background: $card-event-bg;
            .card-image {
                background-image: $card-event-image-placeholder;
            }
            .card-body {
                position: relative;
                .date {
                    color: $card-event-date;
                }
                h3.card-title {
                    color: $card-event-title;
                }
                p {
                    color: $card-event-text;
                }
                &:before {
                    content: '';
                    width: 20px;
                    height: 24px;

                    position: absolute;
                    top: 0px;
                    right: 0px;

                    background: $card-event-icon;
                    background-repeat: no-repeat;
                    background-size: 100% auto;
                }
            }
        }

        .card-image {
            width: 100%;
            height: 170px;
            margin-bottom: 30px;
            background-position: left top;
            background-size: auto 100%;
            background-repeat: no-repeat;
        }

        .card-body {
            padding: 0;

            .date {
                @include font-size(16px);
                margin-bottom: 20px;
                padding-right: 60px;
            }

            h3.card-title {
                @include font-size(22px);
            }

            p {
                @include font-size(16px);
            }
        }

    }

    @include media-breakpoint-down(md) {
        .card {
            padding: 10px 10px 15px 10px;

            .card-image {
                margin-bottom: 20px;
            }

            .card-body {
                .date {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
.bg-white{
    background: white !important;
}

.linkPoint {
    cursor: pointer;
}

// DETAIL PAGE
.content-block {
    padding-top: 190px;
    background: $content-bg-no-gradient;
    background: $content-bg-gradient;

    @include media-breakpoint-down(lg) {
        padding-top: 100px;
    }


    //Layouts Global Settings
    .meta {
        color: $content-meta-info;
        @include font-size(18px);
        span {
            display: block;
        }
    }

    //Share Button
    .share {
        text-align: right;
    }

    .btn-share {
        @include font-size(16px);
        padding-left: 25px;
        padding-right: 25px;
    }

    .social {
        margin-top: 10px;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;

            li {
                padding-left: 5px;
                margin-bottom: 0;
                div {
                    cursor: pointer;
                }
                img {
                    width: 30px;
                }
            }
        }
    }

    .title {
        margin-bottom: 40px;
        .share {
            text-align: left;
            margin-top: 40px;
        }
        .social ul {
            justify-content: flex-start;
        }

    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
        color: $content-headers;
    }

    h5, .h5, h6, .h6 {
        color: $content-headers-secondary;
    }

    h1, .h1 {
        @include font-size(36px);
        font-weight: 400;
    }

    h2, .h2 {
        @include font-size(30px);
        margin-bottom: 15px;
    }

    h3, .h3 {
        @include font-size(24px);
        margin-bottom: 10px;
    }

    h4, .h4 {
        @include font-size(20px);
        margin-bottom: 10px;
    }

    h5, .h5 {
        @include font-size(18px);
        margin-bottom: 10px;
    }

    h6, .h6 {
        @include font-size(16px);
        font-weight: 700;
        margin-bottom: 10px;
    }

    p {
        @include font-size(16px);
        color: $content-text;
    }

    ul, ol {
        padding-left: 20px;
        @include font-size(16px);
        margin-bottom: 30px;
        li {
            line-height: 1.4em;
            margin-bottom: 8px;
        }
    }
    .sidebar ul {
        padding-left: 0;
        flex-direction: column;
    }

    a.smartlink_default {
        content: $content-link;
    }

    a.smartlink_button {
        margin-right: 15px;
        margin-bottom: 15px;
    }

    .article-content {
        padding-bottom: 20px;
    }

    .inline-video {
        width: 100%;
        margin-bottom: 40px;

        .caption {
            @include font-size(14px);
            margin-top: 8px;
        }

    }

    figure {
        figcaption {
            @include font-size(14px);
            margin-top: 8px;
        }
    }

    //Table
    table {
        table-layout: fixed;
        width: 100%;
        min-width: 760px;
        thead {
            border: 1px solid $content-table-header-borders;
            tr {
                background: $content-table-header-bg;
                th {
                    border-left: 1px solid $content-table-header-borders;
                    padding: 20px;
                    text-align: center;
                    color: $content-table-header-title;
                    @include font-size(15px);
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid $content-table-body-borders;
                background: $content-table-body-bg;
                th, td {
                    padding: 15px 10px;
                    border-left: 1px solid $content-table-body-borders;
                    @include font-size(14px);
                    &:first-child {
                        border-left: 0;
                    }
                }
            }
        }
    }

    .scrollable {
        overflow-x: auto;
        padding-bottom: 10px;
        margin-bottom: 40px;
    }

    .columns {
        display: flex;

        .column {
            padding-left: 15px;
            padding-right: 15px;
            &.col-two {
                width: 50%;
            }
        }

        @include media-breakpoint-down(lg) {
            .column {
                &.col-two {
                    width: 100%;
                }
            }
        }
    }

    //Blog - Tags
    .tags,
    .sidebar {
        margin-top: 40px;
        ul.link-list {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                :hover{
                    text-decoration: underline;
                    color: $dark;
                }
                a.smartlink_default {
                font-weight: 600;

                }
            }
        }
    }

    .sidebar h2 {
        @include font-size(22px);
        font-weight: 600;
    }

    /* Single Event */
    .single-events {
        .single-event {
            border-top: 1px solid $content-events-border;
            display: flex;
            padding-top: 40px;
            padding-bottom: 40px;
            .event-details {
                flex: 1;
                padding-right: 100px;
                .avatar {
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
            .avatar {
                width: 180px;
            }
        }

        @include media-breakpoint-down(lg) {
            .single-event {
                .event-details {
                    padding-right: 0;
                }
            }
        }
    }

    //Content Layouts
    &-article {
        .title-event {
            h1 {
                margin-bottom: 20px;
            }
        }
    }

    &-blog {
        .title-blog {
            h1 {
                margin-bottom: 20px;
            }
        }
    }

    &.simplified {
        h1 {
            margin-bottom: 40px;
        }
        img {
            margin-bottom: 30px;
        }
    }

    //Page Layouts
    &.blog {
        .share {
            text-align: left;
        }
    }

    &.generic {
        .share {
            text-align: left;
        }
    }
}
