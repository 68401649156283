@import "../_shared/_styles/component-base";

.accordion {
	background-color: $accordion-bg;
	border-top: 1px solid $accordion-border;

	ul {
		margin: 0 0 30px 0;
		padding: 0;
		list-style: none;
		li {
			width: 100%;
		}
	}

	.accordion_title {
		border-bottom: 1px solid $accordion-border;
		padding: 25px 40px 25px 10px;
		cursor: pointer;
		position: relative;

        color: $accordion-title;
        font-weight: 400;
        font-size: 18px;

		&:after {
			content: '';
			width: 25px;
			height: 25px;
			position: absolute;
			right: 0px;
			top: 50%;
            transform: translateY(-50%);
			background: $accordion-icon;
			background-size: 14px 16px;
		}

		&.is_expanded {
            border-bottom: 1px solid transparent;
			&:after {
				background: $accordion-icon-active;
				background-size: 14px 16px;

			}
		}

	}

	.accordion_panel {
		border-bottom: 1px solid $accordion-border;
		padding: 20px 10px 10px 10px;
		display: none;
		color: $accordion-text;

        @include font-size(16px);
        font-weight: 300;
		b{
			font-weight: 600;
		}
		p {
			margin: 0 0 20px 0;
			padding: 0;

		}
		a {
			color: $hero-gradient-link;
		}
		ul{
			list-style: disc;
		}
		li{
			margin-left: 17px;
		}
	}
}

.hero-accordions .accordion {
	margin-top: 30px;
	margin-bottom: 30px;
}
