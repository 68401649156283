@import "../_shared/_styles/component-base";

.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: {
        top: 30px;
        bottom: 20px;
    }
    z-index: 500;
    background: $cookie-banner-bg;

    @include box-shadow(0px, -4px, 10px, rgba(0, 0, 0, 0.05));

    @include font-size(14px);
    color: $cookie-banner-text;

    .container {
        position: relative;
    }

    h6 {
        margin-bottom: 10px;
        @include font-size(24px);
    }

    a {
        font-size: 14px;
        &:hover{
            text-decoration: underline;
        }

    }
    .manage-preferences{
        color: $btn-secondary-text;
        &:hover{
            color: $btn-secondary-text;
        }
    }
    p {
        @include font-size(14px);
    }
}