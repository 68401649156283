@import "../_shared/_styles/component-base";

.feature {

    .feature-container {
        background: $feature-bg;
        border-radius: 0.35rem;
        padding: 60px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.10);
        -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.10);
        -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.10);
    }

    @include media-breakpoint-down(md) {
        .feature-container {
            padding: 30px;
        }
    }

    h3 {
        font-weight: 600;
    }

    .feature-rates {
        margin-bottom: 40px;
        h3 {
            margin-bottom: 15px;
            @include font-size(24px);
            color: $feature-title-highlight;
        }
        ul.rates {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            li {
                @include font-size(40px);
                font-weight: 600;
                color: $feature-rates-highlight;
                position: relative;
                padding-right: 20px;
                margin-right: 15px;
                &:after {
                    content: '';
                    width: 5px;
                    height: 80%;
                    background: $feature-rates-divider;

                    @include rounded(20px);

                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;

                    margin: auto;
                }

                &:last-child {
                    padding-right: 0;
                    margin-right: 0;
                    &:after {
                        display: none;
                    }
                }
            }
        }

        @include media-breakpoint-down(xl) {
            h3 {
                @include font-size(20px);
            }
            ul.rates {
                li {
                    @include font-size(22px);
                }
            }
        }

        @include media-breakpoint-down(sm) {
            h3 {
                @include font-size(20px);
            }
            ul.rates {
                li {
                    @include font-size(18px);
                    &:after {
                        width: 3px;
                    }
                }
            }
        }
    }
    .feature-content {
        margin-bottom: 40px;
        h2 {
            @include font-size(28px);
            color: $feature-content-title;
        }

        h3 {
            @include font-size(24px);
            margin-bottom: 20px;
            color: $feature-content-title;
        }

        h4 {
            font-weight: 600;
            @include font-size(20px);
            margin-bottom: 20px;
            color: $feature-content-title;
        }

        p {
            @include font-size(16px);
            color: $feature-content-text;
        }

        a.smartlink_default {
            color: $feature-content-link;
        }

        ul {
            padding-left: 30px;
            li {
                margin-bottom: 8px;
                color: $feature-content-text;
            }

            &.link-list {
                list-style: none;
                padding-left: 0px;
            }
        }

        .link-list {
            ul {
                padding-left: 0;
            }
        }

        @include media-breakpoint-down(lg) {
            h3 {
                @include font-size(20px);
            }
        }
    }

    table.rates {
        background: $feature-rates-table;
        thead th {
            color: $feature-rates-table-headers;
        }
        tbody th {
            color: $feature-rates-table-body-headers;
        }
        tbody td {
            color: $feature-rates-table-body-text;
        }
    }

    //Dividing line
    .div {
        position: absolute;
        width: 100px;
        height: 75%;
        &:after {
            content: '';
            width: 1px;
            height: 100%;
            background: $feature-panel-divider;
            margin: auto;

            position: absolute;
            top: 100px;
            right: 0;
            bottom: 100px;
            left: 0;
        }
    }
    hr{
        background: $feature-panel-divider;
        width: 1px;
        opacity: 1;
        margin: 0 auto;
    }
}