@import "../../_shared/_styles/component-base";
.product-panel-container{
    @include rounded(10px 10px 0 0);
    background: $carousel-large-card-gradient-default;
    background: -webkit-linear-gradient(bottom, $carousel-large-panel-gradient-bottom 0%, $carousel-large-panel-gradient-top 100%);
    background: -o-linear-gradient(bottom, $carousel-large-panel-gradient-bottom 0%, $carousel-large-panel-gradient-top 100%);
    background: linear-gradient(to top, $carousel-large-panel-gradient-bottom 0%, $carousel-large-panel-gradient-top 100%);

    .product-panel-content {
        padding: 50px 75px;

        .eyebrow {
            color: $carousel-large-panel-eyebrow;
        }

        h3 {
            color: $carousel-large-panel-title;
            @include font-size(36px);
            font-weight: 600;
            margin-bottom: 20px;
        }

        :global .col-wrap {
            margin-top: 40px;
            display: flex;
            flex-direction: row;
        }
        :global .col {
            padding-right: 40px;
            padding-left: 15px;
            margin: {
                bottom: 20px;
            }

            .icon {
                width: 48px;
                height: 48px;
                margin-bottom: 30px;
            }

            p {
                @include font-size(18px);
                color: $carousel-large-panel-text;
            }
        }

        :global .link-list {
            margin: {
                top: 20px;
                bottom: 20px;
            }
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                justify-content: center;
                li {
                    padding: 0 15px;
                    position: relative;
                    text-align: center;
                    display: inline-flex;
                    align-items: center;
                    &:after {
                        content: '|';
                        position: absolute;
                        right: -2px;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: auto 0;
                        color: $carousel-large-panel-link;
                        @include font-size(16px);
                    }
                    a {
                        color: $carousel-large-panel-link;
                        @include font-size(16px);
                    }
                    &:last-child:after {
                        display: none;
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            :global .col {
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    li {
                        @include font-size(18px);
                        color: $carousel-large-panel-text;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            padding: 35px 12px;

            h3 {
                @include font-size(26px);
            }

            :global .col-wrap {
                margin-top: 40px;
                display: block;
            }

            :global .col {
                padding-right: 40px;
                margin: {
                    bottom: 20px;
                }

                ul {
                    padding-left: 0px;
                    li {
                        @include font-size(18px);
                        color: $carousel-large-panel-text;
                    }
                }
            }

            :global .link-list {
                ul {
                    flex-direction: column !important;
                    padding-left: 0;

                    li {
                        @include font-size(18px);
                        color: $carousel-large-panel-text;
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &:after {
                            display: none;
                        }
                    }
                }
            }

        }
    }
}
