.center {
    text-align: center;
}
.pad-above {
    padding-top: 0;
}
.pad-below {
    padding-bottom: 0;
}
.no-border {
    border: none;
}

.calc-layout {
    padding-top: 40px;
    padding-bottom: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.calc-intro {
    margin-bottom: 30px;
}