@import "../../../components/_shared/_styles/component-base";

.iframe-container {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    padding-top: 56.25%;
  }
  
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
.caption {
  @include font-size(14px);
  margin-top: 8px;
}
.inline-video {
  width: 100%;
  margin-bottom: 40px;
}