@import "../../_shared/_styles/component-base";

.root {
  display: flex;
  flex-direction: row;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }

  cite {
    font-style: normal;
    color: $carousel-testimonial-cite;
    @include font-size(16px);
    font-weight: 500;
  }

  @include media-breakpoint-down(md){
    p {
      @include font-size(20px);
      line-height: 1.4em;
    }

    cite span {
      display: block;
    }
  }
}
