@import "../_shared/_styles/component-base";

:global .tall-tabs {
    :global .intro {
        h2 {
            color: $talltabs-intro-title;
        }

        p {
            color: $talltabs-intro-text;
        }
    }

    .tabcordions {
        //Tabs
        [role=tablist] {
            display: flex;

            button {
                text-align: center;
                background: none;
                border: 0;
                padding: 25px 15px;
                position: relative;
                flex: 1;

                .tab-icon {
                    width: 80px;
                    height: 60px;
                    margin: 0 auto 20px auto;
                    .icon-on {
                        display: none;
                    }
                    .icon-off {
                        display: block;
                    }
                }
                .tab-title {
                    font-weight: 700;
                    color: $talltabs-tab-title;
                    @include font-size(20px);
                    margin-bottom: 10px;
                }

                .tab-desc {
                    @include font-size(14px);
                    color: $talltabs-tab-text;
                }

                &[aria-selected="true"] {
                    background: $talltabs-tab-active-bg;
                    /*
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: -29px;
                        left: 0;
                        height: 6px;
                        width: 100%;
                        @include rounded(6px);
                        background: $talltabs-tab-border;
                    }
                    */
                    .tab-icon {
                        .icon-on {
                            display: block;
                        }
                        .icon-off {
                            display: none;
                        }
                    }
                    .tab-title {
                        color: $talltabs-tab-title-active;
                    }
                    .tab-desc {
                        color: $talltabs-tab-text-active;
                    }
                }
            }
            @include media-breakpoint-down(xl) {
                display: none;
            }
        }
        //Tab Panels
        .tab-panels {
            background-color: $talltabs-tabcontent-panel-bg;
            background-image: $talltabs-tabcontent-panel-bg-image;
            background-repeat: no-repeat;
            background-position: left center;
            background-size: auto 200%;

            .intro {
                h2 {
                    color: $talltabs-tabcontent-intro-title;
                }

                p {
                    color: $talltabs-tabcontent-intro-text;
                }
            }
            @include media-breakpoint-down(lg) {
                background-image: none;
            }
        }

        .tab-panel-content {
            padding-top: 60px;
            padding-bottom: 60px;

            h1,h2,h3 {
                color: $talltabs-tabcontent-headline;
                @include font-size(22px);
                margin-bottom: 15px;
                font-weight: 700;

                a:not(.smartlink_button) {
                    color: $talltabs-tabcontent-link;
                }
            }

            h4,h5,h6 {
                color: $talltabs-tabcontent-headline;
                @include font-size(18px);
                font-weight: 700;
                margin-bottom: 15px;
            }

            p {
                color: $talltabs-tabcontent-text;
            }

            /*ul {
                list-style: none;
                padding: 0;
                margin: 0 0 20px 0;
                li {
                    padding-left: 1em;
                    position: relative;
                    margin-bottom: 8px;
                    p:last-of-type {
                        margin-bottom: 0;
                    }
                    &:before {
                        content: "• ";
                        color: $talltabs-tabcontent-intro-text;
                        @include font-size(20px);
                        position: absolute;
                        left: 0;
                        top: -3px;
                    }
                }
            }*/

            :global a.smartlink_button {
                margin-bottom: 20px;
                margin-right: 10px;
            }

            :global a:not(.smartlink_button) {
                color: $talltabs-tabcontent-link;
            }

            .link-list {
                ul {
                    margin: 0 0 40px 0;
                    flex-direction: column;

                    li {
                        margin-bottom: 5px;

                        :global a.smartlink_default {
                            color: $talltabs-tabcontent-link;
                            font-weight: 700;
                            @include font-size(16px);
                        }
                    }
                }
            }
            .tab-panel-content-inner{
                a{
                    text-decoration: none;
                }
                a:hover{
                    text-decoration: underline;
                }
                ul{ // react specific, overrides rich text styling
                    list-style: outside;
                    color:  $talltabs-tabcontent-text;
                    padding-left: 20px;
                }
            }
            .right-side {
                padding-top: 40px;
            }

            hr {
                margin: 0 auto;
                background: $talltabs-tabcontent-hr;
                width: 1px;
                opacity: 1.0;
            }

            &-rates {
                padding-top: 40px;

                table.rates-table {
                    width: 100%;

                    thead tr {
                        th {
                            color: $talltabs-rates-table-header-th;
                            font-weight: 300;
                            @include font-size(10px);

                            &:last-of-type {
                                text-align: right;
                            }
                        }
                    }

                    tbody {
                        tr {
                            border-bottom: 1px solid $talltabs-rates-table-border;

                            &:first-of-type {
                                border-top: 1px solid $talltabs-rates-table-border;
                            }
                        }

                        th, td {
                            padding: 5px 0;
                            @include font-size(20px);
                        }

                        th {
                            color: $talltabs-rates-table-body-th;
                            padding-right: 10px;
                            font-weight: 400;
                        }

                        td {
                            color: $talltabs-rates-table-body-text;
                            font-weight: 700;
                            text-align: right;
                        }

                        :global a.smartlink_default {
                            color: $talltabs-rates-table-link;
                        }
                    }
                }
            }

            .horizontal-list-links {
                border-top: 1px solid $talltabs-link-list-hr;
                padding-top: 60px;

                h3 {
                    margin-bottom: 25px;
                }

                .link-list {
                    ul {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;

                        li {
                            width: 33%;
                            /*
                                        padding-left: 0;
                                        &:before {
                                            display: none;
                                        }
                            */
                            a:not(.smartlink_button) {
                                color: $talltabs-link-list-link;
                            }
                        }
                    }
                }
            }
            @include media-breakpoint-down(lg) {
                .column-list-links {
                    &:last-of-type {
                        ul {
                            margin-bottom: 0;
                        }
                    }

                    h3, ul {
                        text-align: center;
                    }
                }

                .horizontal-list-links {
                    ul li {
                        width: 100%;
                    }
                }
            }
        }

        //Accordions
        .accordion-desc {
            background: $talltabs-accordion-desc-bg;
            padding: 40px 0 20px 0;
            color: $talltabs-accordion-desc-text;
            text-align: center;
            display: none;
        }

        .accordion-button {
            border: 0;
            width: 100%;
            padding: 15px 40px 15px 15px;
            display: none;
            text-align: left;
            @include font-size(18px);
            font-weight: 600;
            color: $talltabs-accordion-btn-text;
            background: $talltabs-accordion-btn-bg;
            position: relative;
            border-bottom: 1px solid $talltabs-accordion-btn-border;

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 15px;
                width: 16px;
                height: 16px;
                background: $talltabs-accordion-btn-icon;
                background-size: 12px;
                background-repeat: no-repeat;
                background-position: center;
            }

            div:nth-child(1) {
                width: 35px;
            }

            div:nth-child(2) {
                flex: 1;
                padding-left: 15px;
            }

            img {
                max-width: 35px;
                height: auto;
            }

            &[aria-selected=true] {
                color: $talltabs-accordion-btn-text-active;

                &:after {
                    background: $talltabs-accordion-btn-icon-active;
                    background-size: 12px;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }
        @include media-breakpoint-down(xl) {
            .accordion-button,
            .accordion-desc {
                display: flex;
                align-items: center;
            }
        }
    }
}
