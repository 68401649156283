@import "../../../components/_shared/_styles/component-base";

.smartlink_default {
    color: $text-color;
    text-decoration: none;
    &:hover {
        color: $text-color-hover;
        text-decoration: underline;
    }
}

.smartlink_button {
  display: inline-block;
  border-radius: 2rem;
  font-family: $btn-font-family;
  padding: {
    top: 0.375rem;
    bottom: 0.375rem;
    left: 2.75rem;
    right: 2.75rem;
  }
  @include font-size(20px);
  font-weight: 600;
  text-decoration: none;
  @include animate(0.2s);
}

.smartlink_buttonprimary {
    background: $btn-primary-bg;
    border: 2px solid $btn-primary-border;
    color: $btn-primary-text;
  &:hover {
    background: $btn-primary-bg-hover;
    border: 2px solid $btn-primary-text-hover;
    color: $btn-primary-text-hover;
  }
}

.smartlink_buttonsecondary {
    background: $btn-secondary-bg;
    border: 2px solid $btn-secondary-border;
    color: $btn-secondary-text !important;
    &:hover {
        background: $btn-secondary-bg-hover;
        border: 2px solid $btn-secondary-border-hover;
        color: $btn-secondary-text-hover !important;
    }
}

.smartlink_buttontransparent {
    color: $btn-transparent-text;
    background: $btn-transparent-bg;
    border: 2px solid $btn-transparent-border;
    &:hover {
        background: $btn-transparent-bg-hover;
        border: 2px solid $btn-transparent-border-hover;
        color: $btn-transparent-text-hover;
    }
}

.smartlink_buttonmenu {
    background: $btn-menu-bg;
    @include box-shadow(0px, 0px, 20px, rgba(0, 0, 0, 0.1));
    font-family: $font-family-sans-serif;
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
    &:hover {
        background: $btn-menu-bg-hover !important;
        color: $btn-menu-text-hover !important;
    }
}

.smartlink {
  @include media-breakpoint-down(md) {
    &_button {
      padding: {
        left: 1.25em;
        right: 1.25em;
      }
    }
  }
}

.smartlink_relativeurl {

}

.smartlink_anchorlink {

}

.smartlink_fullurl {

}

.smartlink_modallink {

}

.smartlink_email {

}

.smartlink_phone {


}

.smartlink_unspecified {

}

.smartlink_scripted {

}
