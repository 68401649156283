@import "../_shared/_styles/component-base";

.content-block {
    padding: 40px 0 0 0;
    background: $white !important;
    :global .inline-video{
        width: 66%;
    }
    p{
        width: 66%;
    }
    th{
        background: $light
    }


    @include media-breakpoint-down(lg) {
        padding-left: 0;
        padding-right: 0;
        :global .inline-video{
            width: 100%;
        }
        p{
            width:100%;
        }
    }
}
