@import "../_shared/_styles/component-base";

.sidebar {
    //margin-top: 40px;
    ul.link-list {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            :hover{
                text-decoration: underline;
                color: $dark;
            }
            a.smartlink_default {
            font-weight: 600;

            }
        }
    }
}

.sidebar h2 {
    @include font-size(22px);
    font-weight: 600;
}

.sidebar ul {
    padding-left: 0;
    flex-direction: column;
}