@import "../_shared/_styles/component-base";

.table-compare {
    &-nobg {
        background: $table-compare-no-bg !important;
    }

    //Component Intro
    .intro {
        h2 {
            color: $table-compare-intro-title;
        }
        p {
            color: $table-compare-intro-text;
        }
    }

    //Desktop Table
    table.compare {
        color: $table-compare-table-color;
        width: 100%;
        table-layout: fixed !important;

        :global a:not(.blue) {
            color: $table-compare-table-link;
        }

        thead {
            th {
                background: $table-compare-table-header-bg;
                @include font-size(20px);
                color: $table-compare-table-header-text;
                text-align: center;
                padding: 15px 10px;
                border-bottom: 1px solid $table-compare-table-header-borders;
                border-top: 1px solid $table-compare-table-header-borders;
                border-left: 1px solid $table-compare-table-header-borders;
                &:first-of-type {
                    border-left: 0;
                    border-top: 0;
                    background: none;
                }
            }
        }

        tbody {
            @include font-size(18px);

            p {
                @include font-size(16px);
            }

            th, td {
                padding: 20px 10px;
                border-bottom: 1px solid $table-compare-table-body-borders;
                border-top: 1px solid $table-compare-table-body-borders;
                border-left: 1px solid $table-compare-table-body-borders;
            }

            th {
                &:first-of-type {
                    border-left: 0;
                }
            }

            tr.expand-rows {
                td {
                    padding: 0;
                    border: 0;
                    &[colspan] {
                        border-bottom: 1px solid$table-compare-table-body-borders;
                    }
                }
                a.expand-rows {
                    display: block;
                    width: 100%;
                    height: 100%;
                    padding: 10px 40px 10px 20px;
                    background: $table-compare-table-expand-row;
                    text-align: center;
                    @include font-size(16px);
                    color: $table-compare-table-expand-text;
                    font-weight: 600;

                    position: relative;

                    &:after {
                        content: '';
                        width: 12px;
                        height: 10px;

                        position: relative;
                        display: inline-block;
                        margin-left: 5px;

                        background: $table-compare-table-expand-icon;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100% auto;

                        @include animate(0.3s);
                        transform-origin: center;
                    }
                }

                &.expanded {
                    a {
                        &:after {
                            @include rotate(180deg);
                        }
                    }
                }
            }

        }

        tfoot {
            text-align: center;
            td {
                padding: 20px;
                border-bottom: 1px solid $table-compare-table-footer-borders;
                border-left: 1px solid $table-compare-table-footer-borders;
                &:first-of-type {
                    border-left: 0;
                    border-bottom: 0;
                }
                &:nth-child(2) {
                    border-left: 0;
                }
                :global a,
                :global a.smartlink_default {
                    @include font-size(18px);
                    font-weight: 700;
                    color: $table-compare-table-footer-link;
                }
            }
        }

    }

    @include media-breakpoint-down(lg) {
        background-image: none !important;
    }

    //Mobile View Quick Links
    ul.quick-links {
        margin: 0;
        padding: 0;
        list-style: none;
        border-top: 1px solid $table-compare-quicklinks-border;

        li {
            text-align: center;
            border-bottom: 1px solid $table-compare-quicklinks-border;
        }

        .title {
            font-weight: 600;
            @include font-size(20px);
            margin-bottom: 5px;
            color: $table-compare-quicklinks-footer-title;
        }

        :global a.smartlink_default {
            display:  block;
            width: 100%;
            padding: {
                top: 15px;
                bottom: 15px;
                left: 15px;
                right: 15px;
            }
            text-decoration: none;
            color: $table-compare-quicklinks-footer-link;
            &:hover {
                div {
                    text-decoration: underline;
                }
            }
        }

        p {
            color: $table-compare-quicklinks-footer-text;
            @include font-size(14px);
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .container {
            width: 100%;
            max-width: 100%;
        }
    }

    //Mobile View Compare Cards
    table.compare-card {
        background: none;
        @include rounded(0);
        color: $table-compare-mobile-table-text;
        width: 100%;
        margin-bottom: 40px;

        &:last-of-type {
            margin-bottom: 0;
        }

        :global a:not(.blue) {
            color: $table-compare-mobile-table-link;
        }

        thead tr th {
            width: 100%;
            background: $table-compare-mobile-table-header-bg;
            color: $table-compare-mobile-table-header-text;
            @include font-size(20px);
            font-weight: 700;
            text-align:  left;
            border: 1px solid $table-compare-mobile-table-header-border;

            padding: 10px 15px;
        }

        tbody {
            border-top: 1px solid $table-compare-mobile-table-body-borders;
            border-left: 1px solid $table-compare-mobile-table-body-borders;
            border-right: 1px solid $table-compare-mobile-table-body-borders;

            tr {
                border-bottom: 1px solid $table-compare-mobile-table-body-borders;
                &:last-of-type {
                    border-bottom: 0;
                }

                th[scope=row] {
                    width: 40%;
                }

                th, td {
                    text-align: left;
                    padding: 10px 15px;
                }

                &.desc td {
                    text-align: left;
                    padding: 20px 15px;
                }

                p:last-of-type {
                    margin-bottom: 0;
                }

                &.expand-rows-mobile {
                    a.expand-rows {
                        display: block;
                        width: 100%;
                        height: 100%;
                        padding: 10px 40px 10px 20px;
                        text-align: center;
                        @include font-size(16px);
                        color: $table-compare-mobile-table-footer-expander;
                        font-weight: 600;

                        position: relative;

                        &:after {
                            content: '';
                            width: 12px;
                            height: 10px;

                            position: relative;
                            display: inline-block;
                            margin-left: 5px;

                            background: $table-compare-mobile-table-footer-expander-icon;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 100% auto;

                            @include animate(0.3s);
                            transform-origin: center;
                        }
                    }

                    &.expanded {
                        a.expand-rows {
                            &:after {
                                @include rotate(180deg);
                            }
                        }
                    }
                }
            }
        }

        tfoot {
            border-bottom: 1px solid $table-compare-mobile-table-footer-expander-borders;
            border-left: 1px solid $table-compare-mobile-table-footer-expander-borders;
            border-right: 1px solid $table-compare-mobile-table-footer-expander-borders;

            td {
                text-align: center;
                padding: 20px 15px;

                :global a,
                :global a.smartlink_default {
                    @include font-size(18px);
                    font-weight: 700;
                    color: $table-compare-table-footer-link;
                }
            }
        }


    }

}
