@import "../_shared/_styles/component-base";

.c-category {
  position: relative;
  @include background();
  background-color: $category-layout-bg-color;
  &.no-bg {
    background: none !important;
  }
  :global .row {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
  }

  @include media-breakpoint-up(lg) {

    &:not(.c-category-rates-collapsable, .c-category-rates-small) .column {
      flex: 1;
    }

    :global .column {
      width: 33%;
      margin-bottom: 40px;
    }

  }

  @include media-breakpoint-down(lg) {
    :global .column {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  :global .intro {
    h2 {
      color: $category-intro-title;
    }
    p {
      color: $category-intro-text;
    }

    a:not(.smartlink_button) {
      color: $category-intro-link;
    }
  }

  &.card-large {
    .card {
      .card-image {
        margin-bottom: 25px;
        max-height: 153px;
        overflow: hidden;
        img {
          width: auto !important;
          height: auto !important;
        }
      }
    }
  }

  &.card-small {
    .card {
      .card-image {
        margin-bottom: 25px;
        height: 100%;
        max-height: 50px;
        img {
          max-height: 100%;
        }
      }
    }
  }

  .card {
    padding: 50px 35px;
    height: 100%;
    -webkit-box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    background: $category-bg;
    border: 0;
    /*Commenting out... not in new styles, unclear if is dev fix or old style*/
    :global .icon {
      height: 100%;
      max-height: 50px;
      margin-bottom: 25px;
      img {
        max-height: 100%;
      }
    }
    .card-body {
      padding: 0;
      h3.card-title {
        @include font-size(26px);
        margin-bottom: 20px;
        font-weight: 700;
        color: $category-title;
      }
      a {
        color: $category-link;
        &:hover {
          color: $category-link-hover;
        }
      }
      p {
        @include font-size(18px);
        color: $category-text;
      }
    }

    //card.rate
    &.rates {
      background: $category-rate-bg;
      .card-title {
        font-weight: 700;
        color: $category-rate-title;
        a {
          color: $category-rate-title;
        }
      }
      table {
        border-collapse: collapse;
        width: 100%;
        td {
          padding: 0;
        }
      }

      table.inner {
        th, td {
          display: block;
          text-align: center;
          padding-left: 10px;
          padding-right: 10px;
        }

        th {
          @include font-size(16px);
          font-weight: 400;
          color: $category-rate-table-title;
          border-bottom: 1px solid $category-rate-table-border;
          padding-bottom: 10px;
        }

        td {
          padding-top: 10px;

          span {
            @include font-size(12px);
            display: block;
            color: $category-rate-table-text-small;
          }

          a {
            @include font-size(30px);
            color: $category-rate-table-link;
          }
        }
      }

      @include media-breakpoint-down(xl) {
        .card-image {
          h3.card-title {
            margin-bottom: 30px;
          }
        }
        table.inner {
          th, td {
            text-align: left;
            padding-left: 0;
            padding-right: 20px;
          }
        }
      }

      @include media-breakpoint-down(lg) {
        table {
          tr {
            display: flex;
            flex-direction: column;
          }
        }

        table.inner tbody tr {
          display: flex;
          flex-direction: row;
          align-items: center;

          border-top: 1px solid $category-rate-table-border;

          th {
            border-bottom: 0;
            padding-bottom: 0;
          }

          th, td {
            flex: 1;
          }
        }
      }

      @include media-breakpoint-up(xl) {
        display: flex;
        flex-direction: row;
        align-items: center;
        .card-image {
          width: 20%;
        }
        .card-body {
          width: 80%;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    :global [class*='col-'],
    :global .column {
      padding: {
        left: 2px;
        right: 2px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    :global [class*='col-'] {
      margin-bottom: 20px;
    }
    .card {
      padding: 50px 35px;
      .card-body {
        h3.card-title {
          @include font-size(25px);
          margin-bottom: 20px;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .card {
      padding: 25px 15px;
    }
  }

  //Category.Filled
  &-filled {

    :global &.light-text {
      .intro {
        h2 {
          color: $category-filled-intro-title;
        }
        p {
          color: $category-filled-intro-text;
        }
      }
    }

    :global .row.filled {
      background: $category-filled-bg;
      border-radius: 0.25rem;
      padding-left: 0;
      padding-right: 0;
      -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    }

    .card {
      border: none;
      background: none;
      padding: {
        left: 40px;
        right: 40px;
      }
      -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
      -moz-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);

      .card-image {
        margin-bottom: 25px;
        height: 100%;
        max-height: 50px;
        text-align: center;
        img {
          max-height: 100%;
        }
      }

      .card-body {
        @include font-size(16px);
        text-align: center;
        h3.card-title {
          @include font-size(20px);
          color: $catefory-filled-title;
        }
        p {
          @include font-size(16px);
          color: $catefory-filled-text;
        }
      }
    }

    @include media-breakpoint-up(md) {
      :global [class*='col-'],
      :global .column {
        padding: {
          left: 0;
          right: 0;
        }
        flex: 1;
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-down(lg) {
      :global .row {
        padding-top: 20px;
        padding-bottom: 20px;
      }
      :global .column {
        margin-bottom: 0;
        padding: {
          left: 15px;
          right: 15px;
        }
      }

      .card {
        padding: {
          top: 20px;
          bottom: 20px;
          left: 0;
          right: 0;
        }
        .card-body {
          h3.card-title {
            margin-bottom: 10px;
            @include font-size(18px);
          }
        }
      }
    }
  }
}
