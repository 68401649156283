@import '../../../styles/index.scss';

.formInput {
  gap: 5px;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;

  input,
  textarea {
    z-index: 1;
    height: 48px;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    background: none;
    line-height: 16px;
    padding: 5px 15px;
    border-radius: 3px;
    position: relative;
    letter-spacing: 0.14px;
    box-sizing: border-box;
    font-family: $font-family;
    box-shadow: none !important;
    border: 2px solid #cccccc;

    ::placeholder {
      color: #999999 !important;
    }
  }
  textarea {
    resize: none;
    height: auto;
    padding: 13.5px 15px;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  label {
    top: 5px;
    margin: 0;
    left: 15px;
    opacity: 0;
    z-index: 0;
    margin: auto;
    padding: 0 5px;
    font-size: 12px;
    font-weight: 600;
    color: #707070;
    text-align: left;
    line-height: 14px;
    position: absolute;
    background: white;
    letter-spacing: 0.22px;
    font-family: $font-family;
    transform: translateY(50%);
    transition: all 0.2s ease-in;
  }

  .labelFocused {
    transform: translateY(-50%);
    top: 0;
    z-index: 2;
    opacity: 1;
  }

  .prefixSufix {
    height: 10px;
    display: flex;
    padding: 15px;
    min-width: 20px;
    font-weight: 200;
    align-items: center;
    color: $input-black;
    justify-content: center;
    background: $secondary-dark;
  }

  .prefix {
    @include leftRadius;
  }

  .suffix {
    @include rightRadius;
  }
  .danger-text  {
    color: red;
    font-weight: bold;
  }
  
}