@import "../_shared/_styles/component-base";

.c-hero {
    @include background();
    position: relative;
    z-index: 1;

    :global .container,
    :global .row {
        position: relative;
        z-index: 5;
    }

    @include media-breakpoint-up(lg) {
        :global .row {
            padding-top: 160px;
            padding-bottom: 100px;
            min-height: 700px;
        }
    }

    //Defaults
    h1 {
        @include font-size(54px);
        font-weight: 400;
        color: $hero-light-title;
        margin-bottom: 10px;
    }

    p {
        color: $hero-light-text;
        @include font-size(20px);
    }

    :global a.smartlink_button {
        margin-top: 10px;

        &:not(:last-of-type) {
            margin-right: 15px !important;
        }
    }
    //Light
    &.light {
        //Gradient
        &:before {
            content: '';
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: $hero-light-no-gradient;
            background: $hero-light-gradient;
        }
        //Watermark
        &:after {
            content: '';
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: 100%;
            background: $hero-light-watermark;
            background-repeat: no-repeat;
            background-size: 40%;
            @include opacity(0.9);
        }
    }
    //Dark
    &.dark {
        //Gradient
        &:before {
            content: '';
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: $hero-dark-no-gradient;
            background: $hero-dark-gradient;
        }
        //Watermark
        &:after {
            content: '';
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: 100%;
            background: $hero-dark-watermark;
            background-repeat: no-repeat;
            background-size: 40%;
            @include opacity(0.9);
        }

        h1, h2 {
            color: $hero-dark-title;
        }

        p {
            color: $hero-dark-text;
        }
    }

    //Small Hero
    &-small {
        @include media-breakpoint-up(lg) {
            :global .row {
                padding-top: 160px;
                padding-bottom: 100px;
                min-height: 550px;
            }
        }
        h1 {
            @include font-size(46px);
        }
    }

    //Gradient Hero
    &-gradient {
        background: $hero-gradient-default;
        background: $hero-gradient;
        height: unset;

        &:before {
            display: none;
        }

        &:after {
            content: '';
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            right: unset;
            bottom: unset;

            width: 100%;
            height: 100%;

            background: $hero-gradient-watermark !important;
            background-repeat: no-repeat !important;
            background-size: 50%;
            opacity: unset;
        }

        @include media-breakpoint-up(lg) {
            :global .row {
                padding-top: 200px;
                padding-bottom: 60px;
                min-height: unset;
            }
        }

        :global .hero-content {
            padding-right: 60px;
            padding-bottom: 0;
            a {
                color: $hero-gradient-link;
            }
            h2{
                margin-bottom: 20px;
                font-size: 2.25rem;
                font-weight: 400;
                letter-spacing: -1px;
                color: $hero-gradient-title;
            }
            b {
                font-weight: 600;
            }
        }

        h1 {
            @include font-size(46px);
            color: $hero-gradient-title;
            margin-bottom: 40px;
        }

        p {
            @include font-size(18px);
            font-weight: 300;
            color: $hero-gradient-text;
            strong {
                font-weight: 600;
            }
        }

        a.smartlink_default {
            color: $hero-gradient-link;
        }
    }
    /* 404 Page Hero */
    &-404 {
        background: $hero-404-bg;
        position: relative;

        &:before {
            display: none;
        }

        &:after {
            content: '';
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            background-size: 100%;
            background: $hero-404-watermark !important;
            background-repeat: no-repeat !important;
            background-position: left top !important;
        }

        :global .row {
            height: unset;
            padding-top: 160px;
            padding-bottom: 100px;
        }

        h1, h2 {
            @include font-size(46px);
            color: $hero-404-title;
            margin-bottom: 40px;
        }

        p {
            @include font-size(18px);
            font-weight: 300;
            color: $hero-404-text;
            margin-bottom: 0;
            strong, b, a {
                font-weight: 600;
            }
        }

        :global a,
        :global a.smartlink_default {
            color: $hero-404-link;
        }
    }
    //Align Hero Center
    &.align-center {
        :global .row {
            text-align: center !important;
        }

        :global .hero-content {
            padding-right: 0;
            a,
            h1,
            p {
                text-align: center;
            }
        }
    }
    //Search Hero
    &-search {
        height: unset;
        min-height: unset !important;
    }

    /* Hero Sub-Components : Accordions */
    .hero-accordions {
        @include rounded(20px);
        padding: 45px 50px;
        margin: 0;
        background: $hero-accordion-bg;
        border: $hero-accordion-border;

        h2 {
            color: $hero-accordion-main-title;
            @include font-size(24px);
        }

        p {
            color: $hero-accordion-text;
        }

        .accordion {
            border-top: $hero-accordion-borders;
            margin-bottom: 0;

            ul {
                margin: 0;
            }

            .accordion_panel,
            .accordion_title {
                color: $hero-accordion-title;
                border-bottom: $hero-accordion-borders;
            }

            .accordion_title {
                @include font-size(16px);
                padding: {
                    top: 15px;
                    bottom: 15px;
                }

                &:after {
                    content: '';
                    width: 25px;
                    height: 25px;
                    background: $hero-accordion-icon;
                    background-size: 14px 16px;
                }

                &.is_expanded {
                    border-bottom: $hero-accordion-borders-active;

                    &:after {
                        background: $hero-accordion-icon-active;
                        background-size: 14px 16px;
                    }
                }
            }

            .accordion_panel,
            .accordion_panel p {
                @include font-size(14px);
            }
        }
    }
    /* Hero Sub-Component : Boxed */
    .boxed-content {
        @include rounded(20px);
        padding: 45px 50px;
        margin: 0;
        background: $hero-box-bg;

        h2 {
            color: $hero-box-title;
            font-weight: 700;
            @include font-size(24px);
            margin-bottom: 40px;
        }

        p {
            color: $hero-box-text;
        }

        :global ul {
            margin: 0;
            padding: 0;
            list-style: none;
            border-top: $hero-box-list-borders;

            li {
                border-bottom: $hero-box-list-borders;
                padding: 25px 0;
                @include font-size(16px);
                color: $hero-box-text;

                a.smartlink_default {
                    @include font-size(20px);
                    font-weight: 700;
                    color: $hero-box-link;
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
        @include media-breakpoint-down(md) {
            padding: 40px 20px;
        }
    }
    /* Hero Sub-Component : Rates Table */
    .hero-rates {
        @include rounded(20px);
        padding: 25px;
        margin: 0;
        background: $hero-rates-bg;

        h2 {
            @include font-size(24px);
            color: $hero-rates-title;
            margin-bottom: 10px;
        }

        table.rates-table {
            width: 100%;

            thead tr {
                border-bottom: 1px solid $hero-rates-table-header-border;

                th {
                    color: $hero-rates-table-title;
                    font-weight: 300;
                    @include font-size(10px);
                    padding-bottom: 5px;
                    width: 70%;

                    &:last-of-type {
                        width: 30%;
                        text-align: left;
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid $hero-rates-table-border;

                    &:first-of-type {
                        border-top: 1px solid $hero-rates-table-border;
                    }
                }

                td,
                th {
                    color: $hero-rates-table-text;
                    padding: 5px 0;
                    @include font-size(20px);
                }

                th {
                    padding-right: 10px;
                    font-weight: 700;
                }

                td {
                    font-weight: 300;
                    text-align: left;
                }

                a.smartlink_default {
                    color: $hero-rates-table-link;
                }
            }

            sup {
                font-size: 65%;
                font-weight: 700;
                margin-left: 3px;
                top: -0.8em;
            }
        }
    }

    @include media-breakpoint-down(xl) {
        &.light:not(.c-hero-gradient, .c-hero-404),
        &.dark:not(.c-hero-gradient, .c-hero-404) {
            &:after {
                background-size: 60%;
            }
        }
        .hero-rates {
            margin-top: 60px;
        }
    }

    @include media-breakpoint-down(lg) {
        height: 100%;

        &:not(.c-hero-gradient, .c-hero-404) {
            background-image: none !important;
            padding-top: 0;
            padding-bottom: 0;

            &:before {
                display: none;
            }

            &:after {
                background-size: 50%;
            }
        }

        :global .hero-mobile-image {
            position: relative;
            display: flex;
            width: 100%;

            background-size: cover !important;
            background-position: right top !important;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }

        &.light:not(.c-hero-gradient, .c-hero-404, .c-hero-small) {
            background-color: $hero-light-text-bg-mobile;

            :global .hero-mobile-image {
                height: 300px;
                &:before {
                    background: none;
                    background: $hero-light-gradient-mobile;
                }
            }
        }

        &.dark:not(.c-hero-gradient, .c-hero-404, .c-hero-small) {
            background-color: $hero-dark-text-bg-mobile;

            :global .hero-mobile-image {
                height: 300px;
                &:before {
                    background: none;
                    background: $hero-dark-gradient-mobile;
                }
            }
        }

        :global .hero-content {
            padding-top: 0;
            padding-bottom: 100px;
        }

        h1 {
            @include font-size(36px);
            margin-bottom: 20px;
        }

        p {
            @include font-size(18px);
        }

        //Small Hero
        &-small {
            min-height: auto;

            // :global .hero-mobile-image {
            //     height: 224px;
            // }

            &.light:not(.c-hero-gradient, .c-hero-404) {
                background-color: $hero-small-light-text-bg-mobile;
                :global .hero-mobile-image {
                    height: 224px;
                    &:before {
                        background: none;
                        background: $hero-small-light-gradient-mobile;
                    }
                }
            }

            &.dark:not(.c-hero-gradient, .c-hero-404) {
                background-color: $hero-small-dark-text-bg-mobile;
                :global .hero-mobile-image {
                    height: 224px;
                    &:before {
                        background: none;
                        background: $hero-small-dark-gradient-mobile;
                    }
                }
            }

            &.light:not(.c-hero-gradient, .c-hero-404),
            &.dark:not(.c-hero-gradient, .c-hero-404) {
                &:after {
                    background-size: 40%;
                }
            }

            :global .hero-content {
                padding-top: 30px;
                padding-bottom: 40px;
            }
        }

        //Gradient Hero
        &-gradient{
            :global .row {
                min-height: auto;
                padding-top: 120px;
                padding-bottom: 60px;
            }
            &:after {
                content: '';
                z-index: 2;
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                background-size: 80% !important;
            }
            :global .hero-content {
                padding-right: 0;
                padding-bottom: 0;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .hero-accordions {
            @include rounded(20px);
            padding: 20px;
            margin: 0;

            h2 {
                font-weight: 400;
            }
        }
    }
}
