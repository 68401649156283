@import "../../../components/_shared/_styles/component-base";
:global .link-list {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}