@import "../../../../components/_shared/_styles/component-base";

:global .mobile-search {
  a.smartlink_buttonprimary {
    color: $mobile-search-btn-text !important; 
    @include font-size(16px);
    background-image: $mobile-search-btn-icon;
    background-repeat: no-repeat;
    background-position: 15px center;
    padding: .5rem 1.25rem .5rem 2.2rem !important;
    display: inline-block;
  }
  fieldset{
    top: 0;
  }
  .MuiInputLabel-outlined {
    background: white;
    transform: translate(14px, -6px) scale(0.75);
    background: white;
    padding: 0 4px;
  }
  @include media-breakpoint-down(lg) {
    .MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75) !important;
      background: white;
    }
  }
  .MuiOutlinedInput-root {
    position: static;
    border-radius: 4px;
  }
}

:global .mobile-login {
  font-weight: 600;
  @include font-size(10px);
  padding: 0.375rem 0.75rem;
  text-transform: uppercase;
  margin-left: 10px;
}
//Hamburger Menu Animation
:global nav.mobile-menu {
  float: right;
  width: 50px;
  height: 40px;
  padding: 10px;
  margin-left: 10px;

  #navicon {
    width: 30px;
    height: 19px;
    position: relative;
    @include rotate(0);
    @include animate();
    cursor: pointer;
    a {
      display: block;
      width: inherit;
      height: inherit;
      cursor: pointer;
    }
  }

  #navicon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $mobile-menu-icon-bg;
    opacity: 1;
    left: 0;
    @include rotate(0);
    @include animate(0.2s);
  }
  #navicon span:nth-child(1) {
    top: 0;
  }
  #navicon span:nth-child(2),
  #navicon span:nth-child(3) {
    top: 8px;
  }
  #navicon span:nth-child(4) {
    top: 16px;
  }
  #navicon.open span:nth-child(1) {
    top: 8px;
    width: 0%;
    left: 50%;
  }
  #navicon.open span:nth-child(2) {
    @include rotate(45deg);
  }
  #navicon.open span:nth-child(3) {
    @include rotate(-45deg);
  }
  #navicon.open span:nth-child(4) {
    top: 8px;
    width: 0%;
    left: 50%;
  }
}

//Mobile Menu Container
:global #mobileMenu {
  display: none;
  background: $mobile-menu-bg;
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 25%);
  -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 25%);
  position: absolute;
  top: 72px;
  left: 0;
  right: 0;
  z-index: 500;
  &.open{
    display: block
  }
  padding: {
    bottom: 25px;
    top: 25px;
    left: 20px;
    right: 20px;
  }
  .divider {
    border-top: 1px solid $mobile-menu-border;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .spacer {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .mobile_tabs_container {
    background: $mobile-menu-bg;
    .container {
      padding: 0;
    }
    & ul.menu-items.submenu-active{
      left: -100%;
    }
    & ul.menu-items.submenu-active ul.active{
      display:block;
    }
  }


  //Mobile Menu Tabs
  .mobile_menu_tabs {
    border-bottom: 1px solid $mobile-menu-border;
    ul {
      margin: 0 0 -1px 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      li {
        display: inline-block;
        button.mobile_menu_tab {
          background: $mobile-menu-tab-bg;
          padding: 8px 30px;
          outline: none;
          @include font-size(14px);
          text-transform: uppercase;
          color: $mobile-menu-tab-text;
          font-weight: 300;
          border: 0;
          border-bottom: 2px solid $mobile-menu-tab-border;
          span {
            padding: 0 4px;
          }
          &.current_mm_tab {
            background: $mobile-menu-tab-active-bg;
            font-weight: 600;
            color: $mobile-menu-tab-active-text;
            border-bottom: 2px solid $mobile-menu-tab-active-border;
          }
        }
      }
    }
  }

  //Mobile Menu Tabs
  .mm-container {
    margin-top: -1px;

    a.smartlink_buttonprimary {
        @include font-size(14px);
        padding: {
            left: 1.25rem;
            right: 1.25rem;
        }
    }
  }
  //Mobile Menu CTA
  .mobile-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px 0 20px 0;
    a {
      @include font-size(13px);
      font-weight: 700;
      text-transform: uppercase;
      background: none;
    }
  }
  

  .cta > div:not(.mobile-menu-ctas) {
      margin-top: 20px;
  }
}

:global .light-header {
  nav.mobile-menu {
    #navicon span {
        background: $mobile-menu-icon-light-bg;
    }
  }
}