@import "../../_styles/site";

.authorLink {
    cursor: pointer;
    color: $content-link;
    display: inline !important;

    &:hover {
        color: $primary-hover;
        text-decoration: underline;
    }
}

.roleLink {
    display:block;
}