@import "../../components/_shared/_styles/component-base";

//:global .menu_link.smartlink_buttonprimary{
//  position: relative;
//  top: -.4em;
//}

:global header {
  position: relative;
  top: 0;
  z-index: 1000;
  width: 100%;
  background: $header-bg;
}

:global .header-spacer {
  position: relative;
  display: block;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

:global .header.g-header {
  @include media-breakpoint-down(lg) {
    position: relative;
  }
  //Desktop Header
  #desktop-header {
    width: 100%;
    padding: {
      top: 25px;
      bottom: 25px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: $header-bg;
      @include animate(0.2s);
    }
    &.isSticky {
      position: fixed;
      top: 0;
      padding: {
        top: 12.5px;
        bottom: 12.5px;
      }
      &:before {
        background: $header-bg-locked;
      }
      .header-logo {
        max-width: 120px;
        @include animate(0.2s);
      }
      //a.smartlink_buttonprimary {
      //background: $bg-blue-4;
      //color: $text-white;
      //}
    }
    .header-logo {
      width: 100%;
      max-height: 104px;
      @include animate(0.2s);
      position:relative;
    }

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  //Mobile Header
  #mobile-header {
    width: 100%;
    z-index: 40;
    padding: {
      top: 15px;
      bottom: 15px;
    }
    position: absolute;
    top: 0;
    &.locked {
      position: fixed;
      top: 0;
    }
    @include animate(0.2s);
    .header-logo {
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      display: none;
    }
    > .container {
        :global a.smartlink_button {
            font-weight: 600;
            @include font-size(10px);
            color: $header-mobile-btn-text;
            background: $header-mobile-btn;
            padding: 0.375rem 0.75rem;
            text-transform: uppercase;
            margin-left: 10px;
            border-radius: 1.25rem;
            text-decoration: none;
          }
    }
  }
  &.light-header {
    #desktop-header {
      &.isSticky {
        &:before {
          background: $header-bg-light-locked;
        }
      }
    }
  }
}
// different than heroku, we add class to header instead of body
:global header.scroll-down {
  #mobile-header {
    transform: translate3d(0, -100%, 0);
    position: fixed !important;
    top: 0 !important;
  }
}

:global .scroll-up {
  #mobile-header {
    transform: translate3d(0, 0, 0);
    position: fixed !important;
    top: 0 !important;
  }
  .header.g-header.light-header {
    //#desktop-header {
    //  a.smartlink_buttonprimary {
    //    color: $text-white !important;
    //  }
    //}
    #mobile-header {
        background: $header-mobile-light-bg;
    }
  }
  .header.g-header {
    #mobile-header {
        background: $header-mobile-bg;
    }
  }
}

//----------------

:global #megamenu {
  ul.menu > li > a.smartlink_buttonprimary {
    background: $main-menu-btn;
    font-family: $font-family-sans-serif;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
    border: 0;
    font-family: $main-menu-btn-font-family;
    text-decoration: none !important;
    &:hover {
        background: $main-menu-btn-hover !important;
        color: $main-menu-btn-hover-text !important;
    }
  }

  ul.menu { //Main Menu
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    > li {
      position: relative;
      @include animate(0.3s);
      > a {
        font-weight: 600;
        color: $main-menu-link;
        padding: 0.4rem 0.85rem;
        @include animate(0.2s);
        text-decoration: none;
        position: relative;
        font-size: 1rem !important;
      }

      .submenu {
        display: flex;
        visibility: visible;
        position: fixed;
        top: -999px;

        width: auto;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25) ;
        -webkit-box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25) ;
        -moz-box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25) ;
        animation: 1s ease 0s normal forwards 1 fadein;
        &:not(.edge) {
          left: 50%;
          transform: translateX(-50%);
        }
        &.edge {
          transform: translateX(30%);
          right: 25px;
          margin: 0 auto;
        }

        @keyframes fadein{
          0%{
            opacity:0
          }
          80%{
            opacity:0
          }
          100%{
            opacity:1
          }
        }

        @-webkit-keyframes fadein{
          0%{
            opacity:0
          }
          80%{
            opacity:0
          }
          100%{
            opacity:1
          }
        }

        .menu_column {
          background: $main-menu-submenu-bg;
          flex: 1;
          min-width: 360px;
          padding: 40px 0 40px 40px;

          .menu_inner_column {
            display: flex;
            width: 100%;
            div {
              flex: 1;
              min-width: 200px;
              padding-right: 40px;
            }
          }
        }

        .cta {
          margin-top: 30px;
          padding-right: 40px;
          :global a.smartlink_buttonprimary {
            @include font-size(12px);
            padding: {
                left: 1.75rem;
                right: 1.75rem;
            }
            background: $main-menu-submenu-btn-primary-bg;
            border: 2px solid $main-menu-submenu-btn-primary-bg;
            color: $main-menu-submenu-btn-primary-text;
            &:hover {
                background: $main-menu-submenu-btn-primary-bg-hover !important;
                border: 2px solid $main-menu-submenu-btn-primary-bg-hover;
                color: $main-menu-submenu-btn-primary-text-hover;
            }
        }
        }

        .menu_column_promo {
          background: $main-menu-promo-bg;
          width: 300px;
          padding: 25px;
          border-left: 1px solid $main-menu-promo-border;
          display: flex;
          align-items: center;

          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          div {
            text-align: center;
            p {
              color: $main-menu-promo-text;
              font-weight: 700;
              @include font-size(16px);
            }

            :global a.smartlink_buttonprimary {
                @include font-size(12px);
                padding: {
                    left: 1.25rem;
                    right: 1.25rem;
                }
                color: $main-menu-promo-btn-text !important;
                background: $main-menu-promo-btn-bg !important;
                border: 2px solid $main-menu-promo-btn-border !important;
                &:hover {
                    border: 2px solid $main-menu-promo-btn-border-hover !important;
                    background: $main-menu-promo-btn-bg-hover !important;
                    color: $main-menu-promo-btn-text-hover !important;
                }
            }
          }
        }

        a.title {
          text-transform: uppercase;
          color: $main-menu-submenu-title;
          @include font-size(14px);
          font-weight: 600;
          margin-bottom: 10px;
          display: block;
        }

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          flex-direction: column;
          li {
            margin-bottom: 8px;
            a {
              @include font-size(16px);
              font-weight: 600;
              color: $main-menu-submenu-link;
            }
          }
        }
      }

      &.focus {
        .submenu {
          position: absolute;
          visibility: visible;
          display: flex;
          top: 40px;
          z-index: 40;
        }
        > a {
          color: $main-menu-submenu-link-hover;
          position: relative;
        }
        &.hasSubMenu {
          > a {
            &:before {
              content: '';

              width: 16px;
              height: 16px;

              background: $main-menu-submenu-bg;
              box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25) ;
              -webkit-box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25) ;
              -moz-box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25) ;

              position: absolute;
              top: 45px;
              left: 40%;
              transform: translateY(-50%) rotate(45deg);
              z-index: 1;
            }
          }
        }
      }

      &.menu_active {
        > a {
            color: $main-menu-submenu-link-active;
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    ul.menu {
      li {
        a {
          @include font-size(14px);
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

:global .light-header {
    #megamenu {
        ul.menu {
            li {
                a.menu_link {
                    color: $main-menu-link-light;
                }
                &.focus {
                    > a {
                        color: $main-menu-link-hover;
                    }
                }
                &.menu_active {
                    > a {
                        color: $main-menu-link-active;
                    }
                }
                > a.smartlink_buttonprimary {
                    background: $main-menu-btn-dark;
                    @include box-shadow(0px, 0px, 20px, rgba(0, 0, 0, 0.05));
                    color: $main-menu-btn-text-dark !important;
                    &:hover {
                        background: $main-menu-btn-hover !important;
                        color: $main-menu-btn-hover-text !important;
                    }
                }
            }
        }
    }
    //Sticky
    #desktop-header.isSticky {
        #megamenu {
            ul.menu {
                li {
                    > a.smartlink_buttonprimary {
                        background: $main-menu-btn-dark-sticky;
                        color: $main-menu-btn-text-dark-sticky !important;
                        &:hover {
                            background: $main-menu-btn-hover-dark-sticky !important;
                            color: $main-menu-btn-hover-dark-text-sticky !important;
                        }
                    }
                }
            }
        }
    }
}

//Sticky
:global #desktop-header.isSticky {
    #megamenu {
        ul.menu {
            li {
                > a.smartlink_buttonprimary {
                    background: $main-menu-btn-sticky;
                    color: $main-menu-btn-text-sticky !important;
                    &:hover {
                        background: $main-menu-btn-hover-sticky !important;
                        color: $main-menu-btn-hover-text-sticky !important;
                    }
                }
            }
        }
    }
}
