@import "../components/_shared/_styles/component-base";

.section{
    background: #00193D;
    height: 100vh;
}
.page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    width: 100%;
    height: 100%;

    background-image: url("../_assets/ripple-transparent.webp");
    background-size: cover;
    background-position: left center;
    h1 {
        color: #fff;
        font-size: 48px;
        margin-bottom: 10px;
        font-weight: 400;
    }
    h2 {
        letter-spacing: normal;
        color: #fff;
        font-size: 48px;
        font-weight: 400;
        display: block;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        margin-bottom: 10px;
    }
    p {
        font-size: 20px;
        color: #fff;
        line-height: 1.5em;
    }
    a {
        color: $secondary-lighter;
        text-decoration: none;
        &:hover{
            color: $secondary-lighter;
        }
    }

    .on-mobile {
        display: none;
    }
}
.container {
    width: 100%;
    max-width: 1240px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 100px 20px;
}
.header {
    padding: 64px 40px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
}


@media screen and (max-width: 991px) {
    .header {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    header img {
        width: 100%;
        max-width: 160px;
    }
    .page {

        h1, h2 {
            font-size: 36px;
        }
        p {
            font-size: 18px;
        }
        @media screen and (max-width: 767px) {
            .on-desktop {
                display: none;
            }

            .on-mobile {
                display: block;
            }
        }
    }
}
