@import "../_shared/_styles/component-base";

.c-product-carousel {
  background: $carousel-large-bg;
  overflow-x: hidden;

  /* Intro Text */
  :global .intro {
    margin-bottom: 30px;
    h2 {
        color: $carousel-large-title;
    }
  }

  :global .eyebrow {
    margin-bottom: 15px;
  }

  :global .slick-arrow {
    position: absolute;
    z-index: 5;
    background: none;
    border: 0;
    width: 70px;
    padding: 1px 6px;
    img {
      width: 100%;
    }

    &.slick-prev {
      top: 50%;
      transform: translateY(-50px);
      left: -35px;
    }

    &.slick-next {
      top: 50%;
      transform: translateY(-50px);
      right: -35px;
    }

    &.slick-disabled {
      @include opacity(0.2);
    }

    @include media-breakpoint-up(lg) {
      &.slick-prev {
        left: -70px;
      }

      &.slick-next {
        right: -70px;
      }
    }

    @include media-breakpoint-up(xl) {
      &.slick-prev {
        left: -150px;
      }

      &.slick-next {
        right: -150px;
      }
    }
  }

  :global .slick-slider {
    margin: 0 auto;
    @include make-col-ready();
    @include make-col(12);

    @include media-breakpoint-up(lg) {
      @include make-col(10);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(8);
    }

    .slick-list {
      overflow: visible;
      padding: 0 !important;
    }

    .slick-slide {
      @include animate(0.2s);
      padding: {
        left: 10px;
        right: 10px;
      }

      &:not(.slick-active) {
        margin-top: 30px;
        @include opacity(0.2);
      }

      //Product Panel Content

    }
  }

  /* Product Carousel Nav */
  :global .product-carousel-nav {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    & > button {
      color: $carousel-large-nav-link;
      text-transform: uppercase;
      @include font-size(13px);
      @include animate(0.2s);
      cursor: pointer;
      border: 0;
      background: none;
      margin: {
        left: 15px;
        right: 15px;
      }
      &:hover {
        text-decoration: underline;
        color: $carousel-large-nav-link-hover;
      }
      &:focus {
        outline: 1px dotted;
      }
      &.active {
        color: $carousel-large-nav-link-active;
        @include font-size(15px);
      }
    }

    @include media-breakpoint-down(md) {
      & > button {
        @include font-size(11px);
        margin: {
          left: 8px;
          right: 8px;
        }
        &.active {
          @include font-size(13px);
        }
      }
    }
  }
}
