@import "../_shared/_styles/component-base";

.credit-card-row{
    font-size: 21px;
}

:global .dont-print{
    @media print{
        display: none;
    }
}
:global .only-print{
    display: none;
    @media print{
        display: block;
    }
}

:global div.MuiInputBase-root.pageFilter.select {
    font-size: 1.625rem !important;
    margin-bottom: 40px!important;
    padding-bottom: 10px!important;
    padding-left: 10px!important;
    color: #193C78!important;
    border: none;
    border-bottom: 1px solid #D8D8D8!important;
    background-image: url("../../_assets/single-chevron-down-blue.svg")!important;
    background-color: transparent;
    background-repeat: no-repeat!important;
    background-position: 97% 48%!important;
    background-size: 20px!important;

    & :after {
        display: none;
    }

    & .MuiSelect-select:focus {
        background-color: transparent;
    }

    & .MuiSvgIcon-root {
        display: none;
    }
}

:global .disclosure-note {
    position: relative;
    top: -15px;
    margin-bottom: 30px;
    color: $black;
    @include font-size(12px);
    display: flex;
    padding: {
        left: 15px;
        right: 15px;
    }
    .effective-date{
        height: 20px;
        align-items: center;
    }
    @include media-breakpoint-down(lg) {
        justify-content: flex-start;
        div {

            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .align-unset{
            align-items: unset;
        }
    }
    @include media-breakpoint-down(md){
        top: -30px;
        .effective-date{
            height: unset;
        }
    }
    p:last-of-type{
        margin-bottom:0;
        margin-right: 7px;
    }

}

:global .hero-filter {
    position: relative;
    background: $hero-filter-no-gradient;
    background: $hero-filter-gradient;
    height: unset;
    padding-top: 200px;
    @include font-size(20px);

    &:after {
        content: '';
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: 100%;
        background: $hero-filter-watermark;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        background-size: 40%;
        @include opacity(0.5);
    }
    .container {
        position: relative;
        z-index: 2;
    }

    .desc-text {
        display: flex;
        justify-content: center;
        width: 100%;

        > div {
            width: 50%;
            padding: 0 15px;
        }
    }

    .related-box {
        border: 1px solid $hero-filter-box-border;
        padding: 30px;
        width: 100%;
        max-width: 485px;
        float: right;

        .related-box-title {
            color: $hero-filter-box-title;
            @include font-size(20px);
            font-weight: 700;
        }

        .link-list {
            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    a.smartlink_default {
                        @include font-size(20px);
                        font-weight: 700;
                        color: $hero-filter-box-link;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        .desc-text {
            flex-direction: column;

            > div {
                width: 100%;
                margin-bottom: 40px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .related-box {
            max-width: 100%;

            .link-list {
                ul {
                    li {
                        a {
                            @include font-size(18px);
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        .related-box {
            padding: 15px;

            .related-box-title {
                @include font-size(18px);
            }

            .link-list {
                ul {
                    li {
                        a {
                            @include font-size(16px);
                        }
                    }
                }
            }
        }
    }

    a.smartlink_button {
        margin-top: 10px;
    }
    @include media-breakpoint-down(md) {
        padding-top: 80px;

        select.filter {
            @include font-size(22px);
            margin-bottom: 20px;
        }

        .text-center p {
            @include font-size(18px);
        }

        .rates-filter-container {
            margin-top: 20px;
        }
    }
}
//Rates Container
:global .rates-filter-container {
    background: $rates-filter-bg;
    padding: 45px 100px;
    margin-top: 60px;
    border-radius: 0.35rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.10);
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.10);
    position: relative;
    overflow: hidden;

    @media print{
        width: 8.1in;
        margin: .2in .2in 0 .2in;
    }

    .rates-filters {
        margin-bottom: 30px;
        width: 100%;
        display: flex;
        align-items: flex-end;
        flex-grow: 1;

        > div {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
        }

        label {
            display: block;
            color: $rates-filter-label;
            @include font-size(12px);
            text-transform: uppercase;
            padding-left: 3px;
        }

        input[type=text] {
            width: 100%;
            border: 1px solid $rates-filter-input-border;
            height: 42px;
            @include rounded(6px);
            margin: 6px 0 0;
            padding-left: 10px;
            padding-right: 10px;
            color: $rates-filter-input-text;
        }

        select.custom {
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-down(lg) {
        margin-top: 20px;
        padding: 20px;

        .rates-filters {
            flex-direction: column;

            > div.filter {
                margin-bottom: 20px;
            }
        }

        select.custom {
            max-width: 100%;
        }
    }
}

//Calculator Container
:global .disclosure-block {
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    @include font-size(12px);
    background-color: $disclosures-bg;

    p {
        @include font-size(12px);
        color: $disclosures-text;
    }
    @include media-breakpoint-down(lg) {
        padding-left: 0;
        padding-right: 0;
    }
    @media print{
        background: white;
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 40px;
    }
}
