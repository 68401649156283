@import "../_shared/_styles/component-base";

.print-button{
    span{
        font-weight: 900;
    }
    :hover{
        text-decoration: underline;
        color: $text-color-hover;
        cursor: pointer;
    }
}