@import '../../_styles/helpers/mixins';
@import '../../../_variables';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/tables";

.c-category {
    table.rates-table {
        width: 100%;
        background: #FFF;
        border-collapse: collapse;
        border: 0;
        overflow: hidden;

        thead {
            tr th {
                padding: 4px 15px 4px 0;
            }

            tr th:first-of-type {
                text-align: left;
                width: 70%;
            }
            tr th:last-of-type {
                width: 30%;
                text-align: left;
            }
        }

        tr {
            border-bottom: 1px solid $category-table-border;
        }

        tr th {
            padding: 4px 20px;
            text-align: left;
            @include font-size(12px);
            font-weight: 400;
        }

        tr th[scope=row] {
            @include font-size(18px);
            padding: 4px 0;
        }

        tr td  {
            //padding: 4px 0 4px 20px;
            text-align: left;
            @include font-size(18px);
            font-weight: 400;
        }

        strong {
            font-weight: 600;
        }
    }
}

.flex-gap-10 {
    display: flex;
    justify-content: center;
    gap: 10px;
}

table.rates {
    width: 100%;
    @include font-size(14px);
    thead {
        th {
            text-transform: uppercase;
            padding: 20px;
            text-align: center;
            font-weight: 700;
            color: $rates-table-table-header;
            padding: 20px 10px;
            vertical-align: middle;
        }
    }
    tbody {
        border-bottom: 1px solid $rates-table-table-border;
        tr {
            border-top: 1px solid $rates-table-table-border;
            vertical-align: middle;
        }
        th, td {
            text-align: center;
            padding: 10px;
            color: $rates-table-table-text;
        }
    }

    @include media-breakpoint-up(sm) {
        table-layout: fixed;
    }
}

.table-row-loading {
    display: none;
    &.show {
        display: block;
    }
}

.cta-row {
    a.btn {
        @include font-size(16px);
        margin: {
            left: 10px;
            right: 10px;
        }
    }
}

.scrollable {
    width: 100%;
    padding-bottom: 20px;

    @include media-breakpoint-down(lg) {
        overflow-x: scroll;
        @media print{
            overflow-x: visible;
        }
    }

    table.rates {
        width: 100%;
        min-width: 767px !important;
    }

    &.table-small {
        table.rates {
            min-width: 480px !important;
        }
    }
}
