@import '../../../styles/index.scss';

.alertContainer {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.144px;
  color: $input-black;
  display: flex;
  flex-direction: column;
}

.error {
  background: $error-alert;
}
.warning {
  background: $warning-alert;
}
.success {
  background: $success-alert;
}
.info {
  background: $info-alert;
}

.alertTitle {
  font-weight: 400;
  margin-bottom: 10px;
  text-transform: capitalize;
}
