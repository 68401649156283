@import "../_shared/_styles/component-base";
.c03-small-carousel {
  @include background();

  :global .intro h2 {
    color: $carousel-testimonial-intro-title;
  }

  :global .intro p {
    color: $carousel-testimonial-intro-desc;
  }

  :global .slick-arrow {
    position: absolute;
    z-index: 5;
    background: none;
    border: 0;
    width: 40px;
    margin: 0;
    padding: 0;
    img {
      width: 100%;
      max-width: 26px;
    }
  }

  :global .slick-prev {
    top: 75px;
    left: -30px;
  }

  :global .slick-next {
    top: 75px;
    right: -30px;
  }

  &.slick-disabled {
    @include opacity(0.2);
  }

  @include media-breakpoint-down(lg) {
    img {
      max-width: 20px;
    }
  }
  @include media-breakpoint-up(lg) {
    :global .slick-prev {
      left: -100px;
    }

    :global .slick-next {
      right: -100px;
    }
  }

  @include media-breakpoint-up(xl) {
    :global .slick-prev {
      left: -120px;
    }

    :global .slick-next {
      right: -120px;
    }
  }

  :global .slick-disabled {
    @include opacity(0.2);
  }

}
