@import "../_shared/_styles/component-base";

.image-list {
  background: $imagelist-background;

  .intro {
    margin-bottom: 80px;
    h2 {
      font-size: 1.375rem;
      font-weight: 700;
      color: $imagelist-intro-title;
    }
    p {
      font-size: 0.875rem;
      color: $imagelist-intro-text;
      margin-top: 15px;
    }
  }

  .image-list-layout {

    .image-list-item {
      @include make-col-ready();
      margin-bottom: 20px;
    }

    &.image-list-count-1,
    &.image-list-count-2,
    &.image-list-count-3,
    &.image-list-count-4 {
      .image-list-item {
        @include make-col(6);

        @include media-breakpoint-up(lg) {
          @include make-col(3);
        }
      }
    }

    &.image-list-count-5,
    &.image-list-count-6 {
      .image-list-item {
        @include make-col(6);

        @include media-breakpoint-up(lg) {
          @include make-col(4);
        }
      }
    }

    &.image-list-count-7 {
      .image-list-item {
        @include make-col(6);

        @include media-breakpoint-up(lg) {
          @include make-col(3);
        }
      }
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .item-badge {
      height: 110px;
      display: inline-flex;
      align-items: center;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: auto;
      }
    }

    .item-content {
      text-align: center;
      @include font-size(14px);
      color: $imagelist-text;
    }

  }

  @include media-breakpoint-down(lg) {
    [class*="col-"] {
      margin-bottom: 40px;
    }
  }
}
