@import "../../../components/_shared/_styles/component-base";

//Search Panel
:global .search-panel {
    background: $toolbar-search-panel-bg;
    width: 560px;
    
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25) ;
    -webkit-box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25) ;
    -moz-box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25) ;
    position: fixed;
    top: 40px;
    // left: 50%;
    transform: translateX(-41%);
    padding: 20px 45px;
    display: none;
    height: 0;
    transition: height 350ms ease-in-out, display 450ms ease-in-out;
    &-open {
        display: block;
        height: auto;
        &:after {
        content: '';
        width: 16px;
        height: 16px;
        background: $toolbar-search-panel-bg-active;
        position: absolute;
        top: 2px;
        left: 50%;
        transform: translateY(-50%) rotate(45deg);
        z-index: 19;
        }
    }
    form {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
    .field {
        position: relative;
        &:first-of-type {
        flex: 1;
        padding-right: 5px;
        }
        &:last-of-type {
        width: 36px;
        }
    }
    fieldset{
        top: 0;
    }
    label {
        color: $toolbar-search-panel-label;
        text-align: left;
        color: #909090;
        top: 3px;
        background: $toolbar-search-panel-label-bg;
        &.focused {
            color: $toolbar-search-panel-label-active;
            @include font-size(11px);
        }
    }
    input[type=text],
    input.ais-SearchBox-input {
        width: 100%;
        // border: 1px solid $toolbar-search-input-border;
        @include rounded(3px);
        color: $toolbar-search-input-text;
        @include font-size(14px);
        height: 42px;
        padding: 0px 10px;
    }
    input.ais-SearchBox-input {
        box-shadow: none;
        padding-right: 65px;
    }
    button.ais-SearchBox-reset {
        right: 55px;
    }
    .ais-SearchBox-form {
        &:before {
        display: none;
        }
    }
    .ais-SearchBox-submit {
        display: block;
        width: 40px;
        height: 40px;
    }
    .ais-SearchBox-submit svg {
        width: 20px;
        height: 20px;
        fill: $toolbar-search-icon-color;
    }
    button.btn-search {
        width: 36px;
        height: 36px;
        border: 0;
        background: none;
    }
}