@import "./variables_buildvalues";

@font-face {
  font-family: 'Manrope';
  src: url("App/_styles/fonts/Manrope-Light.otf") format("opentype"),
  url('App/_styles/fonts/Manrope-Light.ttf') format('truetype'),
  url('App/_styles/fonts/Manrope-Light.woff2') format('woff2'),
  url('App/_styles/fonts/Manrope-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url("App/_styles/fonts/Manrope-Regular.otf") format("opentype"),
  url('App/_styles/fonts/Manrope-Regular.ttf') format('truetype'),
  url('App/_styles/fonts/Manrope-Regular.woff2') format('woff2'),
  url('App/_styles/fonts/Manrope-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url("App/_styles/fonts/Manrope-Medium.otf") format("opentype"),
  url('App/_styles/fonts/Manrope-Medium.ttf') format('truetype'),
  url('App/_styles/fonts/Manrope-Medium.woff2') format('woff2'),
  url('App/_styles/fonts/Manrope-Medium.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url("App/_styles/fonts/Manrope-Bold.otf") format("opentype"),
  url('App/_styles/fonts/Manrope-Bold.ttf') format('truetype'),
  url('App/_styles/fonts/Manrope-Bold.woff2') format('woff2'),
  url('App/_styles/fonts/Manrope-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PublicSans';
  src: url('App/_styles/fonts/PublicSansLight.eot');
  src: url('App/_styles/fonts/PublicSansLight.eot') format('embedded-opentype'),
  url('App/_styles/fonts/PublicSansLight.woff2') format('woff2'),
  url('App/_styles/fonts/PublicSansLight.woff') format('woff'),
  url('App/_styles/fonts/PublicSansLight.ttf') format('truetype'),
  url('App/_styles/fonts/PublicSansLight.svg#PublicSansLight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'PublicSans';
  src: url('App/_styles/fonts/PublicSansRegular.eot');
  src: url('App/_styles/fonts/PublicSansRegular.eot') format('embedded-opentype'),
  url('App/_styles/fonts/PublicSansRegular.woff2') format('woff2'),
  url('App/_styles/fonts/PublicSansRegular.woff') format('woff'),
  url('App/_styles/fonts/PublicSansRegular.ttf') format('truetype'),
  url('App/_styles/fonts/PublicSansRegular.svg#PublicSansRegular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PublicSans';
  src: url('App/_styles/fonts/PublicSansMedium.eot');
  src: url('App/_styles/fonts/PublicSansMedium.eot') format('embedded-opentype'),
  url('App/_styles/fonts/PublicSansMedium.woff2') format('woff2'),
  url('App/_styles/fonts/PublicSansMedium.woff') format('woff'),
  url('App/_styles/fonts/PublicSansMedium.ttf') format('truetype'),
  url('App/_styles/fonts/PublicSansMedium.svg#PublicSansMedium') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'PublicSans';
  src: url('App/_styles/fonts/PublicSansBold.eot');
  src: url('App/_styles/fonts/PublicSansBold.eot') format('embedded-opentype'),
  url('App/_styles/fonts/PublicSansBold.woff2') format('woff2'),
  url('App/_styles/fonts/PublicSansBold.woff') format('woff'),
  url('App/_styles/fonts/PublicSansBold.ttf') format('truetype'),
  url('App/_styles/fonts/PublicSansBold.svg#PublicSansBold') format('svg');
  font-weight: 700;
  font-style: normal;
}