@mixin leftRadius {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

@mixin text {
  margin: 0px;
  color: $dark-gray;
  font-size: 14px;
  font-weight: 400;
}

@mixin rightRadius {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

// Label
@mixin label($margin, $color, $font-size, $font-weight) {
  margin: $margin;
  color: $color;
  font-size: $font-size;
  font-weight: $font-weight;
}

@mixin focusedLabel {
  padding: 0 5px;
  z-index: -1;
  font-size: 14px;
  background: none;
  opacity: 0;
}

@mixin unfocusedLabel {
  z-index: 9999;
  font-size: 13px;
}

@mixin inputBorder($color) {
  border: 1px solid $color;
}

@mixin text {
  margin: 0px;
  color: $dark-gray;
  font-size: 14px;
  font-weight: 400;
}

// Containers
@mixin labelContainer($position, $bottom, $left, $padding, $background) {
  position: $position;
  bottom: $bottom;
  left: $left;
  padding: $padding;
  background-color: $background;
}

@mixin sizeFlexible($max-width, $position, $display, $justify) {
  max-width: $max-width;
  position: $position;
  display: $display;
  justify-content: $justify;
}

@mixin container($width, $position, $border, $background, $radius, $padding, $cursor, $z-index) {
  width: $width;
  position: $position;
  border: $border;
  background-color: $background;
  border-radius: $radius;
  padding: $padding;
  cursor: $cursor;
  z-index: $z-index;
}
@mixin textContainerFooter(
  $height,
  $left,
  $right,
  $top,
  $font-family,
  $font-style,
  $font-weight,
  $font-size,
  $line-height,
  $letter-spacing,
  $color,
  $max-width
) {
  height: $height;
  left: $left;
  right: $right;
  top: $top;
  font-family: $font-family;
  font-style: $font-style;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  color: $color;
  max-width: $max-width;
}

@mixin inputOptionsContainer {
  right: -1.5px !important;
  left: -1.5px !important;
  margin: auto;
  height: auto;
  max-height: 150px;
  z-index: 99999 !important;
  cursor: pointer;
  position: absolute;
  border-radius: 3px;
  overflow-y: scroll;
  animation-name: show;
  box-sizing: border-box;
  background-color: #e4e6e9;
  animation-duration: 0.2s;
  @include inputBorder(#d4d4d4);
}

@mixin inputOptionItem {
  padding: 10px 15px;
  @include text;
  &:hover {
    background-color: $select-blue;
    color: $white;
  }
}

@mixin layoutPadding {
  padding: 0 115px;
  @media screen and (max-width: $break--tblt) {
    padding: 0 50px;
  }
  @media screen and (max-width: $break--mbl-lg) {
    padding: 0;
  }
}

//textAlert (error and Helper)
@mixin textAlert($color) {
  margin: 0;
  font-size: 11px;
  font-weight: 200;
  line-height: 16px;
  color: $color;
  letter-spacing: 0.144px;
}

//label
@mixin labelType($color) {
  margin: 0;
  position: absolute;
  color: $color;
  background: $white;
  letter-spacing: 0.144px;
  animation-duration: 0.4s;
  text-transform: capitalize;
  padding: 0 5px !important;
}

@mixin semiBoldOpenSans {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}

@mixin highlight {
  @include semiBoldOpenSans;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.144px;
  color: $primary-bright;
}
@mixin containerMixin {
  width: 95%;
  max-width: 1080px;
  margin: 0 auto;
}

@mixin mbl {
  @media (min-width: $breakMin--mbl) {
    @content;
  }
}

@mixin tblt {
  @media (min-width: $breakMin--tblt) {
    @content;
  }
}

@mixin tbltLg {
  @media (min-width: $breakMin--tblt--lg) {
    @content;
  }
}
@mixin dsktp {
  @media (min-width: $break--tblt) {
    @content;
  }
}
@mixin dsktpLg {
  @media (min-width: $break--tblt) {
    @content;
  }
}

@mixin boxShadow($type) {
  @if $type == error {
    :focus {
      box-shadow: rgba(220, 53, 69, 25%) 0 0 6px;
    }
  }
}
