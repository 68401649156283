@import "../../../components/_shared/_styles/component-base";

:global .g-toolbar {
  width: 100%;
  position: relative;
  z-index: 500;
  overflow-x: hidden !important;
  &.no-tabs{
    background-color: $toolbar-bg;
  }
  &:before {
    content: '';
  }
  &:after {
    content: '';
  }
  .container {
    position: relative;
    padding: {
      right: 0;
    }
    &:before {
      content: '';
      position: absolute;
      left: -100%;
      top: 0;
      margin-left: -1px;
      width: 100%;
      height: 40px;
      background-color: $toolbar-bg;
    }
    &:after {
      content: '';
      position: absolute;
      left: 100%;
      top: 0;
      width: 100%;
      height: 40px;
      background-color: $toolbar-bg;
    }
  }
  [class*='col-'] {
    background-color: $toolbar-bg;
    &.no-bg {
      background-color: transparent;
    }
  }
  .no-gutters {
    padding: 0;
  }
  .nav-height {
    height: 2.5rem;
    margin-right: -1px;
  }
  ul.site-select {
    margin: 0;
    padding: 0;
    list-style: none;
    width: auto;
    display: inline-block;
    position: relative;
    font-size: 0;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: -100%;
      background: $toolbar-bg;
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 100%;
      background: $toolbar-bg;
    }
    li {
      height: 40px;
      display: inline-block;
      vertical-align: center;
      background-color: $toolbar-bg;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 5px 20px;
        text-transform: uppercase;
        text-decoration: none;
        color: $toolbar-site-select-text;
        @include font-size(13px);
        font-weight: 600;
      }
      &.active {
        background-color: transparent;
        a {
            color: $toolbar-site-select-text-active;
        }
      }
    }
  }
  //Toolbar Links
  .link-list {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      li {
        display: flex;
        align-items: center;
        color: $toolbar-link-text;
        height: 100%;
        position: relative;
        @include font-size(12px);
        &:first-child {
          padding-right: 15px;
          &:after {
            content: '|';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            color: $toolbar-link-divider;
            @include font-size(18px);
          }
        }
        &.login {
            background: $toolbar-login-bg;
            margin-right: -1px;
            a:not(.smartlink_button) {
                color: $toolbar-login-text;
                img{
                  margin-right: 9px;
                }
                &:after {
                    display: none;
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
        &.search {
            a {
                padding-left: 35px;
                color: $toolbar-link-text;
                background-image: $toolbar-search-icon;
                background-repeat: no-repeat;
                background-position: 15px center;
                &:after {
                    display: none;
                }
                &:hover {
                    text-decoration: none;
                }
                &.searchbar-open {
                    background-image: $toolbar-search-icon-active;
                    background-position: 8px center;
                }
            }
        }
        > a {
            color: $toolbar-link-text;
            display: flex;
            align-items: center;
            height: 100%;
            text-transform: uppercase;
            font-weight: 700;
            padding: {
                left: 15px;
                right: 15px;
            }
            text-decoration: none;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                width: 0;
                height: 2px;
                background: $toolbar-link-animation;
                bottom: 8px;
                right: 0;
                left: 0;
                margin: 0 auto;
                @include animate(0.3s);
            }
            &:hover,
            &:focus {
                &:after {
                    width: 80%;
                }
            }
            &:hover {
                color: $toolbar-link-text-hover;
            }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

:global .light-header {
  ul.site-select {
    li.active {
      a {
        color: $toolbar-site-select-text-active-light-header;
      }
    }
  }
}
