@import "../../_shared/_styles/component-base";

.c-promo-primary {
  position: relative;

  //Light Text
  &.light {

    &:before {
      content: '';
      width: 100%;
      height: 100%;

      position: absolute;

      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      background: $promo-primary-light-no-gradient;
      background: linear-gradient(90deg, $promo-primary-light-gradient-top 0%, $promo-primary-light-gradient-bottom 100%);
    }

    :global .eyebrow {
      color: $promo-primary-light-eyebrow;
    }

    :global h2 {
     color: $promo-primary-light-title;
    }

    :global p {
     color: $promo-primary-light-text;
    }

    :global &.offset {
      &:before {
        background: $promo-primary-light-no-gradient;
        background: linear-gradient(270deg, $promo-primary-light-gradient-top 0%, $promo-primary-light-gradient-bottom 100%);
      }
    }
  }

  //Dark Text
  &.dark {
    &:before {
      content: '';
      width: 100%;
      height: 100%;

      position: absolute;

      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      background: $promo-primary-dark-no-gradient;
      background: linear-gradient(90deg, $promo-primary-dark-gradient-top 0%, $promo-primary-dark-gradient-bottom 100%);
    }

    :global .eyebrow {
      color: $promo-primary-dark-eyebrow;
    }

    :global h2 {
      color: $promo-primary-dark-title;
    }

    :global p {
      color: $promo-primary-dark-text;
    }

    :global &.offset {
      &:before {
        background: $promo-primary-dark-no-gradient;
        background: linear-gradient(90deg, $promo-primary-dark-gradient-top 0%, $promo-primary-dark-gradient-bottom 100%);
      }
    }
  }

}
