@import "../../_shared/_styles/component-base";

:global .scrollable-horizontal {
  width: 100%;
  scrollbar-color: rgba(0, 0, 0, .5) rgba(255, 255, 255, 0) !important;
  overflow-x: hidden;
  @include media-breakpoint-down(lg) {
      overflow-x: scroll;
  }

  table.rates {
    width: 100%;
    min-width: 767px !important;
  }

  &.table-small {
    table.rates {
      min-width: 480px !important;
    }
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 12px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0);
  }
}

.scrollable-horizontal-clone {
  position: absolute;
  visibility: hidden;
}
