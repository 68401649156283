@import "../../../components/_shared/_styles/component-base";

:global footer.g-footer {
    //Upper Footer
    #upper-footer {
        background: $footer-upper-bg;
        padding-top: 60px;
        padding-bottom: 45px;
        color: $footer-upper-text;

        .grid-columns {
            padding-bottom: 40px;
            margin-bottom: 40px;
            border-bottom: 1px solid $footer-upper-divider;

            @include media-breakpoint-up(lg) {
                display: flex;
                .column {
                    flex: 1;
                }
            }

            @include media-breakpoint-down(xl) {
                column-count: 2;
                column-width: 50%;
                .column {
                    display: inline-block;
                    margin: 0;
                    width: 100%;
                }
            }

            .header-six {
                text-transform: uppercase;
                margin-bottom: 20px;
                @include font-size(16px);
                font-weight: 400;
                color: $footer-upper-title;
                font-family: MuseoSlab,sans-serif!important;
            }

            h6 {
                text-transform: uppercase;
                margin-bottom: 20px;
                @include font-size(16px);
                font-weight: 400;
                color: $footer-upper-title;
            }

            a:not(.smartlink_button) {
                color: $footer-upper-link;
            }

            .link-list {
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    padding-right: 40px;
                    padding-bottom: 40px;
                    flex-direction: column;
                    li {
                        margin-bottom: 10px;
                        a {
                            font-weight: 700;
                        }
                    }
                }
            }

        }

        .footer-info {
            display: flex;
            align-items: flex-end;
            strong {
                font-weight: 700;
            }
            div {
                @include font-size(14px);
                padding-right: 30px;
                &.info-col-1, &.info-col-2, &.info-col-3, &.info-col-4 {
                    p:last-of-type{
                        margin-bottom: 0;
                    }
                }
            }
            a:not(.smartlink_button) {
                color: $footer-upper-link;
            }
        }

        .link-list.extra {
            ul {
                justify-content: flex-end;
                align-items: center;
                //flex-wrap: nowrap;
                li {
                    padding-left: 30px;
                    img {
                        max-height: 48px;
                    }
                    &:first-child {
                        padding-left: 0;
                    }
                }
            }
        }

        @include media-breakpoint-down(lg) {
            .footer-info {
                margin-top: 40px;
                margin-bottom: 40px;
            }

            ul.extra {
                justify-content: center;
            }
        }

        @include media-breakpoint-down(md) {
            .footer-info {
                flex-wrap: wrap;
                margin-bottom: 20px;
                div {
                    margin-bottom: 20px;
                    &.info-col-1 {
                        width: 50%;
                    }
                    &.info-col-2 {
                        width: 50%;
                        line-height: 2.0em;
                    }
                    &.info-col-3 {
                        width: 50%;
                        line-height: 2.0em;
                    }
                    &.info-col-4 {
                        width: 50%;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            padding-top: 40px;
            .grid-columns {
                padding-bottom: 0px;
                ul {
                    padding-right: 15px;
                }
            }

            .footer-info {
                flex-wrap: wrap;
                margin-bottom: 20px;
                div {
                    margin-bottom: 20px;
                    width: 100% !important;
                }
            }
        }
    }

    //Lower Footer
    #lower-footer {
        background: $footer-bottom-bg;
        padding: 25px 0 60px 0;

        //Global Footer Links
        .link-list {
            ul {
                li {
                    position: relative;
                    padding-right: 15px;
                    padding-left: 15px;
                    &:after {
                        content: '|';
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        @include font-size(14px);
                        color: $footer-bottom-divider-link;
                    }
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                        &:after {
                            display: none;
                        }
                    }
                    a.smartlink_default {
                        text-transform: uppercase;
                        color: $footer-bottom-link;
                        @include font-size(12px);
                    }
                }
            }
            @include media-breakpoint-down(lg) {
                ul {
                    justify-content: flex-start;
                    margin-bottom: 50px;
                }
            }
            @include media-breakpoint-down(md) {
                ul {
                    flex-wrap: wrap;
                    li {
                        width: 50%;
                        padding-left: 0;
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        //Social Links
        .link-list.social {
            ul {
                justify-content: flex-end;
                li {
                    padding-left: 15px;
                    padding-right: 0;
                    &:first-child {
                        margin-left: 0;
                    }
                    &:after {
                        display: none;
                    }
                }
            }
            @include media-breakpoint-down(md) {
                ul {
                    justify-content: center;
                }
            }
            @include media-breakpoint-down(lg) {
                ul {
                    justify-content: space-between;
                    li {
                        padding-left: 7px;
                        padding-right: 7px;
                        width: unset;
                        &:first-child {
                            padding-left: 0;
                        }
                        &:last-child {
                            padding-right: 0;
                        }
                    }
                }
            }
        }

        //Disclosures
        .footer-disclosures {
            margin-top: 50px;
            display: flex;
            margin-left: -15px;
            margin-right: -15px;

            .disclosure {
                flex: 1;
                padding: {
                    left: 15px;
                    right: 15px;
                }
                color: $footer-bottom-text;
                @include font-size(12px);

            }
        }

        @include media-breakpoint-down(md) {
            .footer-disclosures {
                flex-direction: column;
                .disclosure {
                    width: 100%;
                }
            }
        }
    }
}
