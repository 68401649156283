@import "../_styles/component-base";

:global .MuiDialog-root{
    &.pass-through{
        pointer-events: none;
    }
}

:global .MuiModal-backdrop{
    background: $modal-overlay-bg !important;
}

:global .MuiDialog-container {
    
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box;

    :global .MuiPaper-root.bio-modal{
        // padding: 20px 30px 0 30px;
        padding: 40px 75px;
        @include media-breakpoint-down(md) {
            padding: 20px;
        }
        .dialog-title{
            padding: 0 20px 20px 0;
        }
    }
    

    .MuiButtonBase-root {
        @include font-size(0);
        @include opacity(0.7);
        &:focus,
        &:hover {
            background: none;
            @include opacity(1);
            border: 0;
            outline: none;
        }
        &.closeButton{
            position: absolute;
            right: 20px;
            top: 40px;
            @include media-breakpoint-down(md) {
                top: 10px;
                right: 10px;
            }
        }
    }
    .MuiPaper-root{
        margin: 0px;
    }
    .backdrop-modal{
        height: 0;
        padding: 0 !important;
    }
    .location-modal{
        padding: 3px 12px !important; 
        &.closeButton{
            position: absolute;
            right: 30px;
            top: 16px;
        }
    }
    .apply-modal{
        // padding: 0 8px;
        &.closeButton{
            position: absolute;
            right: 60px;
            top: 40px;
        }
    }
    .cookie-preferences {
        &.closeButton{
            position: absolute;
            right: 20px;
            top: 20px;
        }
    }
}

:global .mf-modal {
    position: relative;
    background: $modal-bg;
    // margin: 20px auto !important;
    // padding: 10px 45px !important;
    width: auto !important;
    
    //Modal Global Styles
    .modal-title {
        color: $modal-title;
        text-align: left;
        font-size: 24px;
        @include media-breakpoint-down(md) {
            font-size: 18px;
        }
        font-weight: 600;
        h2{
            font-weight: 600 !important;
        }
        border-bottom: 1px solid $modal-title-border;
        padding-bottom: 20px;
        padding-right: 40px;
    }

    p {
        color: $modal-text;
    }

    a:not(.smartlink_button) {
        color: $modal-link;
    }

    .link-list {
        ul {
            margin: 0;
            padding: 0;
            width: 100%;
            list-style: none;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            li {
                padding: 20px 20px 20px 0;
                // padding-top: 20px;
                // padding-right: 20px;

                a:not(.smartlink_button) {
                    color: $modal-link-list;
                }
            }
        }
    }
    //Bio Modal
    &.bio-modal {
        max-width: 1000px;
        display: block;
        .closeButton {
            right: 60px;
            top: 40px;
            &:before {
                content: '';
                width: 44px;
                height: 44px;
                display: block;
                background-image: $modal-close-btn;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 40px;
            }
        }

        .bio-modal-content {
            padding-top: 40px;
            display: flex;
            text-align: left;
        }

        .bio-photo {
            width: 230px;
        }

        .bio-full {
            flex: 1;
            padding-left: 20px;
            padding-right: 20px;
        }

        .bio-name,
        .bio-title {
            @include font-size(24px);
        }

        .bio-title {
            color: $modal-bio-title;
        }

        .bio-name {
            font-weight: 700;
            color: $modal-bio-name;
        }

        p {
            margin-top: 20px;
            padding-bottom: 20px;
        }

        p:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
        }
        @include media-breakpoint-down(lg) {
            .bio-photo {
                width: 200px;
                margin: 0 auto;
            }

            .bio-name,
            .bio-title {
                text-align: center;
            }

            .bio-modal-content {
                flex-direction: column;
            }

            .bio-full {
                padding: 20px 0 0;
            }

            .bio-name,
            .bio-title {
                @include font-size(20px);
                font-weight: 400;
            }

            a.btn {
                margin: 0 auto;
            }

        }
        @include media-breakpoint-down(md){
            .closeButton {
                right: 10px;
                top: 10px;
            }
        }
    }
    //External Modal
    &.confirmation-dialog{
        padding: 40px;
        .dialogBody{
            padding: 30px 0 0 0;
            border-bottom: 0;
            text-align: left;
            li{
                padding-bottom: 0;
            }
        }
        .MuiDialogActions-root{
            padding: 0;
        }
        .closeButton {
            &:before {
                content: '';
                width: 44px;
                height: 44px;
                display: block;
                background-image: $modal-close-btn;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 40px;
            }
        }
    }
    //FAqs Modal
    &.faq-modal {
        max-width: 1000px;
        text-align: left;
        @include media-breakpoint-up(md){
            padding: 40px 75px !important;
        }
        margin: 20px auto;
        .dialogBody {
            padding-left: 0;
        }
    }
    //Modal Global Styles - Mobile

    &.dynamic-modal {
        max-width: 1000px;
        position: relative;
        padding: 40px 20px;

        &.apply-modal {
            display: block;
            padding: 40px 75px;
            .modal-title {
                padding-top: 0px;
            }
            &:before {
                content: '';

                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                background-size: 80%;
                background-image: $modal-apply-watermark;
                background-repeat: no-repeat;
                background-position: -400px center;
                @include opacity(0.3);
            } 
            @include media-breakpoint-down(sm) {
                padding: 20px;
                .modal-title {
                    font-size: 18px;
                    font-weight: 600;
                    padding: 0 25px 20px 0;
                }
                .MuiButtonBase-root {
                    &.closeButton{
                        position: absolute;
                        right: 10px;
                        top: 10px;
                    }
                }
            }
        }
        .smartlink_default {
            color: $text-color;
            &:hover {
                color: $text-color-hover;
            }
        }

        .smartlink_button {
          display: inline-block;
          border-radius: 2rem;
          font-family: $btn-font-family;
          padding: {
            top: 0.375rem;
            bottom: 0.375rem;
            left: 2.75rem;
            right: 2.75rem;
          }
          @include font-size(20px);
          font-weight: 600;
          text-decoration: none !important;
          @include animate(0.2s);
        }

        .smartlink_buttonprimary {
            background: $btn-primary-bg;
            border: 2px solid $btn-primary-border;
            color: $btn-primary-text;
          &:hover {
            background: $btn-primary-bg-hover;
            border: 2px solid $btn-primary-border-hover;
            color: $btn-primary-text-hover;
          }
        }

        .smartlink_buttonsecondary {
            background: $btn-secondary-bg;
            border: 2px solid $btn-secondary-border;
            color: $btn-secondary-text;
            &:hover {
                background: $btn-secondary-bg-hover;
                border: 2px solid $btn-secondary-border-hover;
                color: $btn-secondary-text-hover;
            }
        }

        form.login {
            .field {
                margin-bottom: 20px;

                &.center {
                    text-align: center;
                }
            }

            .link-list {
                text-align: center;
                ul {
                    justify-content: center;
                }
            }
        }

        .note {
            @include font-size(18px);
            font-weight: 400;
            text-align: center;
            p {
                margin-bottom: 0;
                color: $modal-apply-note-text;
            }
        }

        .apply-modal-content {
            width: 100%;
            height: 100%;
            display: flex;
            position: relative;
            margin: 45px 0 10px;
            text-align: left;
            
            .MuiButtonBase-root {
                &.closeButton{
                    position: absolute;
                    right: 10px;
                    top: 10px;
                }
            }
            .link-list {
                a:not(.smartlink_button) {
                    font-weight: 700;
                }

                ul li {
                    padding-right: 15px;

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }

            h3 {
                margin-bottom: 20px;
                font-size: 24px;
                color: $modal-apply-title;
            }


            > div {
                width: 50%;
                border-left: 1px solid $modal-apply-divider;
                padding-right: 40px;
                padding-bottom: 80px;
                position: relative;
                &:first-of-type {
                    border-left: 0;
                }
                &:last-of-type {
                    padding-left: 40px;
                    padding-right: 0;
                }
                .cta-btn {
                    width: 100%;
                    position: absolute;
                    bottom: 10px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    text-align: center;
                }
                &:first-of-type {
                    border-left: 0;
                }

                &:last-of-type {
                    padding-left: 40px;
                    padding-right: 0;
                }
            }
            @include media-breakpoint-down(lg) {
                flex-direction: column;
                margin-bottom: 40px;

                > div {
                    width: 100%;
                    border-left: 0;
                    border-top: 1px solid $modal-apply-divider;
                    padding-top: 40px;
                    padding-right: 0;

                    &:first-of-type {
                        border-top: 0;
                        padding-top: 0;
                        padding-bottom: 60px;
                        margin-bottom: 30px;
                    }

                    &:last-of-type {
                        padding-left: 0;
                    }
                }

                .link-list ul {
                    justify-content: center;
                }
            }
        }
    }
}
.cookie-preferences {
    :global .cookies-modal{
        width: 100%;
        max-width: 640px;
        padding: 60px 30px;
    }
    @include rounded(8px);

    @include media-breakpoint-down(md) {

        :global .cookies-modal{
            padding: {
                top: 40px;
                bottom: 40px;
            }
            .MuiDialogContent-root{
                scrollbar-color:  $primary-light #007;
            }
        }
    }

    .modal-title {
        border: 0;
        padding-top: 0;
    }

    .mfp-close {
        position: absolute;
        right: 20px !important;
        top: 20px !important;
    }

    .modal-title {
        h2 {
            margin-bottom: 0;
            @include font-size(24px);
            font-weight: 400;
        }
        color: $primary-medium-2;
        margin-bottom: 10px;
        padding-bottom: 0;
    }

    .modal-content {
        text-align: left;
        border: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        h3 {
            @include font-size(20px);
            font-weight: 500;
            color: $primary-medium-2;
            font-family: $font-family-sans-serif;
            margin-bottom: 8px;
            margin-top: 10px;
        }

        p {
            @include font-size(14px);
        }
    }

    :global .form-group {
        width: 100%;
        display: grid;
        row-gap: 0;
        grid-template-columns: repeat(4, 1fr);

        .form-group-title {
            @include font-size(20px);
            font-weight: 500;
            margin-bottom: 5px;
            color: $primary-medium-2;
            font-family: $font-family-sans-serif;
            grid-column-start: 1;
            grid-column-end: 5;
        }

        .form-group-desc {
            grid-column-start: 1;
            grid-column-end: 4;
        }

        .check-switch {
            grid-column-start: 4;
            grid-column-end: 5;
            align-items: end;
        }
    }

    :global .form-group-btn {
        text-align: center;
        margin-top: 40px;

        button.smartlink {
            @include font-size(14px);
        }
    }

    :global .switch {
        display: inline-block;
        height: 34px;
        position: relative;
        width: 60px;
        float: right;

        input {
            margin-left: 20px;
        }
    }

    :global .slider {
        background-color: #ccc;
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: .4s;

        &:before {
            background-color: #fff;
            bottom: 4px;
            content: "";
            height: 26px;
            left: 4px;
            position: absolute;
            transition: .4s;
            width: 26px;
        }
    }


    :global input:checked+.slider {
        background-color: $primary;
    }

    :global input:checked+.slider:before {
        transform: translateX(26px);
    }

    :global .slider.round {
        border-radius: 34px;
    }

    :global .slider.round:before {
        border-radius: 50%;
    }
}
.location-root{
    h2{
        margin-top: 5px;
        padding: 0 0 4px 0 !important;
    }
    button{
        right: 0 !important;
        top: 5px !important;
    }
    input{
        width: 460px;
    }
    .kentico-form{
        text-align: left;
    }
    .MuiDialogContent-root{
        padding-right: 0 !important;
    }
    .SubmitGroup{
        padding: 0 0 0 0;
        margin-top:15px;
    }
}
/************************************************************
 MINI CUSTOM MODAL
************************************************************/

.zag-popup-background {
    position: relative;
    z-index: 99;
    background: #fff;
    padding-top: 8px;
    text-align: left;
}
