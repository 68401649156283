@import "../_shared/_styles/component-base";
.c-promo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  height: 600px;

  position: relative;

  :global .container, :global .row {
    height: 100%;
    position: relative;
  }

  :global .eyebrow {
    margin-bottom: 7px;
  }

  h2 {
    @include font-size(42px);
    line-height: 1.0em;
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    @include font-size(18px);
    margin-bottom: 20px;
  }

  :global a.smartlink_button {
    margin-top: 10px;
    &:not(:last-of-type) {
      margin-right: 15px;
    }
  }

  @include media-breakpoint-down(lg) {
    padding-top: 130px;
    padding-bottom: 60px;
    height: 100%;
    min-height: 520px;
    h2 {
      @include font-size(35px);
      margin-bottom: 20px;
    }
  }
}