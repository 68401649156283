@import "../../../App/components/_shared/_styles/component-base";
@import "../../../../node_modules/bootstrap/scss/mixins/breakpoints";


.dynamic-form {
    h2 {
        padding-top: 1%;
    }
    padding: 2%;
    text-align: center;

    .contact-form-card {
        border-radius: 16px;
        color: #006EF5;
        padding:3%;

        .text-box {
            width: 70%;
             border-radius: 3px;
             margin-top: 3%;
        }
        :global .radio-buttons{
            .form-label{
                font-size: 1.35rem;
                margin-bottom: 0;
            }
            text-align: left;
        }
        .rich-text{
            color: $text-primary;
            a:hover{
                text-decoration: underline;
            }
        }
        .method-select {
            width: 70%;
            text-align: left;
            margin-top:3%;
        }

        .method-select-label {
            text-align: left;
        }

        .text-center {
            text-align: center;
        }
    
        .g-recaptcha {
            display: inline-block;
        }

        .required {
            color: red;
            font-weight: bold;
            text-align: left;
        }
    }

    .form-button{
        border-radius: 20px;
        width: 100%;
        color:#FFFFFF !important;
        font-weight: bold;
        background-color: #006EF5;
        font-family: "Manrope", sans-serif !important;
        padding-left: 1.75rem !important;
        padding-right: 1.75rem !important;

    }


    @include media-breakpoint-up(sm) {
        margin-left: 1%;
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        margin-left: 10%;
        width: 80%;    
    }

    @include media-breakpoint-up(lg) {
        margin-left: 5%;
        width: 90%;    
    }

    @include media-breakpoint-up(xl) {
        margin-left: 30%;
        width: 40%;    
    }

    .mat-elevation-z7 {
        box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px, 2px, 16px, 1px rgba(0, 0, 0, 0.12);
    }

    .mat-elevation-z14 {
        box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12)
    }

  .text-center {
    text-align: center;
  }

  .g-recaptcha {
    display: inline-block;
  }
}