@import "../_shared/_styles/component-base";

.two-thirds {

    .intro {
        h2 {
            color: $twothirds-intro-title;
        }
        p {
            color: $twothirds-intro-text;
        }
    }

    h3 {
        @include font-size(26px);
    }

    .content-left {
        color: $twothirds-left-text;
        position: relative;
        padding-right: 60px;

        display: flex;
        flex-direction: column;
        height: 100%;

        &:after {
            content: '';
            width: 1px ;
            height: 100%;

            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);

            background: $twothirds-left-divider;
        }

        h4 {
            @include font-size(18px);
            font-weight: 700;
            margin-bottom: 15px;
        }

        p {
            @include font-size(16px);
            margin-bottom: 20px;
        }
    }

    .content-right {
        display: flex;
        flex-direction: column;
        height: 100%;
        @include font-size(14px);
        h3 {
            margin-bottom: 40px;
        }
        a:not(.smartlink_button) {
            color: $twothirds-content-link;
        }
    }

    //Filters
    .content-left.filters {
        .link-list {
            ul {
                border-top: 1px solid $twothirds-link-border;
                li {
                    width: 100%;
                    border-bottom: 1px solid $twothirds-link-border;
                    text-align: center;
                    a.smartlink_default {
                        padding-top: 20px;
                        padding-bottom: 20px;
                        padding-left: 70px;
                        padding-right: 70px;
                        display: block;
                        @include font-size(24px);
                        line-height: 1.3em;
                        color: $twothirds-link;
                        &.active {
                            background: $twothirds-link-icon;
                        }
                    }
                }
            }
        }

        select.custom { 
            max-width: unset;
        }
    }

    /*
    a.more {
        padding-left: 25px;
        background: url('/assets/images/ui/plus-blue.svg') no-repeat left center;
        background-size: 16px;
        font-weight: 400;
    }
    */

    @include media-breakpoint-down(lg) {
        .content-left {
            margin-bottom: 40px;
            padding-bottom: 40px;
            padding-right: 0;
            &:after {
                width: 100%;
                height: 1px;
                top: unset;
                right: unset;
                bottom: 40px;
                left: 0;
            }
            &.filters {
                margin-bottom: 0;
                &:after {
                    display: none;
                }
            }
        }

        .content-right {
            h3 {
                text-align: center;
            }
        }
    }

}
