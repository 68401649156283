@import "../../../components/_shared/_styles/component-base";

:global .loading {
  width: 100%;
  height: 100%;

  padding: 20px;
  margin-top: 200px;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transition: opacity 2s linear;

  .loading-message {
    width: 100%;
    max-width: 665px;
    padding: 40px 20px 60px 20px;
    @include rounded(10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-text {
      color: $loading-message-text;
      @include font-size(20px);
    }
  }
}
:global .loading.fade-in{
  opacity: 1;
}
