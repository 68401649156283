@import "../../_shared/_styles/component-base";

.root {
  text-align: center;
  padding: {
    left: 54px;
    right: 54px;
  }
  img {
    width: 100%;
    max-width: 178px;
    @include circular();
  }
  @include media-breakpoint-down(lg) {
    text-align: center;
    padding: {
      left: 54px;
      right: 54px;
    }
    margin-bottom: 40px;
    img {
      width: 100%;
      max-width: 178px;
      margin: 0 auto;
    }
  }
}
