@import "../_shared/_styles/component-base";

.stepwise {
  position: relative;
  overflow: hidden;

  :global .link-list {
    margin: {
      top: 20px;
      bottom: 20px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      li {
        padding: 0 15px;
        position: relative;
        text-align: center;
        display: inline-flex;
        align-items: center;

        a.smartlink_default {
          color: $stepwise-step-link;
          @include font-size(16px);
        }
        &:last-child:after {
          display: none;
        }
      }
    }
  }

  .intro {
        h2 {
            color: $stepwise-intro-title;
        }
        p {
            color: $stepwise-intro-text;
        }
    }

  .card-container {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;

    :global .column {
      @include make-col-ready();
    }

    /* IF 2 cards are present */
    :global .column:first-child:nth-last-child(2),
    :global .column:first-child:nth-last-child(2) ~ .column {
      @include media-breakpoint-up(xs) {
        @include make-col(12);
      }

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(5);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(4);
      }
    }

    /* IF 3 cards are present */
    :global .column:first-child:nth-last-child(3),
    :global .column:first-child:nth-last-child(3) ~ .column {
      @include media-breakpoint-up(md) {
        @include make-col(12);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(3);
      }
    }

    /* IF 4 Cards are present */
    :global .column:first-child:nth-last-child(4),
    :global .column:first-child:nth-last-child(4) ~ .column {
      @include media-breakpoint-up(sm) {
        @include make-col(12);
      }

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(3);
      }
    }

  }

  .card {
    border: none;
    background: none;
    text-align: center;
    height: 100%;

    .step {
      width: 60px;
      height: 60px;
      margin: 0 auto;
      background: $stepwise-step-bg;
      display: flex;
      align-items: center;
      justify-content: center;
      @include circular();

      @include font-size(30px);
      font-weight: 700;
      color: $stepwise-step;
    }

    .card-body {
      max-width: 100% !important;
      h3.card-title {
        @include font-size(20px);
        margin-bottom: 20px;
        font-weight: 700;
        color: $stepwise-step-text;
        :global a,
        :global a.smartlink_default {
            color: $stepwise-step-link;
        }
      }
        p {
            @include font-size(16px);
            color: $stepwise-step-text;
        }

        :global a,
        :global a.smartlink_default {
            @include font-size(16px);
            font-weight: 700;
            color: $stepwise-step-link;
        }
    }
  }
}
