// ----- Box Sizing ----- //
*, *:before, *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

// ----- Clearfix ----- //
@mixin clearfix {
	&:before,
	&:after {
		content: " ";
		display: table;
	};
	&:after {
		clear: both;
	};
}

// ----- Background Image ----- //
@mixin background($position: center center) {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: $position;
}

// ----- List Reset ----- //
@mixin listReset {
	margin: 0;
	padding: 0;
	list-style: none;
}

// ----- Circle ----- //
@mixin circular($value: 50%) {
	-moz-border-radius: $value;
	-webkit-border-radius: $value;
	border-radius: $value;
}

// ----- Rounded Corners ----- //
@mixin rounded($radius: 0.5em) {
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	border-radius: $radius;
}

// ----- Opacity ----- //
@mixin opacity($opacity: 0) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}

// ----- Rotate ----- //
@mixin rotate($degree) {
	-ms-transform: rotate($degree); /* IE 9 */
	-webkit-transform: rotate($degree); /* Chrome, Safari, Opera */
	transform: rotate($degree);
}

// ----- Circle ----- //
@mixin circular {
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

@mixin alignVertical($position: middle) {
	&:before {
		content: "";
		display: inline-block;
		vertical-align: $position;
		height: 100%;
	}
}

// ----- Gradients ----- //
@mixin vertical-gradient($topColor, $bottomColor) {
	background: $topColor; /* For browsers that do not support gradients */
	background: -webkit-linear-gradient($topColor, $bottomColor); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient($topColor, $bottomColor); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient($topColor, $bottomColor); /* For Firefox 3.6 to 15 */
	background: linear-gradient($topColor, $bottomColor); /* Standard syntax */
}

@mixin left-right-gradient($leftColor, $rightColor) {
	background: $leftColor; /* For browsers that do not support gradients */
	background: -webkit-linear-gradient(left, $leftColor , $rightColor); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(right, $leftColor, $rightColor); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(right, $leftColor, $rightColor); /* For Firefox 3.6 to 15 */
	background: linear-gradient(to right, $leftColor , $rightColor); /* Standard syntax */
}

@mixin right-left-gradient($leftColor, $rightColor) {
	background: $leftColor; /* For browsers that do not support gradients */
	background: -webkit-linear-gradient(right, $leftColor , $rightColor); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(left, $leftColor, $rightColor); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(left, $leftColor, $rightColor); /* For Firefox 3.6 to 15 */
	background: linear-gradient(to left, $leftColor , $rightColor); /* Standard syntax */
}

//DropShadow
@mixin box-shadow($left, $top, $blur, $color, $inset:"") {
	-webkit-box-shadow:$left $top $blur $color #{$inset};
	-moz-box-shadow:$left $top $blur $color #{$inset};
	box-shadow:$left $top $blur $color #{$inset};
	/*
		Usage
        @include box-shadow(0px, 4px, 5px, rgba(0, 0, 0, 0.25));
	*/
}

//Generic Transform
@mixin transform($transforms) {
	-moz-transform: $transforms;
	-o-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
	transform: $transforms;
}

@function pxtopercent($target, $parent: 16) {
	@return percentage($target / $parent);
}

// ----- Animation ----- //
@mixin animate($time: 0.3s) {
	-webkit-transition: all $time ease-out;
	-moz-transition: all $time ease-out;
	-o-transition: all $time ease-out;
	transition: all $time ease-out;
}

@mixin animate-btn($time: 0.3s) {
	-webkit-transition: top $time ease-out;
	-moz-transition: top $time ease-out;
	-o-transition: top $time ease-out;
	transition: top $time ease-out;
}

@mixin animate-frames($animationType, $time) {
	-webkit-animation: $animationType $time; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: $animationType $time; /* Firefox < 16 */
	-ms-animation: $animationType $time; /* Internet Explorer */
	-o-animation: $animationType $time; /* Opera < 12.1 */
	animation: $animationType $time;
}

// ----- Vertical Centering (IE9+) ----- //
@mixin vertical-align($position: relative) {
	position: $position;
	top: 100%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}

// ----- Pixels to REM (IE9+) ----- //
@function calculateRem($size) {
	$remSize: calc($size / 16px);
	@return $remSize * 1rem;
}

@mixin font-size($size) {
	font-size: $size;
	font-size: calculateRem($size);
}

// ----- Forms ----- //
@mixin appearance($value) {
	-webkit-appearance: $value;
	-moz-appearance:    $value;
	appearance:         $value;
}

// ----- Placeholder ----- //
@mixin placeholder($color) {
	::-webkit-input-placeholder {
		color: $color;
		@include opacity(1);
	}

	:-moz-placeholder {
		color: $color;
		@include opacity(1);
	}

	::-moz-placeholder {
		color: $color;
		@include opacity(1);
	}

	:-ms-input-placeholder {
		color: $color;
		@include opacity(1);
	}
}

// ----- Add a dash line above a header ----- //
@mixin dash($width: 80px, $color: #000) {
	&:after {
		position: absolute;
		top: -20px;
		left: 0;
		content: '';
		width: $width;
		height: 2px;
		background: $color;
	}
}

// ----- Plus / Minus Icon Animation ----- //
@mixin plusMinus($width, $lineWidth, $color, $colorActive) {
	box-sizing: border-box;
	transition: transform 0.3s;
	width: $width;
	height: $width;
	transform: rotate(180deg);
	position: relative;

	&.active_icon {
		transform: rotate(0deg);
	}
	&::before {
		content: '';
		display: block;
		width: $width;
		height: 0px;
		border-bottom: solid $lineWidth $color;
		position: absolute;
		bottom:$width /2 - $lineWidth/2;
		transform: rotate(90deg);
		transition: width 0.3s;
	}
	&.active_icon::before {
		content: '';
		display: block;
		width: 0px;
		height: 0px;
		border-bottom: solid $lineWidth $colorActive;
		position: absolute;
		bottom:$width /2 - $lineWidth/2;
		transform: rotate(90deg);
	}
	&.active_icon::after {
		border-bottom: solid $lineWidth $colorActive;
	}
	&::after {
		content: '';
		display: block;
		width: $width;
		height: 0px;
		border-bottom: solid $lineWidth $color;
		position: absolute;
		bottom: $width /2 - $lineWidth/2;
	}
}
