@import "../_shared/_styles/component-base";

.sitemap-container{
    padding-top: 190px;
    h1{
        font-size:xx-large;
        margin-bottom: 20px;
        border-bottom: 1px #ABC1CF solid;
    }

    @include media-breakpoint-down(lg) {
        padding-top: 100px;
    }
}

.sitemap{
    column-count: 4;
    h6{
        margin-left: 18px;
        font-size: large;
    }
    @include media-breakpoint-down(lg) {
        column-count: 2;
    }
    @include media-breakpoint-down(md) {
        column-count: 1;
    }
}