:global .Select_Menu{
  .MuiListItem-root {
    fontSize: 16;
    padding: 4px 15px !important;
    cursor: pointer;
    &:hover {
      color: #193C78;
    }
  }
}

:global div.MuiInputBase-root.select{
  width: 100%;
  max-width: 460px;
  border-radius: 6px;
  padding: 5px 10px;
  background-color: #FFF;
  font-size: 16px;
  color: #212529;
  margin-top: 5px;
  margin-bottom: 10px;
  background-image: url("../../../_assets/select-down-chevron.svg");
  background-repeat: no-repeat;
  background-position: 97% 50%;
  border: 1px solid #D8D8D8;
  text-align: left;

  &:after{
    display: none;
  }

  & .MuiSelect-select:focus {
    background-color: transparent;
  }

  & .MuiSvgIcon-root {
    display: none;
  }

}